import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
  DialogProps,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Controls from './Controls';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
  },
  dialogTitle: {
    paddingRight: '0px',
    borderBottom: '1px solid #d2d2d2',
  },
  modalTitle: {
    fontFamily: 'Reach-Text-Regular, Helvetica, Arial, sans-serif !important',
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
  },
  '.MuiInput-underline:hover:not(.Mui-disabled):before': {
    borderBottom: 'none !important',
  },
  '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    // @ts-ignore
    border: `1px solid ${theme.palette.grey.slate} !important`,
  },
  '.MuiInput-underline:after': {
    borderBottom: 'none',
  },
}));

interface IPopup extends DialogProps {
  openPopup?: any;
  setOpenPopup?: any;
  disableCloseButton?: boolean;
  closeFn?: any;
}
const Popup: React.FC<IPopup> = (props) => {
  const {
    title,
    children,
    openPopup,
    setOpenPopup,
    fullWidth = false,
    maxWidth,
    disableCloseButton = false,
    closeFn,
  } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={openPopup}
      disableEnforceFocus
      maxWidth={maxWidth || 'md'}
      fullWidth={fullWidth}
      classes={{ paper: classes.dialogWrapper }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            <div className={classes.modalTitle}> {title}</div>
          </Typography>
          {!disableCloseButton && (
            <Controls.ActionButton
              color="secondary"
              customButtonType="closeModalButton"
              onClick={() => {
                setOpenPopup && setOpenPopup(false);
                closeFn && closeFn();
              }}
            >
              <CloseIcon color="error" />
            </Controls.ActionButton>
          )}
        </div>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Popup;
