import { ITransactionData } from './TransactionsActionTypes';

export interface IUser {
  acceptedTermsAndConditions: boolean;
  acceptedTermsAndConditionsDateTime: boolean | null;
  accountExecutive: string;
  active: boolean;
  agencyId: string;
  children: string[];
  companyName: string;
  createdDateTime: Date;
  emailAddress: string;
  firstName: string;
  id: number;
  lastName: string;
  parent: any;
  parentId: any;
  paymentTokens: string[];
  phoneNumber: string;
  userName: string;
  userType: string | null;
  userTypeId: number;
}

export interface IPaymentStatuses {
  active: boolean;
  description: string;
  id: number;
  paymentTransactions?: string[];
  statusCode: string;
  value: string;
  title: string;
}

export interface IPaymentTypes {
  active: boolean;
  description: string;
  id: number;
  paymentTransactions?: string[];
  value: string;
  title: string;
}

export interface IRecurringPaymentData {
  amount: number;
  dayOfMonth: string | null;
  dayOfWeek: string;
  description: string | null;
  endDate: Date;
  nextChargeAmount: number;
  nextChargeDate: Date;
  orderNumber: string;
  paymentCard: string;
  paymentStatus: string;
  paymentTokenId: number;
  paymentType: string;
  recurringPaymentId: number;
  startDate: Date;
}

export interface IRecurringPaymentType {
  active: boolean;
  description: string;
  id: boolean;
  paymentTransactions?: string[];
  value: string;
  status: string;
}

export interface IUsersInitialState {
  usersData: IUser[];
  totalRecords: number;
  isUsersLoading: boolean;
  usersTransactionsData: ITransactionData[];
  totalRecordsTransactions: number;
  isUsersTransactionsLoading: boolean;
  recurringPaymentData: IRecurringPaymentData[];
  selectedRecurringPayment: any;
  isPaymentsLoading: boolean;
  paymentStatuses: IPaymentStatuses[];
  paymentTypes: IPaymentTypes[];
  isScheduledPaymentModalOpen: boolean;
  isRecurringPaymentModalOpen: boolean;
  isSendPaymentModalOpen: boolean;
  sendPaymentError: '';
  recurringPaymentError: '';
  scheduledPaymentError: '';
  updateProfileError: '';
  isEditModalOpen: boolean;
  recurringPaymentTypesData: IRecurringPaymentType[];
}

export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS';
export const USERS_REQUEST_FAILURE = 'USERS_REQUEST_FAILURE';
export const USERS_REQUEST_LOADING = 'USERS_REQUEST_LOADING';
export const LOAD_USERS_TRANSACTION_HISTORY_SUCCESS =
  'LOAD_USERS_TRANSACTION_HISTORY_SUCCESS';
export const USERS_TRANSACTIONS_REQUEST_LOADING = 'USERS_TRANSACTIONS_REQUEST_LOADING';
export const USERS_TRANSACTIONS_REQUEST_FAILURE = 'USERS_TRANSACTIONS_REQUEST_FAILURE';
export const SEND_CONFIRMATION_REQUEST_FAILURE = 'SEND_CONFIRMATION_REQUEST_FAILURE';
export const SEND_CONFIRMATION_REQUEST_SUCCESS = 'SEND_CONFIRMATION_REQUEST_SUCCESS';
export const GET_RECURRING_PAYMENT_REQUEST_FAILURE =
  'GET_RECURRING_PAYMENT_REQUEST_FAILURE';
export const GET_RECURRING_PAYMENT_REQUEST_SUCCESS =
  'GET_RECURRING_PAYMENT_REQUEST_SUCCESS';
export const UPDATE_RECURRING_PAYMENT_REQUEST_FAILURE =
  'UPDATE_RECURRING_PAYMENT_REQUEST_FAILURE';
export const UPDATE_RECURRING_PAYMENT_REQUEST_SUCCESS =
  'UPDATE_RECURRING_PAYMENT_REQUEST_SUCCESS';
export const DISABLE_RECURRING_PAYMENT_REQUEST_FAILURE =
  'DISABLE_RECURRING_PAYMENT_REQUEST_FAILURE';
export const DISABLE_RECURRING_PAYMENT_REQUEST_SUCCESS =
  'DISABLE_RECURRING_PAYMENT_REQUEST_SUCCESS';
export const USERS_PAYMENTS_REQUEST_LOADING = 'USERS_PAYMENTS_REQUEST_LOADING';
export const GET_PAYMENT_TYPES_REQUEST_FAILURE = 'GET_PAYMENT_TYPES_REQUEST_FAILURE';
export const GET_PAYMENT_TYPES_REQUEST_SUCCESS = 'GET_PAYMENT_TYPES_REQUEST_SUCCESS';
export const GET_PAYMENT_STATUS_REQUEST_FAILURE = 'GET_PAYMENT_STATUS_REQUEST_FAILURE';
export const GET_PAYMENT_STATUS_REQUEST_SUCCESS = 'GET_PAYMENT_STATUS_REQUEST_SUCCESS';
export const UPDATE_SCHEDULED_PAYMENT_REQUEST_FAILURE =
  'UPDATE_SCHEDULED_PAYMENT_REQUEST_FAILURE';
export const UPDATE_SCHEDULED_PAYMENT_REQUEST_SUCCESS =
  'UPDATE_SCHEDULED_PAYMENT_REQUEST_SUCCESS';
export const DISABLE_SCHEDULED_PAYMENT_REQUEST_FAILURE =
  'DISABLE_SCHEDULED_PAYMENT_REQUEST_FAILURE';
export const DISABLE_SCHEDULED_PAYMENT_REQUEST_SUCCESS =
  'DISABLE_SCHEDULED_PAYMENT_REQUEST_SUCCESS';
export const TOGGLE_SEND_PAYMENT_RECEIPT_MODAL = 'TOGGLE_SEND_PAYMENT_RECEIPT_MODAL';
export const TOGGLE_UPDATE_RECURRING_PAYMENT_MODAL =
  'TOGGLE_UPDATE_RECURRING_PAYMENT_MODAL';
export const TOGGLE_UPDATE_SCHEDULED_PAYMENT_MODAL =
  'TOGGLE_UPDATE_SCHEDULED_PAYMENT_MODAL';
export const UPDATE_USER_REQUEST_SUCCESS = 'UPDATE_USER_REQUEST_SUCCESS';
export const UPDATE_USER_REQUEST_FAILURE = 'UPDATE_USER_REQUEST_FAILURE';
export const TOGGLE_EDIT_MODAL = 'TOGGLE_EDIT_MODAL';
export const GET_RECURRING_PAYMENT_TYPES_REQUEST_SUCCESS =
  'GET_RECURRING_PAYMENT_TYPES_REQUEST_SUCCESS';
export const GET_RECURRING_PAYMENT_TYPES_REQUEST_FAILURE =
  'GET_RECURRING_PAYMENT_TYPES_REQUEST_FAILURE';

export interface ILoadUsersSuccess {
  type: 'LOAD_USERS_SUCCESS';
  data: {
    data: IUser[];
    filteredRecords: number;
    totalRecords: number;
  };
}
export interface IUsersRequestFailure {
  type: 'USERS_REQUEST_FAILURE';
}
export interface IUsersRequestLoading {
  type: 'USERS_REQUEST_LOADING';
}
export interface ILoadUsersTransactionHistorySuccess {
  type: 'LOAD_USERS_TRANSACTION_HISTORY_SUCCESS';
  data: {
    data: ITransactionData[];
    filteredRecords: number;
    totalRecords: number;
  };
}
export interface IUsersTransactionsRequestFailure {
  type: 'USERS_TRANSACTIONS_REQUEST_FAILURE';
}
export interface IUsersTransactionsRequestLoading {
  type: 'USERS_TRANSACTIONS_REQUEST_LOADING';
}
export interface ISendConfirmationRequestFailure {
  type: 'SEND_CONFIRMATION_REQUEST_FAILURE';
  data: any;
}
export interface ISendConfirmationRequestSuccess {
  type: 'SEND_CONFIRMATION_REQUEST_SUCCESS';
}
export interface IGetRecurringPaymentRequestFailure {
  type: 'GET_RECURRING_PAYMENT_REQUEST_FAILURE';
}
export interface IGetRecurringPaymentRequestSuccess {
  type: 'GET_RECURRING_PAYMENT_REQUEST_SUCCESS';
  data: IRecurringPaymentData[];
}
export interface IUpdateRecurringPaymentRequestFailure {
  type: 'UPDATE_RECURRING_PAYMENT_REQUEST_FAILURE';
  data: any;
}
export interface IUpdateRecurringPaymentRequestSuccess {
  type: 'UPDATE_RECURRING_PAYMENT_REQUEST_SUCCESS';
  data: IRecurringPaymentData;
}
export interface IDisableRecurringPaymentRequestFailure {
  type: 'DISABLE_RECURRING_PAYMENT_REQUEST_FAILURE';
}
export interface IDisableRecurringPaymentRequestSuccess {
  type: 'DISABLE_RECURRING_PAYMENT_REQUEST_SUCCESS';
}
export interface IUsersPaymentsRequestLoading {
  type: 'USERS_PAYMENTS_REQUEST_LOADING';
}
export interface IGetPaymentTypesFailure {
  type: 'GET_PAYMENT_TYPES_REQUEST_FAILURE';
  data: any;
}
export interface IGetPaymentTypesSuccess {
  type: 'GET_PAYMENT_TYPES_REQUEST_SUCCESS';
  data: IPaymentTypes[];
}
export interface IGetPaymentStatusesFailure {
  type: 'GET_PAYMENT_STATUS_REQUEST_FAILURE';
  data: any;
}
export interface IGetPaymentStatusesSuccess {
  type: 'GET_PAYMENT_STATUS_REQUEST_SUCCESS';
  data: IPaymentStatuses[];
}

export interface IUpdateScheduledPaymentRequestSuccess {
  type: 'UPDATE_SCHEDULED_PAYMENT_REQUEST_SUCCESS';
}
export interface IUpdateScheduledPaymentRequestFailure {
  type: 'UPDATE_SCHEDULED_PAYMENT_REQUEST_FAILURE';
  data: any;
}

export interface IDisableScheduledPaymentRequestSuccess {
  type: 'DISABLE_SCHEDULED_PAYMENT_REQUEST_SUCCESS';
}
export interface IDisableScheduledPaymentRequestFailure {
  type: 'DISABLE_SCHEDULED_PAYMENT_REQUEST_FAILURE';
}

export interface IToggleSendPaymentReceiptModal {
  type: 'TOGGLE_SEND_PAYMENT_RECEIPT_MODAL';
  data: any;
}
export interface IToggleUpdateRecurringPaymentModal {
  type: 'TOGGLE_UPDATE_RECURRING_PAYMENT_MODAL';
  data: any;
}
export interface IToggleUpdateScheduledPaymentModal {
  type: 'TOGGLE_UPDATE_SCHEDULED_PAYMENT_MODAL';
  data: any;
}
export interface IUpdateUserRequestSuccess {
  type: 'UPDATE_USER_REQUEST_SUCCESS';
}
export interface IUpdateUserRequestFailure {
  type: 'UPDATE_USER_REQUEST_FAILURE';
  data: any;
}
export interface IToggleEditModal {
  type: 'TOGGLE_EDIT_MODAL';
  data: any;
}
export interface IGetRecurringPaymentTypesRequestSuccess {
  type: 'GET_RECURRING_PAYMENT_TYPES_REQUEST_SUCCESS';
  data: IRecurringPaymentType[];
}
export interface IGetRecurringPaymentTypesRequestFailure {
  type: 'GET_RECURRING_PAYMENT_TYPES_REQUEST_FAILURE';
  data: any;
}

export type IUsersActions =
  | ILoadUsersSuccess
  | IUsersRequestFailure
  | IUsersRequestLoading
  | ILoadUsersTransactionHistorySuccess
  | IUsersTransactionsRequestLoading
  | IUsersTransactionsRequestFailure
  | ISendConfirmationRequestSuccess
  | ISendConfirmationRequestFailure
  | IGetRecurringPaymentRequestSuccess
  | IGetRecurringPaymentRequestFailure
  | IUpdateRecurringPaymentRequestFailure
  | IUpdateRecurringPaymentRequestSuccess
  | IDisableRecurringPaymentRequestSuccess
  | IDisableRecurringPaymentRequestFailure
  | IUsersPaymentsRequestLoading
  | IGetPaymentTypesSuccess
  | IGetPaymentTypesFailure
  | IGetPaymentStatusesSuccess
  | IGetPaymentStatusesFailure
  | IDisableScheduledPaymentRequestSuccess
  | IDisableScheduledPaymentRequestFailure
  | IToggleSendPaymentReceiptModal
  | IToggleUpdateRecurringPaymentModal
  | IToggleUpdateScheduledPaymentModal
  | IUpdateUserRequestSuccess
  | IUpdateUserRequestFailure
  | IToggleEditModal
  | IGetRecurringPaymentTypesRequestSuccess
  | IGetRecurringPaymentTypesRequestFailure
  | IUpdateScheduledPaymentRequestFailure
  | IUpdateScheduledPaymentRequestSuccess;
