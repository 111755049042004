import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, TableRow, TableCell } from '@material-ui/core';
import { push } from 'connected-react-router';
import moment from 'moment';
import * as UseTableActions from '../../redux/actionCreators/common/UseTableActionCreators';
import { makeOrGetTableFiltersByKey } from '../../redux/selectors/common/UseTableSelectors';
import { initialState } from '../../rootReducer';

interface ITableFilterRow {
  pageKey: string;
  addSerachInputToUrl?: boolean;
  onColumnFilterAction?: any;
  headCells?: any;
}

/** Table Filter Row Component */
const TableFilterRow: React.FC<ITableFilterRow> = (props) => {
  const dispatch = useDispatch();
  const getTableFiltersByPageKey = makeOrGetTableFiltersByKey();
  const { tableFilters } = useSelector((state: initialState) => ({
    tableFilters: getTableFiltersByPageKey(state, props.pageKey),
  }));
  const { search, orderBy, order, startDate, endDate, columnFilterDetails } =
    tableFilters;
  const [isValidationError, setValidationError] = useState({});
  const { addSerachInputToUrl = true } = props;
  const handleColumnFilterChange = (
    event: { target: { name: any; value: any } },
    fieldType: string
  ) => {
    handleSetTableFilters(event.target.name, event.target.value, fieldType);
  };

  const handleSetTableFilters = (
    name: string,
    columnSearchValue: string,
    fieldType: string
  ) => {
    if (fieldType && fieldType === 'date') {
      const isValid = moment(columnSearchValue, 'M/D/YYYY', true).isValid();
      if (columnSearchValue === '') {
        setValidationError({
          ...isValidationError,
          [name]: false,
        });
      } else {
        setValidationError({
          ...isValidationError,
          [name]: !isValid,
        });
      }
    }
    if (fieldType && fieldType === 'amount') {
      const isAmountValid = parseFloat(columnSearchValue.replace(/\$|,/g, ''));
      if (columnSearchValue === '') {
        setValidationError({
          ...isValidationError,
          [name]: false,
        });
      } else {
        setValidationError({
          ...isValidationError,
          [name]: !isAmountValid,
        });
      }
    }
    if (fieldType && fieldType === 'number') {
      const pattern = /^\d+$/;
      const int32MaxValue=2147483647;
      const isNumberValid = pattern.test(columnSearchValue) && Number(columnSearchValue)<=int32MaxValue;
      if (columnSearchValue === '') {
        setValidationError({
          ...isValidationError,
          [name]: false,
        });
      } else {
        setValidationError({
          ...isValidationError,
          [name]: !isNumberValid,
        });
      }
    }
    const filterValue = {
      pageKey: props.pageKey,
      newFilters: {
        columnFilterDetails: {
          ...columnFilterDetails,
          [name]: columnSearchValue,
        },
      },
    };
    UseTableActions.setTableFilters(dispatch, filterValue);
  };

  const handleEnterSearch = (name: string, fieldType: string) => {
    const currentFilter = columnFilterDetails && columnFilterDetails[name];
    const isEmpty = Object.keys(isValidationError).length === 0;
    if (
      !isEmpty &&
      // @ts-ignore
      isValidationError[name] &&
      fieldType &&
      (fieldType === 'date' || fieldType === 'amount' || fieldType === 'number')
    ) {
      return;
    }
    handleSetTableFilters(name, currentFilter, '');
    if (addSerachInputToUrl) {
      dispatch(
        push({
          search: `?${new URLSearchParams({
            order,
            orderBy,
            page: '0',
            searchInput: search || '',
            startDate: startDate || new Date(),
            endDate: endDate || new Date(),
          }).toString()}`,
        })
      );
    }
    props.onColumnFilterAction();
  };
  const getErrorMessage = (fieldType: string) => {
    let errorMsg = '';
    if (fieldType === 'amount') {
      errorMsg = 'Invalid Amount';
    } else if (fieldType === 'date') {
      errorMsg = 'Invalid date';
    } else if (fieldType === 'number') {
      errorMsg = 'Invalid Input';
    } else {
      errorMsg = '';
    }
    return errorMsg;
  };
  return (
    <TableRow>
      {props.headCells.map((cell: any, index: any) => (
        <TableCell key={`${cell.id}_${index}`}>
          {!cell.disableFiltering ? (
            <TextField
              id="filter-by-column-search"
              name={cell.id}
              label="Filter..."
              value={(columnFilterDetails && columnFilterDetails[cell.id]) || ''}
              onChange={(event) => handleColumnFilterChange(event, cell.fieldType)}
              // @ts-ignore
              error={isValidationError[cell.id]}
              // @ts-ignore
              helperText={isValidationError[cell.id] && getErrorMessage(cell.fieldType)}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  ev.preventDefault();
                  handleEnterSearch(cell.id, cell.fieldType);
                }
              }}
            />
          ) : null}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableFilterRow;
