import { Dispatch } from 'redux';
import * as types from '../../actionTypes/common/UseTableActionTypes';

/** Action to reset table filters */
export const resetFilters = (dispatch: Dispatch<any>, pageKey: string) => {
  const reset = {
    type: types.RESET_FILTERS,
    pageKey,
  };
  dispatch(reset);
};

interface ISetFilters {
  pageKey: string;
  newFilters: any;
}

/** Action to set table filters */
export const setTableFilters = (dispatch: Dispatch<any>, data: ISetFilters) => {
  const setFilters = {
    type: types.SET_TABLE_FILTERS_DATA,
    data,
  };
  dispatch(setFilters);
};
