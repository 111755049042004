import { LocationChangeAction } from 'connected-react-router';
import * as types from '../actionTypes/GlobalNavActionTypes';

/** global nav initial state */
const globalNavInitialState: types.IGlobalNavInitialState = {
  isOpen: false,
};

/** Global Nav Reducer */
const globalNavReducer = (
  state = globalNavInitialState,
  action: types.IGlobalNavActions | LocationChangeAction
) => {
  switch (action.type) {
    case types.TOGGLE_GLOBAL_NAV_DRAWER:
      return {
        isOpen: action.isOpen,
      };
    case '@@router/LOCATION_CHANGE':
      return {
        isOpen: false,
      };
    default:
      return state;
  }
};

export { globalNavInitialState, globalNavReducer };
