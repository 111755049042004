import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, TextField } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/pickers';
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-shadow */
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import moment from 'moment';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import Controls from '../../../../shared/controls/Controls';
import * as DatePicker from '../../../../shared/controls/DatePicker';
import Loader from '../../../../shared/components/Loader';
import Popup from '../../../../shared/controls/Popup';
import * as Actions from '../../../../redux/actionCreators/UsersActionCreators';
import NumberFormatCustom from './NumberFormatCustom';
import ValidationError from './ValidationError';
import { initialState } from '../../../../rootReducer';

interface IScheduledPaymentModal {
  openScheduledPaymentPopup: any;
  paymentData: any;
}
const ScheduledPaymentModal: React.FC<IScheduledPaymentModal> = (props) => {
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const dispatch = useDispatch();
  const { scheduledPaymentError, isPaymentsLoading } = useSelector(
    (state: initialState) => ({
      scheduledPaymentError:
        state && state.usersReducer && state.usersReducer.scheduledPaymentError,
      isPaymentsLoading:
        state && state.usersReducer && state.usersReducer.isPaymentsLoading,
    })
  );
  const classes = { ...clientPortalClasses, ...commonClasses };
  const { paymentData, openScheduledPaymentPopup } = props;
  const [scheduledPaymentData, setScheduledPaymentData] = useState(paymentData);
  const [amountError, setAmountValidation] = useState('');
  const handleDateOnChange = (date: any) => {
    setScheduledPaymentData({
      ...scheduledPaymentData,
      paymentDate: moment(date).format(),
    });
  };

  const updateScheduledPayment = () => {
    const totalAmt =
      scheduledPaymentData &&
      scheduledPaymentData.paymentDetails
        .map((item: { amount: any }) => item.amount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    const data = {
      id: scheduledPaymentData.transactionId,
      amount: totalAmt,
      paymentDate: scheduledPaymentData.paymentDate,
      paymentDetails: scheduledPaymentData.paymentDetails,
    };
    dispatch(Actions.updateScheduledPaymentInfo(data));
  };

  const handleInputChange = (e: { target: { name: any; value: any } }, id: any) => {
    const { name, value } = e.target;
    const itemAttributes = { [name]: parseFloat(value) };
    if (name === 'amount' && parseFloat(value) === 0) {
      setAmountValidation('Amount should be greater than zero');
    } else {
      setAmountValidation('');
    }
    const index = scheduledPaymentData.paymentDetails.findIndex(
      (x: { id: any }) => x.id === id
    );
    if (index >= 0) {
      const updatedData = {
        ...scheduledPaymentData,
        paymentDetails: [
          ...scheduledPaymentData.paymentDetails.slice(0, index),
          { ...scheduledPaymentData.paymentDetails[index], ...itemAttributes },
          ...scheduledPaymentData.paymentDetails.slice(index + 1),
        ],
      };
      setScheduledPaymentData(updatedData);
    }
  };
  return (
    <Popup
      open={openScheduledPaymentPopup}
      title="Scheduled Payment"
      openPopup={openScheduledPaymentPopup}
      closeFn={() => Actions.toggleUpdateScheduledPaymentModal(dispatch, false)}
    >
      <div style={{ width: 500 }}>
        {isPaymentsLoading ? (
          <Loader isLoading={isPaymentsLoading} />
        ) : (
          <Grid container>
            <ValidationError validationErrorData={scheduledPaymentError} />
            <Grid item xs={12}>
              <div
                className="DivAddEmail MuiFormControl-root MuiTextField-root"
                style={{ width: '100%' }}
              >
                <Controls.Input
                  name="transactionId"
                  value={scheduledPaymentData.transactionId}
                  disabled
                  fullWidth
                  label="Payment Token ID"
                />
                <Controls.Input
                  name="amount"
                  label="Total Amount"
                  disabled
                  fullWidth
                  value={scheduledPaymentData.amount}
                />
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <DatePicker.InvoiceAutomationHeader
                    allowSameDateSelection
                    // @ts-ignore
                    autoOk
                    disableToolbar
                    // @ts-ignore
                    renderInput={(props) => <TextField {...props} />}
                    variant="outlined"
                    format="MM/dd/yyyy"
                    margin="normal"
                    label="Payment Date"
                    data-testid="date-picker"
                    emptyLabel="mm/dd/yyyy"
                    disablePast
                    value={moment(scheduledPaymentData.paymentDate).format('MM/DD/yyyy')}
                    onChange={handleDateOnChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </LocalizationProvider>
                {scheduledPaymentData &&
                  scheduledPaymentData.paymentDetails &&
                  scheduledPaymentData.paymentDetails.map(
                    (
                      paymentDT: {
                        orderTypeId: number;
                        orderId:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | React.ReactFragment
                          | React.ReactPortal
                          | null
                          | undefined;
                        id: any;
                        amount: any;
                      },
                      index: React.Key | null | undefined
                    ) => (
                      <div
                        key={index}
                        className={classes.schedulePaymentDetailsContainer}
                      >
                        <div className={classes.schedulePaymentDetailsInvSection}>
                          {paymentDT.orderTypeId === 1 ? 'Order' : 'Invoice'} -{' '}
                          {paymentDT.orderId}
                        </div>
                        <Controls.Input
                          name="amount"
                          size="small"
                          className={classes.schedulePaymentDetailsAmtSection}
                          label="Amount"
                          // @ts-ignore
                          error={amountError}
                          InputProps={{
                            // @ts-ignore
                            inputComponent: NumberFormatCustom,
                          }}
                          fullWidth
                          onChange={(e: any) => handleInputChange(e, paymentDT.id)}
                          value={paymentDT.amount}
                        />
                      </div>
                    )
                  )}
                <Box m={1} bgcolor="background.paper" />
                <div className={classes.recurringPaymentFooter}>
                  <Controls.Button
                    type="submit"
                    text="UPDATE"
                    disabled={
                      !scheduledPaymentData.amount ||
                      !scheduledPaymentData.paymentDate ||
                      !!amountError
                    }
                    onClick={updateScheduledPayment}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        )}
      </div>
    </Popup>
  );
};

export default ScheduledPaymentModal;
