import { createStore, applyMiddleware, compose } from 'redux';
// import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { rootReducer } from './rootReducer';

export const history = createBrowserHistory();

export const CreateStore = () => {
  // add support for Redux dev tools
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store = createStore(
    rootReducer(history),
    // composeEnhancers(applyMiddleware(thunk, reduxImmutableStateInvariant()))
    composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
  );
  return store;
};
