// @ts-nocheck
import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import buttonStyles from '../styles/buttonStyles';

interface IActionButton {
  color?: string;
  children?: React.ReactNode;
  onClick?: any;
  size?: string;
  type?: string;
  text?: string;
  customRoot?: any;
  customButtonType?: string;
  disabled?: boolean;
}

const ActionButton: React.FC<IActionButton> = (props) => {
  const {
    color,
    children,
    onClick,
    size,
    type,
    text,
    customRoot,
    customButtonType,
    disabled,
  } = props;
  let useStyles;

  if (!customRoot) {
    useStyles = makeStyles((theme) => ({
      root: {
        minWidth: 0,
        margin: theme.spacing(0.5),
      },
    }));
  }

  if (customButtonType) {
    if (customButtonType === 'closeModalButton')
      useStyles = makeStyles(() => ({
        root: buttonStyles.closeModalButton.root,
        label: buttonStyles.closeModalButton.label,
      }));
  } else {
    useStyles = makeStyles(() => ({
      root: customRoot,
      label: {
        textTransform: 'none',
      },
    }));
  }

  const classes = useStyles();

  return (
    <Button
      className={`${classes.root} ${classes[color]}`}
      onClick={onClick}
      size={size}
      type={type}
      text={text}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
