/** Interface Transactions initial state */

export interface IPaymentTransactionDetails {
  amount: number;
  id: number;
  orderId: string;
  orderType: string | null;
  orderTypeId: number;
  paymentTransaction: string | null;
  paymentTransactionId: number;
}

export interface ITransactionData {
  agencyId: string;
  companyName: string;
  description: string | null;
  emailAddress: string;
  firstName: string;
  lastName: string;
  paymentDate: Date;
  paymentStatus: string;
  paymentTransactionAmount: number;
  paymentTransactionDetails: IPaymentTransactionDetails[];
  paymentTransactionId: number;
  paymentType: string;
  recurringPaymentId: number | null;
  transactionDateTime: Date;
  userName: string;
}
export interface ITransactionsInitialState {
  transactionsData: ITransactionData[];
  totalRecords: number;
  isTransactionsLoading: boolean;
}

export const LOAD_TRANSACTIONS_SUCCESS = 'LOAD_TRANSACTIONS_SUCCESS';
export const EXPORT_TRANSACTIONS_SUCCESS = 'EXPORT_TRANSACTIONS_SUCCESS';
export const TRANSACTIONS_REQUEST_FAILURE = 'TRANSACTIONS_REQUEST_FAILURE';
export const TRANSACTIONS_REQUEST_LOADING = 'TRANSACTIONS_REQUEST_LOADING';

export interface ITransactionsRequestLoading {
  type: 'TRANSACTIONS_REQUEST_LOADING';
}
export interface ILoadTransactionsSuccess {
  type: 'LOAD_TRANSACTIONS_SUCCESS';
  data: {
    data: ITransactionData;
    filteredRecords: number;
    totalRecords: number;
  };
}
export interface IExportTransactionsSuccess {
  type: 'EXPORT_TRANSACTIONS_SUCCESS';
}
export interface ITransactionsRequestFailure {
  type: 'TRANSACTIONS_REQUEST_FAILURE';
}
export type ITransactionsActions =
  | ITransactionsRequestLoading
  | ILoadTransactionsSuccess
  | IExportTransactionsSuccess
  | ITransactionsRequestFailure;
