import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Collapse,
  Link,
} from '@material-ui/core';
import queryString from 'query-string';
import * as Actions from '../../../../redux/actionCreators/TransactionsActionCreators';
import * as UsersActions from '../../../../redux/actionCreators/UsersActionCreators';
import UseTable from '../../../../shared/controls/UseTable';
import ActionButton from '../../../../shared/controls/ActionButton';
import PageHeader from '../../../../shared/components/PageHeader';
import * as UseTableActions from '../../../../redux/actionCreators/common/UseTableActionCreators';
import Loader from '../../../../shared/components/Loader';
import SearchInput from '../../../../shared/controls/SearchInput';
import Controls from '../../../../shared/controls/Controls';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import { makeOrGetTableFiltersByKey } from '../../../../redux/selectors/common/UseTableSelectors';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import BasicDateRangePicker from '../../../../shared/controls/DateRangePicker';
import { getTruncatedCell, formatDate, formatCurrency } from '../../../../shared/helpers';
import Tooltip from '@material-ui/core/Tooltip';
import { getStatusStyles } from './helpers';
import TransactionDetail from './TransactionDetail';
import { initialState } from '../../../../rootReducer';
import { ITransactionData } from '../../../../redux/actionTypes/TransactionsActionTypes';

const Transactions: React.FC = () => {
  const [open, setOpen] = useState({});
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const classes = { ...clientPortalClasses, ...commonClasses };
  const headCells = [
    { id: 'userName', label: 'User Name' },
    { id: 'emailAddress', label: 'Email' },
    { id: 'companyName', label: 'Company' },
    { id: 'agencyId', label: 'Client/Agency ID' },
    { id: 'paymentTransactionId', label: 'Payment ID',fieldType: 'number'},
    { id: 'paymentTransactionAmount', label: 'Transaction Amt', fieldType: 'amount' },
    { id: 'transactionDateTime', label: 'Transaction Date', fieldType: 'date' },
    { id: 'paymentDate', label: 'Payment Date', fieldType: 'date' },
    { id: 'paymentType', label: 'Type', disableSorting: true, disableFiltering: true },
    {
      id: 'paymentStatus',
      label: 'Status',
      disableSorting: true,
      disableFiltering: true,
    },
    { id: 'actions', label: 'Actions', disableSorting: true, disableFiltering: true },
  ];
  const isIntialMount = useRef(true);
  const dispatch = useDispatch();

  const getAccountFiltersByPageKey = makeOrGetTableFiltersByKey();
  const {
    transactionsData,
    tableFilters,
    paymentTypes,
    paymentStatuses,
    totalRecords,
    isTransactionsLoading,
  } = useSelector((state: initialState) => ({
    transactionsData:
      state && state.transactionsReducer && state.transactionsReducer.transactionsData,
    tableFilters: getAccountFiltersByPageKey(state, 'transactions'),
    paymentTypes: state && state.usersReducer && state.usersReducer.paymentTypes,
    paymentStatuses: state && state.usersReducer && state.usersReducer.paymentStatuses,
    totalRecords:
      state && state.transactionsReducer && state.transactionsReducer.totalRecords,
    isTransactionsLoading: state.transactionsReducer.isTransactionsLoading,
  }));
  useEffect(() => {
    let orderBy = 'userName';
    let order = 'desc';
    let page = 0;
    let searchInput = '';
    let startDate = '';
    let endDate = '';
    let paymentStatus = '';
    let paymentType = '';
    if (isIntialMount.current) {
      isIntialMount.current = false;
      const parsed: any = queryString.parse(window.location.search);
      const isUrlParamsAvailable = Object.keys(parsed).length !== 0;
      const initialFilterValues = {
        pageKey: 'transactions',
        newFilters: {
          orderBy: isUrlParamsAvailable ? parsed.orderBy : 'username',
          order: isUrlParamsAvailable ? parsed.order : 'desc',
          page: isUrlParamsAvailable ? parseInt(parsed.page, 10) : 0,
          search: isUrlParamsAvailable ? parsed.searchInput : '',
          paymentType: isUrlParamsAvailable ? parsed.paymentType : '',
          paymentStatus: isUrlParamsAvailable ? parsed.paymentStatus : '',
        },
      };
      UseTableActions.setTableFilters(dispatch, initialFilterValues);
      if (Object.keys(parsed).length !== 0) {
        order = parsed.order;
        orderBy = parsed.orderBy;
        page = parseInt(parsed.page, 10);
        searchInput = parsed.searchInput;
        startDate = parsed.startDate;
        endDate = parsed.endDate;
        paymentType = parsed.paymentType;
        paymentStatus = parsed.paymentStatus;
      }
    } else {
      order = tableFilters.order;
      orderBy = tableFilters.orderBy;
      page = tableFilters.page;
      searchInput = tableFilters.search;
      startDate = tableFilters.startDate;
      endDate = tableFilters.endDate;
      paymentType = tableFilters.paymentType;
      paymentStatus = tableFilters.paymentStatus;
    }
    const newPage = page.toString();
    dispatch(
      push({
        search: `?${new URLSearchParams({
          order,
          orderBy,
          page: newPage,
          searchInput,
          startDate,
          endDate,
          paymentType,
          paymentStatus,
        }).toString()}`,
      })
    );

    if (tableFilters) {
      if (
        (!!tableFilters.startDate && !!tableFilters.endDate) ||
        (tableFilters.startDate === undefined && tableFilters.endDate === undefined)
      ) {
        dispatch(Actions.loadTransactions());
        dispatch(UsersActions.fetchPaymentStatuses());
        dispatch(UsersActions.fetchPaymentTypes());
      }
    }
  }, [
    tableFilters.order,
    tableFilters.orderBy,
    tableFilters.page,
    tableFilters.startDate,
    tableFilters.endDate,
    tableFilters.paymentStatus,
    tableFilters.paymentType,
  ]);

  /** Reset Page Filters When page Unmounts */
  useEffect(
    () => () => {
      UseTableActions.resetFilters(dispatch, 'transactions');
    },
    []
  );

  const handleOpenFileOuput = (item: ITransactionData) => {
    const id = `${item.paymentTransactionId}`;
    setOpen({
      // @ts-ignore
      [id]: !open[id],
    });
  };
  const handleOnChangeFilter = (e: { target: { value: any; name: any } }) => {
    const { value, name } = e.target;
    const filterValue = {
      pageKey: 'transactions',
      newFilters: {
        [name]: value,
      },
    };
    UseTableActions.setTableFilters(dispatch, filterValue);
  };
  const renderTransactionChanges = () => (
    <div className={`${classes.global}`}>
      <Paper className={classes.pageContent} elevation={0}>
        <PageHeader
          title="Transactions"
          customHeaderToolbarClass={classes.pageHeaderToolbar}
          pageHeaderOptions={
            <div className={classes.registrationKeyPageHeaderOptions}>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <div className={classes.datePickerDropdown}>
                  <div className={classes.filterByDateTransacLabel}>Filter by Date</div>
                  <div className={classes.filterByDateTransacions}>
                    <BasicDateRangePicker pageKey="transactions" />
                  </div>
                </div>
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <Controls.Select
                  name="paymentStatus"
                  label="Payment Status"
                  customFormControl={{
                    minWidth: 160,
                    marginRight: '5px',
                    marginTop: '8px',
                    '& .MuiOutlinedInput-root': {
                      height: '42px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      top: '-5px !important',
                    },
                  }}
                  value={tableFilters && tableFilters.paymentStatus}
                  // @ts-ignore
                  onChange={handleOnChangeFilter}
                  options={paymentStatuses || []}
                />
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <Controls.Select
                  name="paymentType"
                  label="Payment Type"
                  customFormControl={{
                    minWidth: 160,
                    marginRight: '5px',
                    marginTop: '8px',
                    '& .MuiOutlinedInput-root': {
                      height: '42px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      top: '-5px !important',
                    },
                  }}
                  value={tableFilters && tableFilters.paymentType}
                  // @ts-ignore
                  onChange={handleOnChangeFilter}
                  options={paymentTypes || []}
                />
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <Controls.Button
                  text="Download"
                  type="button"
                  color="primary"
                  title="All transactions data based on search and filters will be downloaded into a csv file."
                  className={classes.exportTransactionsBtn}
                  startIcon={<ImportExportIcon />}
                  onClick={() => {
                    dispatch(Actions.downloadTransactions());
                  }}
                />
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol2}>
                <div>
                  <SearchInput
                    pageKey="transactions"
                    onSearchAction={() => {
                      dispatch(Actions.loadTransactions());
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
        <UseTable
          headCells={headCells}
          totalRecords={totalRecords}
          pageKey="transactions"
          hasColumnFilter
          onColumnFilterAction={() => {
            dispatch(Actions.loadTransactions());
          }}
        >
          <TableBody>
            {transactionsData.map((item, index) => (
              <>
                <TableRow key={`${item.userName}_${index}`}>
                  <TableCell>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() =>
                        dispatch(
                          UsersActions.loadUsersTransactionHistory(true, item.userName)
                        )
                      }
                    >
                      {item.userName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={item.emailAddress ? item.emailAddress : ''} arrow>
                      <div> {getTruncatedCell(item.emailAddress, 15)}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{item.companyName}</TableCell>
                  <TableCell>{item.agencyId}</TableCell>
                  <TableCell>{item.paymentTransactionId}</TableCell>
                  <TableCell>{formatCurrency(item.paymentTransactionAmount)}</TableCell>
                  <TableCell>{formatDate(item.transactionDateTime)}</TableCell>
                  <TableCell>{formatDate(item.paymentDate)}</TableCell>
                  <TableCell>{item.paymentType}</TableCell>
                  <TableCell>
                    <Box
                      p={0.5}
                      m={1}
                      color="white !important"
                      borderRadius="8px"
                      bgcolor={getStatusStyles(item.paymentStatus)}
                    >
                      <Box textAlign="center">{item.paymentStatus}</Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <ActionButton
                      color="secondary"
                      onClick={() => handleOpenFileOuput(item)}
                      disabled={item?.paymentTransactionDetails?.length < 1}
                    >
                      <Tooltip title="Transaction Details" arrow>
                        <ExitToAppOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </ActionButton>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse
                      // @ts-ignore
                      in={open[`${item.paymentTransactionId}`]}
                      timeout="auto"
                      unmountOnExit
                      className={classes.collapseOutput}
                    >
                      <Box margin={1}>
                        <TransactionDetail
                          details={
                            item.paymentTransactionDetails &&
                            item.paymentTransactionDetails
                          }
                        />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </UseTable>
      </Paper>
    </div>
  );
  return (
    <>
      <>
        <Loader isLoading={isTransactionsLoading} />
        {transactionsData ? renderTransactionChanges() : <></>}
      </>
    </>
  );
};

export default Transactions;
