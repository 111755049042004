import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Card from '@material-ui/core/Card';
import { Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { LocalizationProvider } from '@material-ui/pickers';
/* eslint-disable import/no-extraneous-dependencies */
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import moment from 'moment';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import * as DatePicker from '../../../../shared/controls/DatePicker';
import Controls from '../../../../shared/controls/Controls';
import Form from '../../../../shared/controls/UseForm';
import { downloadTransactionPayment } from '../../../../redux/actionCreators/ReportingActionCreators';
import TextField from '@material-ui/core/TextField';

interface ISelectedDatesState {
  startDate: string | null;
  endDate: string | null;
}

const RegisteredUsers: React.FC = () => {
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const classes = { ...clientPortalClasses, ...commonClasses };

  const dispatch = useDispatch();

  const [selectedDates, setSelectedDates] = useState<ISelectedDatesState>({
    startDate: null,
    endDate: null,
  });

  const handleStartDateOnChange = (date: any) => {
    setSelectedDates({
      ...selectedDates,
      startDate: moment(date).format(),
    });
  };

  const handleEndDateOnChange = (date: any) => {
    setSelectedDates({
      ...selectedDates,
      endDate: moment(date).format(),
    });
  };

  const { startDate, endDate } = selectedDates;

  const handleDownload = () => {
    dispatch(
      downloadTransactionPayment(
        `${moment(startDate).format('L')} 12:00:00 AM`,
        `${moment(endDate).format('L')} 11:59:00 PM`,
        'registeredUsers'
      )
    );
  };
  const hasDateSelectionError = () =>
    !startDate ||
    !endDate ||
    (endDate && endDate.valueOf() < startDate && startDate.valueOf());
  return (
    <Grid item xs={3}>
      <Card elevation={0} className={classes.configContent}>
        <CardHeader title="Registered Users" />
        <CardContent>
          <Form>
            <Grid container>
              <Grid item>
                <div>
                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <DatePicker.InvoiceAutomationHeader
                      // @ts-ignore
                      autoOk
                      disableToolbar
                      variant="outlined"
                      format="MM/dd/yyyy"
                      margin="normal"
                      label="Registered Start Date"
                      data-testid="date-picker"
                      emptyLabel="mm/dd/yyyy"
                      value={startDate}
                      renderInput={(props) => <TextField {...props} />}
                      onChange={handleStartDateOnChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={DateFnsUtils}>
                    <DatePicker.InvoiceAutomationHeader
                      // @ts-ignore
                      autoOk
                      disableToolbar
                      variant="outlined"
                      format="MM/dd/yyyy"
                      margin="normal"
                      label="Registered End Date"
                      emptyLabel="mm/dd/yyyy"
                      value={endDate}
                      renderInput={(props) => <TextField {...props} />}
                      minDate={startDate}
                      onChange={handleEndDateOnChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </LocalizationProvider>
                </div>
                <div className={classes.configurationButtons}>
                  <Controls.Button
                    size="large"
                    type="button"
                    text="Download"
                    disabled={!!hasDateSelectionError()}
                    onClick={handleDownload}
                  />
                </div>
              </Grid>
            </Grid>
          </Form>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default RegisteredUsers;
