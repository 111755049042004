/** Interface for Toast state */
export interface IToastInitialState {
  isOpen: boolean;
  message?: string;
  type?: any;
}

/** Action Type to show toast message */
export const SHOW_TOAST_MESSAGE = 'SHOW_TOAST_MESSAGE';

export interface IShowToastMessage {
  type: 'SHOW_TOAST_MESSAGE';
  data: IToastInitialState;
}

/** Interface for Toast Actions */
export type IToastActions = IShowToastMessage;
