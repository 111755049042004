 /* @ts-ignore */
import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import Layout from './shared/layout/Layout';
import Routes from './Routes';
import { history } from './Store';

function App() {
  return (
    /* @ts-ignore */
    <ConnectedRouter history={history}>
      <Layout>
        <Routes />
      </Layout>
    </ConnectedRouter>
  );
}
export default App;
