import { ClientPortalLinks, IClientPortalLinks } from './clientPortalConfig';

export interface IPrimaryLinks {
  id: number;
  primaryLinkName: string;
  primaryRoute: string;
  subLinks?: IClientPortalLinks[];
}

/** Routes Config for Primary Links Pages */
export const PrimaryLinks: IPrimaryLinks[] = [
  {
    id: 1,
    primaryLinkName: 'Client Admin Portal',
    primaryRoute: '/',
    subLinks: ClientPortalLinks,
  },
  {
    id: 2,
    primaryLinkName: 'Finance Portal',
    primaryRoute: `${process.env.REACT_APP_FINANCE_PORTAL_URL}`,
  },
];
