/* eslint-disable no-unused-vars */
import { Paper} from '@material-ui/core';
import UseTable from '../../../../shared/controls/UseTable';
import PageHeader from '../../../../shared/components/PageHeader';
import SearchInput from '../../../../shared/controls/SearchInput';
import BasicDateRangePicker from '../../../../shared/controls/DateRangePicker';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import React, { useEffect, useRef } from 'react';
import queryString from 'query-string';
import * as UseTableActions from '../../../../redux/actionCreators/common/UseTableActionCreators';
import { push } from 'connected-react-router';
import * as Actions from '../../../../redux/actionCreators/AdportalActionCreators';
import { makeOrGetTableFiltersByKey } from '../../../../redux/selectors/common/UseTableSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { initialState } from '../../../../rootReducer';
import {  TableBody, TableRow, TableCell} from '@material-ui/core';
import {  formatDate, formatCurrency } from '../../../../shared/helpers';
import Loader from '../../../../shared/components/Loader';
import Controls from '../../../../shared/controls/Controls';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const AdportalTransactions: React.FC = () => {
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const classes = { ...clientPortalClasses, ...commonClasses };
  const dispatch = useDispatch();
  const getAccountFiltersByPageKey = makeOrGetTableFiltersByKey();
  const {
    adportalData,
    tableFilters,
    totalRecords,
    isadportalLoading
  } = useSelector((state: initialState) => ({
    adportalData: state && state.adportalReducer && state.adportalReducer.adportalData,
    tableFilters: getAccountFiltersByPageKey(state, 'adportal'),
    totalRecords: state && state.adportalReducer && state.adportalReducer.totalRecords,
    isadportalLoading: state.adportalReducer.isAdportalLoading
  }));
// eslint-disable-next-line no-console
  const headCells = [
    { id: 'PaymentId', label: 'Payment Id', disableSorting: true },
    { id: 'FirstName', label: 'First Name' },
    { id: 'LastName', label: 'Last Name' },
    { id: 'Company', label: 'Company' },
    { id: 'InvoiceNumber', label: 'Invoice' },
    { id: 'PaymentDate', label: 'Payment Date' },
    { id: 'Amount', label: 'Amount' },
    { id: 'PaymentStatusId', label: 'Status' },
    { id: 'Email', label: 'Email'},
    { id: 'Phone', label: 'Phone'},
    { id: 'Address', label: 'Address'},
  ];

  const isIntialMount = useRef(true);
  useEffect(() => {
    let orderBy = 'firstName';
    let order = 'desc';
    let page = 0;
    let searchInput = '';
    let startDate = '';
    let endDate = '';
    if (isIntialMount.current) {
      isIntialMount.current = false;
      const parsed: any = queryString.parse(window.location.search);
      const isUrlParamsAvailable = Object.keys(parsed).length !== 0;
      const initialFilterValues = {
        pageKey: 'adportal',
        newFilters: {
          orderBy: isUrlParamsAvailable ? parsed.orderBy : 'firstName',
          order: isUrlParamsAvailable ? parsed.order : 'desc',
          page: isUrlParamsAvailable ? parseInt(parsed.page, 10) : 0,
          search: isUrlParamsAvailable ? parsed.searchInput : '',
        },
      };
      UseTableActions.setTableFilters(dispatch, initialFilterValues);
      if (Object.keys(parsed).length !== 0) {
        order = parsed.order;
        orderBy = parsed.orderBy;
        page = parseInt(parsed.page, 10);
        searchInput = parsed.searchInput;
        startDate = parsed.startDate;
        endDate = parsed.endDate;
      }
    } else {
      order = tableFilters.order;
      orderBy = tableFilters.orderBy;
      page = tableFilters.page;
      searchInput = tableFilters.search;
      startDate = tableFilters.startDate;
      endDate = tableFilters.endDate;
    }
    const newPage = page.toString();
    dispatch(
      push({
        search: `?${new URLSearchParams({
          order,
          orderBy,
          page: newPage,
          searchInput,
          startDate,
          endDate,
        }).toString()}`,
      })
    );
    if (
      tableFilters &&
      tableFilters.startDate !== null &&
      tableFilters.endDate !== null
    ) {
      dispatch(Actions.loadAdportalTransactions());
    }
  }, [
    tableFilters.order,
    tableFilters.orderBy,
    tableFilters.page,
    tableFilters.startDate,
    tableFilters.endDate,
  ]);
  useEffect(
    () => () => {
      UseTableActions.resetFilters(dispatch, 'adportal');
    },
    []
  );

  const renderAdportalTransactions = () => (
      <div className={`${classes.global}`}>
      <Paper className={classes.pageContent} elevation={0}>
        <PageHeader
          title="Adportal Transactions"
          customHeaderToolbarClass={classes.pageHeaderToolbar}
          pageHeaderOptions={
            <div className={classes.registrationKeyPageHeaderOptions}>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <div className={classes.statusDropdown}>
                  <div className={classes.filterByDateTransacLabel}>
                    Filter by payment date
                  </div>
                  <div className={classes.filterByDateTransacions}>
                    <BasicDateRangePicker pageKey="adportal" />
                  </div>
                </div>
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <Controls.Button
                  text="Download"
                  type="button"
                  color="primary"
                  title="All transactions data based on search and filters will be downloaded into a csv file."
                  className={classes.exportTransactionsBtn}
                  startIcon={<ImportExportIcon />}
                  onClick={() => {
                     dispatch(Actions.downloadAdportalTransactions());
                  }}
                />
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol2}>
                <div>
                  <SearchInput
                    pageKey="adportal"
                    onSearchAction={() => {
                      dispatch(Actions.loadAdportalTransactions());
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
        <UseTable headCells={headCells} totalRecords={totalRecords} pageKey="adportal">
          <TableBody>
            {adportalData.map((item, index) => (
              <TableRow key={index}>
                 <TableCell>{item.paymentId}</TableCell>
                <TableCell>{item.firstName}</TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.company}</TableCell>
                <TableCell>{item.invoiceNumber}</TableCell>
                <TableCell>{formatDate(item.paymentDate)}</TableCell>
                <TableCell>{formatCurrency(item.amount)}</TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{item.address}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </UseTable>
      </Paper>
    </div>
    );
    return (
      <>
        <>
          <Loader isLoading={isadportalLoading} />
          {adportalData ? renderAdportalTransactions() : <></>}
        </>
      </>
    );
};
export default AdportalTransactions;