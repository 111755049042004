import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import { Paper } from '@material-ui/core';
import Header from '../header/Header';
import SideBar from '../sideNavigation/SideBar';
import Toasts from '../components/Toasts';
import Wrapper from './Wrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '0',
  },
  toolbar: {
    minHeight: '94px !important',
    color: `${theme.palette.common.white} !important`,
    backgroundColor: `${theme.palette.common.white} !important`,
  },
  paper: {
    padding: theme.spacing(2),
    paddingBottom: '50px',
    flexGrow: 1,
  },
}));

interface ILayout {
  children: React.ReactNode;
}

/** Application Layout Component */
const Layout: React.FC<ILayout> = (props) => {
  const classes = useStyles();
  const { children } = props;
  return (
    <div className={classes.root}>
      <Header />
      <SideBar />
      {/* @ts-ignore */}
      <Container maxWidth="false">
        <Paper elevation={3} className={classes.paper}>
          <Toolbar className={classes.toolbar} />
          <Toasts />
          <Wrapper>{children}</Wrapper>
        </Paper>
      </Container>
    </div>
  );
};

export default Layout;
