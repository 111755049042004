import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { TextField, InputAdornment } from '@material-ui/core';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import * as UseTableActions from '../../redux/actionCreators/common/UseTableActionCreators';
import { makeOrGetTableFiltersByKey } from '../../redux/selectors/common/UseTableSelectors';
import { initialState } from '../../rootReducer';

interface ISearchInput {
  pageKey: string;
  onSearchAction: any;
  className?: string;
}

/** Search Input Component */
const SearchInput: React.FC<ISearchInput> = (props) => {
  const dispatch = useDispatch();
  const getTableFiltersByPageKey = makeOrGetTableFiltersByKey();
  const { tableFilters } = useSelector((state: initialState) => ({
    tableFilters: getTableFiltersByPageKey(state, props.pageKey),
  }));
  const { orderBy, order, page, userName } = tableFilters;

  const parsed: any = queryString.parse(window.location.search);
  const [searchState, setSearchState] = useState(parsed.searchInput);
  const handleSearchChange = (event: { target: { value: any } }) => {
    dispatch(
      push({
        search: `?${new URLSearchParams({
          order,
          orderBy,
          page,
          userName,
          searchInput: event.target.value || '',
        }).toString()}`,
      })
    );
    setSearchState(event.target.value || '');
  };

  const handleSetTableFiltersOnEnter = (searchValue: any) => {
    const filterValue = {
      pageKey: props.pageKey,
      newFilters: {
        userName,
        search: searchValue,
        page: 0,
      },
    };
    UseTableActions.setTableFilters(dispatch, filterValue);
  };

  const handleEnterSearch = () => {
    handleSetTableFiltersOnEnter(parsed.searchInput);
    props.onSearchAction();
  };

  return (
    <TextField
      label="Search"
      value={searchState}
      className={props.className}
      InputProps={{
        startAdornment: (
          // @ts-ignore
          <InputAdornment>
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={handleSearchChange}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          ev.preventDefault();
          handleEnterSearch();
        }
      }}
    />
  );
};

export default SearchInput;
