import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  textColor: {
    color: theme.palette.secondary.dark,
  },
  divider: {
    width: '90%',
    display: 'block',
    background: '#187196',
    height: '1px',
    margin: '1rem',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subTitle: {
    display: 'block',
    minWidth: 'fit-content',
  },
}));

interface ISubHeader {
  subTitle: string;
  divider?: any;
}

export const SubHeader: React.FC<ISubHeader> = (props) => {
  const classes = useStyles();
  const { subTitle, divider } = props;
  return (
    <Typography
      variant="h6"
      component="div"
      className={`${classes.textColor} ${classes.flex}`}
    >
      <span className={classes.subTitle}>{subTitle}</span>
      {divider && <span className={classes.divider} />}
    </Typography>
  );
};
