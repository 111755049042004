import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const useCommonClasses = makeStyles((theme) => ({
  global: {
    width: '100%',
    '@global': {
      '.MuiTableSortLabel-root.MuiTableSortLabel-active': {
        color: theme.palette.common.white,
      },
      '.MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
      '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        // @ts-ignore
        border: `1px solid ${theme.palette.grey.slate} !important`,
      },
      '.MuiInput-underline:after': {
        borderBottom: 'none',
      },
      '.PickerWithState-root-141 .MuiInput-root': {
        // @ts-ignore
        border: `1px solid ${theme.palette.grey.slate} !important`,
      },
      '.MuiTableCell-head': {
        '@media only screen and (min-width: 1563px)': {
          lineHeight: '2.5',
        },
      },
      '.MuiToolbar-root': {
        position: 'relative',
        alignItems: 'baseline !important',
      },
      '.headerOptions': {
        marginLeft: '.7rem',
        paddingTop: '1rem',
        width: '100%',

        '@media only screen and (min-width: 1563px)': {
          marginLeft: '0rem',
        },
      },
      '.MuiContainer-root': {
        width: '100%',
        display: 'block',
        boxSizing: 'border-box',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '0px',
        paddingRight: '0px',
      },
      '.MuiTableSortLabel-root:hover': {
        color: 'rgb(0, 241, 255)',
      },
      '.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon':
        {
          color: 'rgb(255, 255, 255)',
          opacity: '1',
        },
    },
  },
  upperPad: {
    paddingTop: '1rem !important',
  },
  upperPadMed: {
    paddingTop: '2rem !important',
  },
  lowerPad: {
    paddingBottom: '1rem !important',
  },
  lowerPadMed: {
    paddingBottom: '2rem !important',
  },
  center: {
    textAlign: 'center',
    '& .MuiSelect-root': {
      textAlign: 'left',
    },
    '& input': {
      textAlign: 'left',
    },
  },
  medBlue: {
    color: theme.palette.secondary.main,
  },
  pageContent: {
    padding: '.5rem',
    paddingBottom: '1rem',
  },
  root: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  container: {
    paddingLeft: '0px',
    paddingRight: '0px',
    maxWidth: '1410px',
  },
  containerContent: {
    padding: '0rem',
    paddingTop: '0rem',
  },
  submitbuttonWrapper: {
    width: '200px',
    margin: 'auto',
  },
  textBoxSpacing: {
    paddingBottom: '.5rem',
  },
  flexDiv: {
    display: 'flex',
  },
  fullWidthHeader: {
    width: '100%',
    position: 'relative',
    top: '-5px',
  },
  pageHeaderToolbar: {
    paddingBottom: '0rem !important',
  },
  customerImportFileDrop: {
    minHeight: '150px !important',
  },
  registrationKeyPageHeaderOptionsCol2: {
    paddingRight: '1.2rem',
    '@media only screen and (min-width: 1563px)': {
      paddingRight: '.6rem',
    },
    registrationKeyModalProps: {
      maxWidth: '500px',
    },
  },
  registrationKeyPageHeaderOptionsCol1: {
    paddingRight: '1.2rem',
    '@media only screen and (min-width: 1563px)': {
      paddingRight: '1.6rem',
      position: 'relative',
      top: '.35rem',
    },
  },
  registrationKeyPageHeaderOptions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    top: '-1rem',
  },
  accountAdjustmentsPageHeaderOptionsCol2: {
    paddingRight: '1.2rem',
    '@media only screen and (min-width: 1563px)': {
      paddingRight: '.6rem',
    },
  },
  accountAdjustmentsPageHeaderOptions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    top: '-1rem',
  },
  accountChangesPageHeaderSearchField: {
    marginRight: '0rem',
    marginLeft: 'auto',
    position: 'relative',
    top: '-10px',
    width: '200px',

    '@media only screen and (min-width: 1563px)': {
      marginLeft: 'auto',
      marginRight: '.7rem',
    },
  },
  configurationButtons: {
    paddingLeft: '.6rem',
  },
  recurringPaymentDownloadButton: {
    paddingLeft: '.6rem',
    marginTop: '4.5rem',
  },
  importCustomerModalProps: {
    maxWidth: '500px',
  },
  exportTransactionsBtn: {
    padding: '8px 12px',
    backgroundColor: `${green[700]} !important`,
    marginTop: '8px',
    height: '40px',
  },
  recurringRadioDiv: {
    flexDirection: 'row',
  },
}));

export default useCommonClasses;
