import { Dispatch } from 'redux';
import * as types from '../../actionTypes/common/ToastsActionTypes';

/** Action to open confirmation modal */
export const showToastMessage = (
  dispatch: Dispatch<any>,
  data: types.IToastInitialState
) => {
  const toast = {
    type: types.SHOW_TOAST_MESSAGE,
    data,
  };
  dispatch(toast);
};
