import { createSelector } from 'reselect';

// user selector
const getUserInfo = () => {
  const loggedInUser: any = localStorage.getItem('loggedInUser');
  return JSON.parse(loggedInUser);
};

// reselect function to get user info
export const getUserInfoState = createSelector(
  [getUserInfo],
  (userInfo: any) => userInfo
);