import React from 'react';
import { Box, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { formatDateMMDDYYYY } from '../helpers';

const useStyles = makeStyles((theme) => ({
  dateStyle: {
    color: `${theme.palette.grey[400]}`,
    fontSize: '.75rem !important',
  },
  title: {
    fontSize: '1rem !important',
    wordBreak: 'break-all',
    fontWeight: 'bold',
  },
  description: {
    fontSize: '.8rem !important',
    wordBreak: 'break-all',
  },
  detailLabel: {
    fontSize: '.8rem !important',
  },
  pushRight: {
    paddingTop: '4px !important',
  },
  bold: {
    fontWeight: 'bold',
  },
  padLeft: {
    paddingLeft: '1rem',
  },
  box: {
    marginLeft: '0rem !important',
    marginRight: '0rem !important',
  },
}));

interface ITitledListItem {
  title?: string;
  detailLabel?: string;
  detail?: string;
  titleTwo?: string;
  detailLabelTwo?: string;
  detailTwo?: string;
  titleThree?: string;
  detailLabelThree?: string;
  detailThree?: string;
  date?: Date;
  description?: string;
  dividerStyle?: string;
  action?: any;
  id?: number;
}

export const TitledListItem: React.FC<ITitledListItem> = ({
  title,
  detailLabel,
  detail,
  titleTwo,
  detailLabelTwo,
  detailTwo,
  titleThree,
  detailLabelThree,
  detailThree,
  date,
  description,
  dividerStyle,
  action,
  id,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <div {...rest}>
      <Grid container md={12} xs={9} spacing={3}>
        <Grid item md={3} xs={3}>
          {title && <Typography className={classes.title}>{title}</Typography>}
          {date && (
            <Typography variant="caption" className={classes.dateStyle}>
              {formatDateMMDDYYYY(date)}
            </Typography>
          )}
          {detail && (
            <Typography className={classes.detailLabel}>
              <span>{detailLabel}:</span>
              <span>{detail}</span>
            </Typography>
          )}
          {titleTwo && <Typography className={classes.title}>{titleTwo}</Typography>}
          {detailTwo && (
            <Typography className={classes.detailLabel}>
              <span className={classes.bold}>{detailLabelTwo}:</span>
              <span className={classes.padLeft}>{detailTwo}</span>
            </Typography>
          )}
          {titleThree && <Typography className={classes.title}>{titleThree}</Typography>}
          {detailThree && (
            <Typography className={classes.detailLabel}>
              <span className={classes.bold}>{detailLabelThree}:</span>
              <span className={classes.padLeft}>{detailThree}</span>
            </Typography>
          )}
        </Grid>
        {description && (
          <Grid item md={4}>
            <Typography className={classes.description}>
              {description ?? 'No description.'}
            </Typography>
          </Grid>
        )}
        {action && (
          <Grid item md={4} className={classes.pushRight}>
            {action(id)}
          </Grid>
        )}
      </Grid>

      <Box m={2} className={classes.box}>
        {/** @ts-ignore */}
        <Divider style={dividerStyle} />
      </Box>
    </div>
  );
};
