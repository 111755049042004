import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loading from '../../images/loading.gif';

const useStyles = makeStyles(() => ({
  loader: {
    position: 'fixed',
    top: '64px',
    left: 0,
    height: '100vh' /* to make it responsive */,
    width: '100vw' /* to make it responsive */,
    overflow: 'hidden' /* to remove scrollbars */,
    zIndex: 99999 /* to make it appear on topmost part of the page */,
    backgroundColor: '#FFFFFFdb',
    visibility: 'visible',
    opacity: 1,
    transition: 'opacity 2s linear',
  },
  fadeOut: {
    backgroundColor: '#FFFFFFdb',
    position: 'fixed',
    top: '84/px',
    left: 0,
    height: '100vh' /* to make it responsive */,
    width: '100vw' /* to make it responsive */,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s .5s, opacity .5s linear',
  },
  loaderImg: {
    position: 'relative',
    top: 'calc(50% - 60px)',
    left: 'calc(50% - 60px)',
  },
}));

interface ILoader {
  isLoading: boolean;
}

const Loader: React.FC<ILoader> = ({ isLoading = true }) => {
  const classes = useStyles();

  return (
    <div id="loader" className={isLoading ? classes.loader : classes.fadeOut}>
      <img src={Loading} className={classes.loaderImg} alt="Loading..." />
    </div>
  );
};

export default Loader;
