import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Paper, TableBody, TableRow, TableCell, Box, Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import Edit from '@material-ui/icons/Edit';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import queryString from 'query-string';
import * as Actions from '../../../../redux/actionCreators/UsersActionCreators';
import UseTable from '../../../../shared/controls/UseTable';
import ActionButton from '../../../../shared/controls/ActionButton';
import PageHeader from '../../../../shared/components/PageHeader';
import * as UseTableActions from '../../../../redux/actionCreators/common/UseTableActionCreators';
import Loader from '../../../../shared/components/Loader';
import SearchInput from '../../../../shared/controls/SearchInput';
import Popup from '../../../../shared/controls/Popup';
import Controls from '../../../../shared/controls/Controls';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import { makeOrGetTableFiltersByKey } from '../../../../redux/selectors/common/UseTableSelectors';
import BasicDateRangePicker from '../../../../shared/controls/DateRangePicker';
import { formatDateMMDDYYYY, getTruncatedCell } from '../../../../shared/helpers';
import ValidationError from './ValidationError';
import { initialState } from '../../../../rootReducer';

interface IUserDetailsState {
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  accountExecutive: string;
  agencyId: string;
  phoneNumber: string;
}

const Users: React.FC = () => {
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const classes = { ...clientPortalClasses, ...commonClasses };
  const dispatch = useDispatch();

  const getAccountFiltersByPageKey = makeOrGetTableFiltersByKey();
  const {
    usersData,
    updateProfileError,
    isEditModalOpen,
    tableFilters,
    totalRecords,
    isUsersLoading,
  } = useSelector((state: initialState) => ({
    usersData: state && state.usersReducer && state.usersReducer.usersData,
    tableFilters: getAccountFiltersByPageKey(state, 'users'),
    totalRecords: state && state.usersReducer && state.usersReducer.totalRecords,
    isUsersLoading: state.usersReducer.isUsersLoading,
    updateProfileError: state.usersReducer.updateProfileError,
    isEditModalOpen: state && state.usersReducer && state.usersReducer.isEditModalOpen,
  }));
  const headCells = [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'userName', label: 'User Name' },
    { id: 'emailAddress', label: 'Email' },
    { id: 'phoneNumber', label: 'Phone' },
    { id: 'createdDateTime', label: 'Created Date' },
    { id: 'accountExecutive', label: 'AE' },
    { id: 'companyName', label: 'Company' },
    { id: 'agencyId', label: 'Customer Id' },
    { id: 'actions', label: 'Actions', disableSorting: true },
  ];
  const isIntialMount = useRef(true);
  const [userDetails, setEditUser] = useState<IUserDetailsState>({
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    accountExecutive: '',
    agencyId: '',
    phoneNumber: '',
  });
  const [userName, setUserName] = useState('');

  useEffect(() => {
    let orderBy = 'firstName';
    let order = 'desc';
    let page = 0;
    let searchInput = '';
    let startDate = '';
    let endDate = '';
    if (isIntialMount.current) {
      isIntialMount.current = false;
      const parsed: any = queryString.parse(window.location.search);
      const isUrlParamsAvailable = Object.keys(parsed).length !== 0;
      const initialFilterValues = {
        pageKey: 'users',
        newFilters: {
          orderBy: isUrlParamsAvailable ? parsed.orderBy : 'firstName',
          order: isUrlParamsAvailable ? parsed.order : 'desc',
          page: isUrlParamsAvailable ? parseInt(parsed.page, 10) : 0,
          search: isUrlParamsAvailable ? parsed.searchInput : '',
        },
      };
      UseTableActions.setTableFilters(dispatch, initialFilterValues);
      if (Object.keys(parsed).length !== 0) {
        order = parsed.order;
        orderBy = parsed.orderBy;
        page = parseInt(parsed.page, 10);
        searchInput = parsed.searchInput;
        startDate = parsed.startDate;
        endDate = parsed.endDate;
      }
    } else {
      order = tableFilters.order;
      orderBy = tableFilters.orderBy;
      page = tableFilters.page;
      searchInput = tableFilters.search;
      startDate = tableFilters.startDate;
      endDate = tableFilters.endDate;
    }
    const newPage = page.toString();
    dispatch(
      push({
        search: `?${new URLSearchParams({
          order,
          orderBy,
          page: newPage,
          searchInput,
          startDate,
          endDate,
        }).toString()}`,
      })
    );
    if (
      tableFilters &&
      tableFilters.startDate !== null &&
      tableFilters.endDate !== null
    ) {
      dispatch(Actions.loadUsers());
    }
  }, [
    tableFilters.order,
    tableFilters.orderBy,
    tableFilters.page,
    tableFilters.startDate,
    tableFilters.endDate,
  ]);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setEditUser({ ...userDetails, [name]: value });
  };

  const handleUpdateProfile = () => {
    const data = {
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      email: userDetails?.email,
      companyName: userDetails?.companyName,
      accountExecutive: userDetails?.accountExecutive,
      agencyId: userDetails?.agencyId,
      phoneNumber: userDetails?.phoneNumber,
    };
    dispatch(Actions.updateUserDetails(data, userName));
  };

  const renderUserChanges = () => (
    <div className={`${classes.global}`}>
      <Paper className={classes.pageContent} elevation={0}>
        <PageHeader
          title="Users"
          customHeaderToolbarClass={classes.pageHeaderToolbar}
          pageHeaderOptions={
            <div className={classes.registrationKeyPageHeaderOptions}>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <div className={classes.statusDropdown}>
                  <div className={classes.filterByDateTransacLabel}>
                    Filter by User Registered date
                  </div>
                  <div className={classes.filterByDateTransacions}>
                    <BasicDateRangePicker pageKey="users" />
                  </div>
                </div>
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol2}>
                <div>
                  <SearchInput
                    pageKey="users"
                    onSearchAction={() => {
                      dispatch(Actions.loadUsers());
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
        <UseTable headCells={headCells} totalRecords={totalRecords} pageKey="users">
          <TableBody>
            {usersData.map((item, index) => (
              <TableRow key={`${item.userName}_${index}`}>
                <TableCell>{item.firstName}</TableCell>
                <TableCell>{item.lastName}</TableCell>
                <TableCell>{item.userName}</TableCell>
                <TableCell>
                  <Tooltip title={item.emailAddress ? item.emailAddress : ''} arrow>
                    <div> {getTruncatedCell(item.emailAddress, 15)}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>{item.phoneNumber}</TableCell>
                <TableCell>{formatDateMMDDYYYY(item.createdDateTime)}</TableCell>
                <TableCell>{item.accountExecutive}</TableCell>
                <TableCell>{item.companyName}</TableCell>
                <TableCell>{item.agencyId}</TableCell>
                <TableCell>
                  <ActionButton
                    color="secondary"
                    onClick={() => {
                      Actions.toggleEditModal(dispatch, true);
                      // @ts-ignore
                      setEditUser(item);
                      setUserName(item.userName);
                    }}
                  >
                    <Tooltip title="Edit User Details" arrow>
                      <Edit fontSize="small" />
                    </Tooltip>
                  </ActionButton>
                  <ActionButton
                    color="secondary"
                    onClick={() =>
                      dispatch(
                        push({
                          pathname: '/client-portal/users/transactions',
                          search: `?userName=${item.userName}`,
                        })
                      )
                    }
                  >
                    <Tooltip title="User Details" arrow>
                      <ExitToAppOutlinedIcon fontSize="small" />
                    </Tooltip>
                  </ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </UseTable>
      </Paper>
    </div>
  );
  return (
    <>
      <>
        <Loader isLoading={isUsersLoading} />
        {usersData ? renderUserChanges() : <></>}
        <Popup
          title="Edit User Details"
          openPopup={isEditModalOpen}
          open={isEditModalOpen}
          closeFn={() => Actions.toggleEditModal(dispatch, false)}
        >
          {isUsersLoading ? (
            <Loader isLoading={isUsersLoading} />
          ) : (
            <div style={{ width: 500 }}>
              <Grid container>
                <ValidationError validationErrorData={updateProfileError} />
                <Box m={3} bgcolor="background.paper" />
                <Grid item xs={12}>
                  <div
                    className="DivAddEmail MuiFormControl-root MuiTextField-root"
                    style={{ width: '100%' }}
                  >
                    <Controls.Input
                      name="firstName"
                      label="First Name"
                      value={userDetails.firstName}
                      onChange={handleChange}
                      fullWidth
                    />
                    <Controls.Input
                      name="lastName"
                      label="Last Name"
                      value={userDetails.lastName}
                      onChange={handleChange}
                      fullWidth
                    />
                    <Controls.Input
                      name="accountExecutive"
                      label="Account Executive"
                      value={userDetails.accountExecutive}
                      onChange={handleChange}
                      fullWidth
                    />
                    <Controls.Input
                      name="companyName"
                      label="Company name"
                      value={userDetails.companyName}
                      onChange={handleChange}
                      fullWidth
                    />
                    <Controls.Input
                      name="agencyId"
                      label="Customer ID"
                      value={userDetails.agencyId}
                      onChange={handleChange}
                      fullWidth
                    />
                    <Box m={3} bgcolor="background.paper" />
                    <Controls.Button
                      type="submit"
                      text="Update User"
                      disabled={!userDetails.firstName && !userDetails.lastName}
                      onClick={handleUpdateProfile}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </Popup>
      </>
    </>
  );
};

export default Users;
