import { Dispatch } from 'redux';
import * as types from '../actionTypes/TransactionsActionTypes';
import { TransactionHistoryEndPoints } from '../../constants/apiConstants';
import { getErrorMessages } from '../../shared/utils/errorHandler';
import { showToastMessage } from './common/ToastsActionCreators';
import { makeOrGetTableFiltersByKey } from '../selectors/common/UseTableSelectors';
import moment from 'moment';
import { formatDataObject } from '../../shared/helpers';
import makeApiCall from '../../shared/utils/makeApiCall';
import { handleUserAuthorization } from './AuthActionCreators';

/** Throw Error Toast */
export const displayErrorToast = (dispatch: Dispatch<any>, errorMsg?: string) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message: errorMsg || `Error while Downloading the transaction.`,
    type: 'error',
  });
};

/** Throw Success Toast */
export const displaySuccessToast = (dispatch: Dispatch<any>) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message: `Downloaded Successfully.`,
    type: 'success',
  });
};

const handleTransactionsLoadingRequest = (dispatch: Dispatch<any>) => {
  dispatch(transactionsRequestLoading());
};

const handleTransactionsRequestFailure = (dispatch: Dispatch<any>) => {
  dispatch(transactionsRequestFailure());
};

export const transactionsRequestLoading = (): types.ITransactionsRequestLoading => ({
  type: types.TRANSACTIONS_REQUEST_LOADING,
});

export const transactionsRequestFailure = (): types.ITransactionsRequestFailure => ({
  type: types.TRANSACTIONS_REQUEST_FAILURE,
});

export const loadTransactionsSuccess = (data: any): types.ILoadTransactionsSuccess => ({
  type: types.LOAD_TRANSACTIONS_SUCCESS,
  data,
});
export const exportTransactionsSuccess = (): types.IExportTransactionsSuccess => ({
  type: types.EXPORT_TRANSACTIONS_SUCCESS,
});

const handleLoadTransactionsResponse = (dispatch: Dispatch<any>, response: any) => {
  dispatch(loadTransactionsSuccess(response));
};
const handleDownloadResponse = (dispatch: Dispatch<any>) => {
  dispatch(exportTransactionsSuccess());
};

export const loadTransactions =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => { useTableReducer: { tableFilters: any } }
  ) => {
    const getPageFilters = makeOrGetTableFiltersByKey();
    const pageFilters = getPageFilters(getState(), 'transactions');
    const reportStartDate = pageFilters.endDate
      ? `${moment(pageFilters.startDate).format('L')}`
      : '';
    const reportEndDate = pageFilters.endDate
      ? `${moment(pageFilters.endDate).add(1, 'days').format('L')}`
      : '';
    const columnFilters = (pageFilters && pageFilters.columnFilterDetails) || {};
    const isValid = (columnSearchValue: moment.MomentInput) =>
      moment(columnSearchValue, 'M/D/YYYY', true).isValid();
    const transactionAmt =
      columnFilters.paymentTransactionAmount &&
      parseFloat(columnFilters.paymentTransactionAmount.replace(/\$|,/g, ''));
    const removeErrorColumnFilters = {
      ...columnFilters,
      transactionDateTime: isValid(columnFilters.transactionDateTime)
        ? columnFilters.transactionDateTime
        : '',
      paymentDate: isValid(columnFilters.paymentDate) ? columnFilters.paymentDate : '',
      paymentTransactionAmount: transactionAmt || '',
    };
    const filteredColumnFilters =
      removeErrorColumnFilters &&
      Object.entries(removeErrorColumnFilters).reduce(
        // @ts-ignore
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      );
    const formattedColumnFilters =
      filteredColumnFilters &&
      Object.entries(filteredColumnFilters).map(([key, value]) => ({
        columnName: key,
        columnValue: value,
      }));
    const additionalParams = {
      columnFilterDetails: formattedColumnFilters || [],
      startDate: reportStartDate,
      endDate: reportEndDate,
      userName: '',
      paymentStatus: pageFilters.paymentStatus || '',
      paymentType: pageFilters.paymentType || '',
    };
    const data = formatDataObject(pageFilters, additionalParams);
    handleTransactionsLoadingRequest(dispatch);
    makeApiCall.postData(`${TransactionHistoryEndPoints.getTransactionHistory}`, null, data, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      // @ts-ignore
      .then(async (response) => {
        if (response.data.status === 200) {
          handleLoadTransactionsResponse(dispatch, response.data.data);
        } else if (response.data.status === 400) {
          const errorMsg = response.data.error || 'Error while fetching transactions';
          handleTransactionsRequestFailure(dispatch);
          displayErrorToast(dispatch, errorMsg);
        } else {
          // Else case handled here
          handleTransactionsRequestFailure(dispatch);
          displayErrorToast(dispatch, 'Error while fetching transactions');
        }
      })
      .catch(async (error) => {
        const errorMsg = getErrorMessages(error, 'Error while fetching transactions');
        displayErrorToast(dispatch, errorMsg);
        handleTransactionsRequestFailure(dispatch);
        throw error;
      });
  };

export const downloadTransactions =
  () =>
  async (
    dispatch: Dispatch<any>,
    getState: () => { useTableReducer: { tableFilters: any } }
  ) => {
    handleTransactionsLoadingRequest(dispatch);
    const getPageFilters = makeOrGetTableFiltersByKey();
    const pageFilters = getPageFilters(getState(), 'transactions');
    const reportStartDate = pageFilters.endDate
      ? `${moment(pageFilters.startDate).format('L')} 12:00:00 AM`
      : '';
    const reportEndDate = pageFilters.endDate
      ? `${moment(pageFilters.endDate).format('L')} 11:59:00 PM`
      : '';
    const columnFilters = pageFilters.columnFilterDetails;
    const filteredColumnFilters =
      columnFilters &&
      Object.entries(columnFilters).reduce(
        // @ts-ignore
        (a, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      );
    const formattedColumnFilters =
      filteredColumnFilters &&
      Object.entries(filteredColumnFilters).map(([key, value]) => ({
        columnName: key,
        columnValue: value,
      }));
    const data = {
      start: pageFilters.page * 10,
      length: 10,
      sortColumn: pageFilters.orderBy,
      sortDirection: pageFilters.order,
      search: {
        value: pageFilters.search === undefined ? '' : pageFilters.search,
        regex: '',
      },
      columnFilterDetails: formattedColumnFilters || [],
      startDate: reportStartDate,
      endDate: reportEndDate,
    };

    makeApiCall.postData(`${TransactionHistoryEndPoints.downloadTransaction}`, null, data, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      // @ts-ignore
      .then(async (response) => {
        if (response.headers['content-disposition']) {
          const fileName = response.headers['content-disposition']
            .split('filename=')[1]
            .split(';')[0];
          // @ts-ignore
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            // IE variant
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(
              new Blob([response.data], { type: 'application/pdf' }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: 'application/pdf' })
            );
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', fileName.replace(/["]/g, ''));
            document.body.appendChild(link);
            link.click();
          }
          handleDownloadResponse(dispatch);
          displaySuccessToast(dispatch);
        } else {
          handleTransactionsRequestFailure(dispatch);
          displayErrorToast(dispatch);
        }
      })
      .catch(async (error) => {
        const errorMsg = getErrorMessages(error, 'Error while downloading transactions');
        displayErrorToast(dispatch, errorMsg);
        handleTransactionsRequestFailure(dispatch);
        throw error;
      });
  };
