import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { useTableReducer } from './redux/reducers/common/UseTableReducer';
import { toastsReducer } from './redux/reducers/common/ToastsReducer';
import { globalNavReducer } from './redux/reducers/GlobalNavReducer';
import { reportingReducer } from './redux/reducers/client-portal/ReportingReducer';
import { transactionsReducer } from './redux/reducers/client-portal/TransactionsReducer';
import { usersReducer } from './redux/reducers/client-portal/UsersReducer';
import { adportalReducer } from './redux/reducers/client-portal/AdportalReducer';

import { IUseTableInitialState } from './redux/actionTypes/common/UseTableActionTypes';
import { IToastInitialState } from './redux/actionTypes/common/ToastsActionTypes';
import { IGlobalNavInitialState } from './redux/actionTypes/GlobalNavActionTypes';
import { IReportingInitialState } from './redux/actionTypes/ReportingActionTypes';
import { ITransactionsInitialState } from './redux/actionTypes/TransactionsActionTypes';
import { IUsersInitialState } from './redux/actionTypes/UsersActionTypes';
import {IAdportalInitialState  } from './redux/actionTypes/AdportalActionTypes';

export interface initialState {
  // Initial State To be Defined Here
  useTableReducer: IUseTableInitialState;
  toastsReducer: IToastInitialState;
  globalNavReducer: IGlobalNavInitialState;
  reportingReducer: IReportingInitialState;
  transactionsReducer: ITransactionsInitialState;
  usersReducer: IUsersInitialState;
  adportalReducer:IAdportalInitialState;
  router: any;
}

export const rootReducer = (history: any) =>
  combineReducers({
    useTableReducer,
    toastsReducer,
    globalNavReducer,
    reportingReducer,
    transactionsReducer,
    usersReducer,
    adportalReducer,
    router: connectRouter(history),
  });
