import { makeStyles } from '@material-ui/core/styles';

const useClientPortalClasses = makeStyles((theme) => ({
  accountChangesPageHeaderOptions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    position: 'relative',
    top: '-1rem',
  },
  accountChangesPageHeaderOptionsCol2: {
    paddingRight: '1.2rem',
    [`@media only screen and (min-width: 1563px)`]: {
      paddingRight: '.6rem',
    },
  },
  spacerDiv: {
    padding: '10px 0px',
  },
  configContent: {
    maxWidth: '550px',
    border: `1px solid ${theme.palette.grey.light}`,
    borderRadius: '12px',
  },
  collapseOutput: {
    width: '70%',
  },
  statusDropdown: {
    minWidth: '220px',
    maxWidth: '900px',
  },
  datePickerDropdown: {
    minWidth: '220px',
    maxWidth: '350px',
  },
  filterByDateTransacions: {
    bottom: '11px',
    position: 'relative',
  },
  filterByDateTransacLabel: {
    bottom: '25px',
    position: 'relative',
  },
  backToUsersBtn: {
    marginTop: '10px',
  },
  disableRecurringPaymentBtn: {
    marginLeft: '10px',
    backgroundColor: `${theme.palette.error.main}`,
  },
  cancelRecurringPaymentBtn: {
    marginLeft: '10px',
    backgroundColor: `${theme.palette.grey.dark}`,
  },
  recurringPaymentFooter: {
    textAlign: 'center',
  },
  schedulePaymentDetailsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  schedulePaymentDetailsInvSection: {
    display: 'flex',
    width: '200px',
  },
  schedulePaymentDetailsAmtSection: {
    marginRight: '0px',
  },
  notAvailableDiv: {
    display: 'flex',
    alignItems: 'center',
    height: '200px',
    color: `${theme.palette.primary.main}`,
    fontSize: '18px',
  },
  userInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '30px',
  },
  marginRight: {
    marginRight: '10px',
  },
  divider: {
    borderBottom: `1px solid ${theme.palette.grey.slate}`,
    marginBottom: '20px',
  },
  emptyRecurringPaymentDiv: {
    padding: '20px 0px !important',
    color: theme.palette.warning.main,
    fontSize: '16px !important',
  },
}));

export default useClientPortalClasses;
