import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const NoAccess: React.FC = () => (
  <div>
    <Grid
      container
      spacing={0}
      alignItems="center"
      justify="center"
      style={{ minHeight: '85vh' }}
    >
      <Grid item>
        <Typography variant="h2">You are not authorized to view this content.</Typography>
      </Grid>
    </Grid>
  </div>
);

export default NoAccess;
