// eslint-disable import/prefer-default-export

export interface IClientPortalLinks {
  parentLinkName: string;
  linkName: string;
  primaryRoute: string;
  subRoute: string;
  icon: string;
}

/** Routes Config for Client Portal Pages */
export const ClientPortalLinks: IClientPortalLinks[] = [
  {
    parentLinkName: 'Client Portal',
    linkName: 'Reporting',
    primaryRoute: '/client-portal/',
    subRoute: '/client-portal/reporting',
    icon: 'ArchiveIcon',
  },
  {
    parentLinkName: 'Client Portal',
    linkName: 'Client Portal Transactions',
    primaryRoute: '/client-portal/',
    subRoute: '/client-portal/transactions',
    icon: 'AccountBalanceIcon',
  },
  {
    parentLinkName: 'Client Portal',
    linkName: 'Users',
    primaryRoute: '/client-portal/',
    subRoute: '/client-portal/users',
    icon: 'PeopleIcon',
  },
  {
    parentLinkName: 'Client Portal',
    linkName: 'Adportal Transactions',
    primaryRoute: '/client-portal/',
    subRoute: '/client-portal/users/adportal/transactions',
    icon: 'MonetizationOnIcon',
  },
];
