import React from 'react';
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
  SelectProps,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

interface ISelect extends SelectProps {
  options?: any;
  disableNone?: boolean;
  customRoot?: any;
  removeNone?: boolean;
  customFormControl?: any;
}

const Select: React.FC<ISelect> = (props) => {
  const {
    name,
    label,
    value,
    error = null,
    onChange,
    options,
    defaultValue,
    disabled = false,
    disableNone = false,
    customRoot,
    customFormControl,
    removeNone = false,
  } = props;

  const SelectCustom = withStyles(() => ({
    root: customRoot,
  }))(MuiSelect);

  const FormControlCustom = withStyles(() => ({
    root: customFormControl,
  }))(FormControl);

  return (
    <FormControlCustom variant="outlined" error={!!error}>
      <InputLabel>{label}</InputLabel>
      <SelectCustom
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        defaultValue={defaultValue}
      >
        {removeNone ? null : (
          <MenuItem disabled={disableNone} value="">
            None
          </MenuItem>
        )}
        {options &&
          options.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.title || item.description}
            </MenuItem>
          ))}
      </SelectCustom>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControlCustom>
  );
};

export default Select;
