/* eslint-disable */
import moment from 'moment';
import queryString from 'query-string';

/** returns file name from the given file path */
export const getFileName = (filePath: string) => {
  const test = filePath.split('\\');
  return test[test.length - 1];
};

/** truncate cells for longer names */
export const getTruncatedCell = (value: string, charLength: number) => {
  return value ? value.substring(0, charLength) : value;
};

/** Email Validation Regex */
export const emailRegex = new RegExp(
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+(?:[A-Za-z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)*/
);

/** Format Date to MM/DD/YYYY with time */
export const formatDateMMDDYYYY = (date: moment.MomentInput) => {
  return !!date && moment(date).format('MM/DD/YYYY h:mm a');
};

/** Format Date to MM/DD/YYYY */
export const formatDate = (date: moment.MomentInput) => {
  return !!date && moment(date).format('MM/DD/YYYY');
};

/** Format number to currency representation */
export const formatCurrency = (amount: any) => {
  return `$${amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

/** Get Table Filters Data object to get filtered response */
export const formatDataObject = (
  pageFilters: { page: any; orderBy: any; order: any; search: string },
  extraParams: {
    columnFilterDetails?: any;
    startDate: string;
    endDate: string;
    userName: any;
    paymentStatus?: any;
    paymentType?: any;
  },
  isUsersPage?: boolean
) => {
  const parsed: any = queryString.parse(window.location.search);
  const isUrlParamsAvailable = Object.keys(parsed).length !== 0;
  const data = {
    start: isUrlParamsAvailable ? parseInt(parsed.page, 10) * 10 : pageFilters.page * 10,
    length: 10,
    sortColumn:
      isUrlParamsAvailable && !isUsersPage ? parsed.orderBy : pageFilters.orderBy,
    sortDirection: isUrlParamsAvailable ? parsed.order : pageFilters.order,
    search: {
      value: isUrlParamsAvailable
        ? parsed.searchInput && parsed.searchInput.trim()
        : pageFilters.search && pageFilters.search.trim(),
      regex: '',
    },
    ...extraParams,
  };
  return data;
};
