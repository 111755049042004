/* eslint-disable no-case-declarations */
/* eslint-disable arrow-body-style */
import * as types from '../../actionTypes/UsersActionTypes';

const usersInitialState: types.IUsersInitialState = {
  usersData: [],
  totalRecords: 0,
  isUsersLoading: false,
  usersTransactionsData: [],
  totalRecordsTransactions: 0,
  isUsersTransactionsLoading: false,
  recurringPaymentData: [],
  selectedRecurringPayment: {},
  isPaymentsLoading: false,
  paymentStatuses: [],
  paymentTypes: [],
  isScheduledPaymentModalOpen: false,
  isRecurringPaymentModalOpen: false,
  isSendPaymentModalOpen: false,
  sendPaymentError: '',
  recurringPaymentError: '',
  scheduledPaymentError: '',
  updateProfileError: '',
  isEditModalOpen: false,
  recurringPaymentTypesData: [],
};

const usersReducer = (state = usersInitialState, action: types.IUsersActions) => {
  switch (action.type) {
    case types.USERS_REQUEST_LOADING:
      return {
        ...state,
        isUsersLoading: true,
        usersData: [],
        totalRecords: 0,
      };
    case types.USERS_REQUEST_FAILURE:
      return {
        ...state,
        isUsersLoading: false,
      };
    case types.LOAD_USERS_SUCCESS:
      return {
        ...state,
        usersData: action.data.data,
        totalRecords: action.data.totalRecords,
        isUsersLoading: false,
      };
    case types.LOAD_USERS_TRANSACTION_HISTORY_SUCCESS:
      return {
        ...state,
        usersTransactionsData: action.data.data,
        totalRecordsTransactions: action.data.totalRecords,
        isUsersTransactionsLoading: false,
      };
    case types.USERS_TRANSACTIONS_REQUEST_FAILURE:
      return {
        ...state,
        isUsersTransactionsLoading: false,
      };
    case types.USERS_TRANSACTIONS_REQUEST_LOADING:
      return {
        ...state,
        usersTransactionsData: [],
        totalRecordsTransactions: 0,
        isUsersTransactionsLoading: true,
      };
    case types.USERS_PAYMENTS_REQUEST_LOADING:
      return {
        ...state,
        isPaymentsLoading: true,
      };
    case types.SEND_CONFIRMATION_REQUEST_SUCCESS:
      return {
        ...state,
        isPaymentsLoading: false,
      };
    case types.GET_RECURRING_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        isPaymentsLoading: false,
        recurringPaymentData: action.data,
      };
    case types.GET_RECURRING_PAYMENT_REQUEST_FAILURE:
      return {
        ...state,
        isPaymentsLoading: false,
        recurringPaymentData: [],
      };
    case types.UPDATE_RECURRING_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        isPaymentsLoading: false,
        selectedRecurringPayment: action.data,
        recurringPaymentError: '',
      };
    case types.UPDATE_RECURRING_PAYMENT_REQUEST_FAILURE:
      return {
        ...state,
        isPaymentsLoading: false,
        recurringPaymentError: action.data,
      };
    case types.GET_PAYMENT_STATUS_REQUEST_SUCCESS:
      const paymentStatusesResponse = action.data || [];
      return {
        ...state,
        isPaymentsLoading: false,
        paymentStatuses: paymentStatusesResponse.map((status) => {
          return {
            id: status.statusCode,
            value: status.statusCode,
            title: status.description,
            description: status.description,
          };
        }),
      };
    case types.GET_PAYMENT_TYPES_REQUEST_SUCCESS:
      const paymentTypesResponse = action.data || [];
      return {
        ...state,
        isPaymentsLoading: false,
        paymentTypes: paymentTypesResponse.map((status) => {
          return {
            id: status.description,
            value: status.description,
            title: status.description,
            description: status.description,
          };
        }),
      };
    case types.TOGGLE_SEND_PAYMENT_RECEIPT_MODAL:
      return {
        ...state,
        isSendPaymentModalOpen: action.data,
        sendPaymentError: action.data ? '' : state.sendPaymentError,
      };
    case types.TOGGLE_UPDATE_RECURRING_PAYMENT_MODAL:
      return {
        ...state,
        isRecurringPaymentModalOpen: action.data,
        recurringPaymentError: action.data ? '' : state.recurringPaymentError,
      };
    case types.TOGGLE_UPDATE_SCHEDULED_PAYMENT_MODAL:
      return {
        ...state,
        isScheduledPaymentModalOpen: action.data,
        scheduledPaymentError: action.data ? '' : state.scheduledPaymentError,
      };
    case types.SEND_CONFIRMATION_REQUEST_FAILURE:
      return {
        ...state,
        sendPaymentError: action.data,
        isPaymentsLoading: false,
      };
    case types.UPDATE_SCHEDULED_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        isPaymentsLoading: false,
      };
    case types.UPDATE_SCHEDULED_PAYMENT_REQUEST_FAILURE:
      return {
        ...state,
        isPaymentsLoading: false,
        scheduledPaymentError: action.data,
      };
    case types.DISABLE_RECURRING_PAYMENT_REQUEST_FAILURE:
      return {
        ...state,
        isPaymentsLoading: false,
      };
    case types.DISABLE_RECURRING_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        isPaymentsLoading: false,
      };
    case types.UPDATE_USER_REQUEST_SUCCESS:
      return {
        ...state,
        isUsersLoading: false,
      };
    case types.UPDATE_USER_REQUEST_FAILURE:
      return {
        ...state,
        isUsersLoading: false,
        updateProfileError: action.data,
      };
    case types.TOGGLE_EDIT_MODAL:
      return {
        ...state,
        isEditModalOpen: action.data,
        updateProfileError: action.data ? '' : state.updateProfileError,
      };
    case types.GET_RECURRING_PAYMENT_TYPES_REQUEST_SUCCESS: {
      return {
        ...state,
        recurringPaymentTypesData: action.data.map((item) => ({
          ...item,
          value: item.status,
          id: item.status,
        })),
      };
    }
    case types.GET_RECURRING_PAYMENT_TYPES_REQUEST_FAILURE: {
      return {
        ...state,
        isPaymentsLoading: false,
      };
    }
    case types.DISABLE_SCHEDULED_PAYMENT_REQUEST_FAILURE: {
      return {
        ...state,
        isPaymentsLoading: false,
      };
    }
    case types.DISABLE_SCHEDULED_PAYMENT_REQUEST_SUCCESS:
      return {
        ...state,
        isPaymentsLoading: false,
      };
    default:
      return state;
  }
};

export { usersInitialState, usersReducer };
