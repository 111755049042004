import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { initialState } from '../../../../rootReducer';
import PageHeader from '../../../../shared/components/PageHeader';
import * as Actions from '../../../../redux/actionCreators/UsersActionCreators';
import UseTable from '../../../../shared/controls/UseTable';
import ConfirmDialog from '../../../../shared/controls/ConfirmDialog';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import { formatDate, formatCurrency } from '../../../../shared/helpers';
import Loader from '../../../../shared/components/Loader';

const MultipleRecurringPayments: React.FC = () => {
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const dispatch = useDispatch();
  const { isPaymentsLoading, multipleRecurringPaymentsData } = useSelector(
    (state: initialState) => ({
      isPaymentsLoading:
        state && state.usersReducer && state.usersReducer.isPaymentsLoading,
      multipleRecurringPaymentsData:
        state && state.usersReducer && state.usersReducer.recurringPaymentData,
    })
  );
  const classes = { ...clientPortalClasses, ...commonClasses };
  const headCells = [
    { id: 'nextChargeDate', label: 'Next Charge Date', disableSorting: true },
    { id: 'orderNumber', label: 'Order Number', disableSorting: true },
    { id: 'amount', label: 'Amount', disableSorting: true },
    { id: 'dayOfMonth', label: 'Day of Month', disableSorting: true },
    { id: 'dayOfWeek', label: 'Day of Week', disableSorting: true },
    { id: 'paymentStatus', label: 'Frequency', disableSorting: true },
    { id: 'paymentCard', label: 'Payment Card', disableSorting: true },
    { id: 'startDate', label: 'Start Date', disableSorting: true },
    { id: 'endDate', label: 'End Date', disableSorting: true },
    { id: 'actions', label: 'Actions', disableSorting: true },
  ];
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });

  const handleCancelRecurringPayment = (id: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    const isLastEntry =
      multipleRecurringPaymentsData && multipleRecurringPaymentsData.length === 1;
    dispatch(Actions.disableRecurringPayment(id, isLastEntry));
  };

  const renderMultipleRecurringPayments = () => (
    <div className={`${classes.global}`}>
      <Paper className={classes.pageContent} elevation={0}>
        <PageHeader title="Scheduled Recurring Payments" />
        <UseTable
          headCells={headCells}
          pageKey="recurringPayments"
          hidePagination
          totalRecords={20}
        >
          <TableBody>
            {multipleRecurringPaymentsData && multipleRecurringPaymentsData.length > 0 ? (
              multipleRecurringPaymentsData.map((item, index) => (
                <>
                  <TableRow key={`${item.recurringPaymentId}_${index}`}>
                    <TableCell>{formatDate(item.nextChargeDate)}</TableCell>
                    <TableCell>{item.orderNumber}</TableCell>
                    <TableCell>{item.amount && formatCurrency(item.amount)}</TableCell>
                    <TableCell>{item.dayOfMonth || 'N/A'}</TableCell>
                    <TableCell>{item.dayOfWeek || 'N/A'}</TableCell>
                    <TableCell>
                      {item?.paymentStatus &&
                        item?.paymentStatus
                          ?.match(/([A-Z]?[^A-Z]*)/g)
                          ?.slice(0, -1)
                          .join(' ')}
                    </TableCell>
                    <TableCell>{item.paymentCard}</TableCell>
                    <TableCell>{formatDate(item.startDate)}</TableCell>
                    <TableCell>{item.endDate && formatDate(item.endDate)}</TableCell>
                    <TableCell>
                      <Tooltip title="Cancel Recurring Payment">
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: `Are you sure, you want to cancel recurring payment for order number ${
                                item.orderNumber
                              } and with amount ${formatCurrency(item.amount)}?`,
                              // @ts-ignore
                              onConfirm: () => {
                                handleCancelRecurringPayment(item.recurringPaymentId);
                              },
                            });
                          }}
                        >
                          <CancelIcon style={{ fill: '#FF0000' }} />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={10}
                  align="center"
                  className={classes.emptyRecurringPaymentDiv}
                >
                  This user does not have any recurring payments scheduled.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </UseTable>
      </Paper>
    </div>
  );

  return (
    <>
      <div>
        {isPaymentsLoading ? (
          <Loader isLoading={isPaymentsLoading} />
        ) : (
          renderMultipleRecurringPayments()
        )}
      </div>
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  );
};

export default MultipleRecurringPayments;
