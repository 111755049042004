import { cognitoGroup, constants } from '../constants/authHandlerConstants';
import { AuthenticationEndPoints } from '../constants/apiConstants';

export interface Token {
  exp: number;
  [key: string]: any;
}

export interface User {
  firstName: string;
  lastName: string;
}

export const saveToken = (data: any) => {
  if(data.access_token) {
    window.sessionStorage.setItem(constants.ACCESS_TOKEN, data.access_token);
  }
  if(data.refresh_token) {
    window.sessionStorage.setItem(constants.REFRESH_TOKEN, data.refresh_token);
  }
  if(data.id_token) {
    window.sessionStorage.setItem(constants.ID_TOKEN, data.id_token);
  }
};

export const getToken = (tokenType: string): string => {
  if (tokenType === constants.ACCESS_TOKEN) {
    return window.sessionStorage.getItem(constants.ACCESS_TOKEN) || '';
  }
  if (tokenType === constants.REFRESH_TOKEN) {
    return window.sessionStorage.getItem(constants.REFRESH_TOKEN) || '';
  }
  if (tokenType === constants.ID_TOKEN) {
    return window.sessionStorage.getItem(constants.ID_TOKEN) || '';
  }
  throw new Error(`Invalid token type: ${tokenType}`);
};


export const decodeToken = (token: string): Token => {
  return JSON.parse(atob(token.split('.')[1]));
};

export const getUserInformation=():User |null=> {
  const idToken=getToken(constants.ID_TOKEN);
  if(idToken) {
    const decodedIdToken = decodeToken(idToken);
    if (decodedIdToken) {
      return {
        firstName: decodedIdToken.name,
        lastName: decodedIdToken.family_name
      }
    }
  }
  return null;
}


export const isTokenExpired = (token: Token): boolean => {
  // Check if the token is expired
  const timeDifference = token.exp - Math.floor(Date.now() / 1000);
  const minutesDifference = Math.floor(timeDifference / 60);
  return minutesDifference > 2;
};

export const redirectToAuthPage = (): void => {
  const encodedRedirectURL=encodeURIComponent(AuthenticationEndPoints.RedirectURI);
  const authenticationURL=`${AuthenticationEndPoints.CognitoDomainName}/oauth2/authorize?client_id=${AuthenticationEndPoints.ClientId}
  &response_type=code&scope=openid&redirect_uri=${encodedRedirectURL}`;
  window.location.href = authenticationURL;
};

export const getCodeFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('code');
};

export const isUserAuthenticated=():boolean=> {
  const accessToken=getToken(constants.ACCESS_TOKEN);
  if(accessToken) {
    const decodedTokenAccessToken = decodeToken(accessToken);
    if (getToken(constants.ACCESS_TOKEN) && isTokenExpired(decodedTokenAccessToken) &&
      decodedTokenAccessToken['cognito:groups'].includes(cognitoGroup.GROUP)) {
      return true;
    }
  }
  return false
}



