// @ts-ignore
import { InternalTheme } from '@spectrum-reach/mui-theme';
import green from '@material-ui/core/colors/green';

export const getStatusStyles = (status: string) => {
  if (status === 'Payment Received' || status === 'Received') {
    return green[400];
  } 
  if (status === 'Payment Failed' || status === 'Failed') {
    return InternalTheme.palette.error.dark;
  } if (status === 'Processed') {
    return InternalTheme.palette.primary.dark;
  }
  return InternalTheme.palette.grey.dark;
};
