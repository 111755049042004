// @ts-nocheck
import moment from 'moment';
import { Dispatch } from 'redux';
import * as types from '../actionTypes/ReportingActionTypes';
import { ReportingEndPoints } from '../../constants/apiConstants';
import { getErrorMessages } from '../../shared/utils/errorHandler';
import { showToastMessage } from './common/ToastsActionCreators';
import { handleUserAuthorization } from './AuthActionCreators';
import makeApiCall from '../../shared/utils/makeApiCall';

/** Throw Error Toast */
export const displayErrorToast = (dispatch: Dispatch<any>, message: string) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message: message || 'Report Export Failed',
    type: 'error',
  });
};

/** Throw Success Toast */
export const displaySuccessToast = (dispatch: Dispatch<any>) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message: 'Report Export Successful',
    type: 'success',
  });
};

const handleDownloadLoadingRequest = (dispatch: Dispatch<any>) => {
  dispatch(downloadPaymentTransLoading());
};

const handleDownloadRequestFailure = (dispatch: Dispatch<any>) => {
  dispatch(downloadPaymentTransFailure());
};

const handleDownloadPaymentTransResponse = (dispatch: Dispatch<any>) => {
  dispatch(downloadPaymentTransSuccess());
};

export const downloadPaymentTransLoading = (): types.IDownloadReportingRequest => ({
  type: types.DOWNLOAD_REPORTING_LOADING,
});

export const downloadPaymentTransFailure = (): types.IDownloadReportingFailure => ({
  type: types.DOWNLOAD_REPORTING_FAILURE,
});

export const downloadPaymentTransSuccess = (): types.IDownloadReportingSuccess => ({
  type: types.DOWNLOAD_REPORTING_SUCCESS,
});

/** Download Payment Transaction Data */
export const downloadTransactionPayment =
  (
    startDate: moment.MomentInput,
    endDate: moment.MomentInput,
    reportName: string,
    fileType?: string | undefined
  ) =>
  async (dispatch: Dispatch<any>) => {
    let api;
    let apiEndPoint = '';
    handleDownloadLoadingRequest(dispatch);
    const reportStartDate = `${moment(startDate).format('L')}`;
    const reportEndDate = endDate && `${moment(endDate).add(1, 'days').format('L')}`;

    // eslint-disable-next-line default-case
    switch (reportName) {
      case 'transactions':
        apiEndPoint = ReportingEndPoints.downloadPymentTransaction;
        break;
      case 'registeredUsers':
        apiEndPoint = ReportingEndPoints.downloadRegisteredUsers;
        break;
      case 'recurringPayments':
        apiEndPoint = ReportingEndPoints.downloadRecurringPayments;
        break;
    }

    if (reportName === 'registeredUsers') {
      api = `${apiEndPoint}?startDate=${reportStartDate}&endDate=${reportEndDate}`;
    } else if (reportName === 'transactions') {
      api = `${apiEndPoint}?startDate=${reportStartDate}&endDate=${reportEndDate}&fileType=${fileType}`;
    } else {
      api = `${apiEndPoint}?startDate=${reportStartDate}`;
    }
    makeApiCall.postData(api, null, {  }, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      .then(async (response) => {
        if (response.status === 200 && typeof response.data === 'string') {
          const fileName =
            response.headers['content-disposition'] &&
            response.headers['content-disposition'].split('filename=')[1].split(';')[0];
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(
              // eslint-disable-next-line
              new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }),
              fileName
            );
          } else {
            const url = window.URL.createObjectURL(
              // eslint-disable-next-line
              new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              })
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName.replace(/["]/g, ''));
            document.body.appendChild(link);
            link.click();
          }
          handleDownloadPaymentTransResponse(dispatch);
          displaySuccessToast(dispatch);
        } else if (response.data.status === 400) {
          const errorMsg =
            response.data.error ||
            'Could not find any transactions to download in the given date range';
          handleDownloadRequestFailure(dispatch);
          displayErrorToast(dispatch, errorMsg);
        } else {
          handleDownloadRequestFailure(dispatch);
          displayErrorToast(
            dispatch,
            'Could not find any transactions to download in the given date range'
          );
        }
      })
      .catch(async (error) => {
        const errorMsg = getErrorMessages(
          error,
          'Could not find any transactions to download in the given date range'
        );
        displayErrorToast(dispatch, errorMsg);
        handleDownloadRequestFailure(dispatch);
        throw error;
      });
  };
