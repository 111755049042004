import { ClientPortalLinks } from './clientPortalConfig';

/** Get Route Config Based on Route Location */
export const getRouteConfig = (path: string) => {
  const pathName = path || window.location.pathname;
  let routeConfig;
  if (pathName.includes('/client-portal/')) {
    routeConfig = ClientPortalLinks;
  } else {
    routeConfig = null;
  }
  return routeConfig;
};
