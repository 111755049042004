import axios from 'axios';

export interface ApiResponse<T> {
  data: {
    data: T;
    error: string;
    status: number;
    validationMessage: string;
  };
}

// Request interceptor
axios.interceptors.request.use(
  (config) => config, // Do something before request is sent
  (error) => Promise.reject(error), // Do something with request error
);

export const handleError = (err: any) => {
  if (err.response) {
    // client received an error response (5xx, 4xx)
    return err.response;
  }
  if (err.request) {
    // client never received a response, or request never left
    return err.request;
  }
  // anything else
  return err;
};

// Response interceptor

// @response
// Any status code that lie within the range of 2xx cause this function to trigger
// Do something with response data
// @error
// Any status codes that falls outside the range of 2xx cause this function to trigger
// Do something with response error
axios.interceptors.response.use(
  (response) => response,
  (error) => handleError(error),
);

// HTTP Methods
export default {
  postData: (url: string, params: any = null, data: any = {}, headers = {}) => axios({
    method: 'POST',
    url,
    data,
    params,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  }).then((response) => response),

  getData: (url: string, params: any = null, headers = {}) => axios({
    method: 'GET',
    url,
    params,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
  }).then((response) => response),
};
