import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ListItem,
  List,
  ListItemIcon,
  Typography,
  Drawer,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getRouteConfig } from '../routeConfigs/helper';
import * as Actions from '../../redux/actionCreators/GlobalNavActionCreators';
import Icons from '../Home/Icons';
import { initialState } from '../../rootReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: 250,
  },
  drawerPaper: {
    width: 250,
  },
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  title: {
    color: theme.palette.text.primary,
  },
}));

/** Side Navigation Bar Component */
const SideBar: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen, location } = useSelector((state: initialState) => ({
    isOpen: state.globalNavReducer.isOpen,
    location: state.router.location,
  }));
  const routeConfiguration = getRouteConfig(location && location.pathname);
  const subNavTitle =
    routeConfiguration && routeConfiguration[0] && routeConfiguration[0].parentLinkName;

  return (
    <div className={classes.root}>
      <Drawer
        open={isOpen}
        onClose={() => {
          Actions.toggleGlobalNavDrawerAction(dispatch, false);
        }}
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
      >
        <List>
          <ListItem button={false} key="title">
            <Typography variant="h6" className={classes.title}>
              {subNavTitle}
            </Typography>
          </ListItem>

          {routeConfiguration &&
            routeConfiguration.map(({ linkName, subRoute, icon }) => (
              <ListItem
                button
                key={linkName}
                onClick={() => {
                  history.push(subRoute);
                }}
              >
                <ListItemIcon>
                  <Icons icon={icon} />
                </ListItemIcon>
                <ListItemText>{linkName}</ListItemText>
              </ListItem>
            ))}
        </List>
      </Drawer>
    </div>
  );
};

export default SideBar;
