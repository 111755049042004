import { withStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
// @ts-ignore
import { InternalTheme } from '@spectrum-reach/mui-theme';

const invoiceAutomationHeader = withStyles(() => ({
  root: {
    width: '210px',
    marginTop: '0px',
    top: '-10.5px',

    '& .MuiInputBase-root.Mui-error': {
      border: '1px solid red',
      '& .MuiInputBase-input': {
        color: 'red',
      },
    },

    '& .MuiInput-root': {
      position: 'relative',
      border: '1px solid #ccc',
      padding: '.5rem',
      borderRadius: '3px',
      height: '2.75rem',
    },
    '& label + .MuiInput-formControl': {
      marginTop: '0px',
    },
    '& .MuiInputBase-input': {
      color: '#88888a',
    },
    '& .MuiFormLabel-root': {
      lineHeight: '.3',
      paddingLeft: '.35rem',
      paddingBottom: '.35rem',
      backgroundColor: 'white',
      position: 'relative',
      top: '7px',
      left: '8px',
      zIndex: 1,
      width: '190px',
    },
    '& .MuiPickersDay-daySelected': {
      backgroundColor: InternalTheme.palette.primary.main,
    },
    '& .MuiInput-underline': {
      bottomBorder: 'none',
    },
    '& .MuiInput-underline:after': {
      bottomBorder: 'none',
    },
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '@media only screen and (max-width: 600px)': {
      width: 'auto',
      maxWidth: '85vw',
    },
  },
}))(DatePicker);

export const InvoiceAutomationHeader = invoiceAutomationHeader;
