/** interface reporting */
export interface IReportingInitialState {
  isDownloadReportLoading: boolean;
}

export const DOWNLOAD_REPORTING_LOADING = 'DOWNLOAD_REPORTING_LOADING';
export const DOWNLOAD_REPORTING_FAILURE = 'DOWNLOAD_REPORTING_FAILURE';
export const DOWNLOAD_REPORTING_SUCCESS = 'DOWNLOAD_REPORTING_SUCCESS';

export interface IDownloadReportingRequest {
  type: 'DOWNLOAD_REPORTING_LOADING';
}
export interface IDownloadReportingSuccess {
  type: 'DOWNLOAD_REPORTING_SUCCESS';
}
export interface IDownloadReportingFailure {
  type: 'DOWNLOAD_REPORTING_FAILURE';
}

/** Actions type */
export type IReportingActions =
  | IDownloadReportingRequest
  | IDownloadReportingSuccess
  | IDownloadReportingFailure;
