/** Login Action */
import axios from 'axios';
import queryString from 'query-string';
import { Dispatch } from 'redux';
import { constants } from "../../constants/authHandlerConstants";
import { push} from 'connected-react-router';
import { AuthenticationEndPoints } from "../../constants/apiConstants";
import {
  saveToken,
  redirectToAuthPage,
  getToken,
  isUserAuthenticated,
} from '../../auth/AuthUtils';


const header={
    'Content-Type': 'application/x-www-form-urlencoded',
  };
const url=`${AuthenticationEndPoints.CognitoDomainName}/oauth2/token`;
export const exchangeCodeForToken =(codeValue:string|null)=> async (dispatch: Dispatch<any>) => {
  const data = {
    grant_type: "authorization_code",
    code: codeValue,
    redirect_uri: AuthenticationEndPoints.RedirectURI,
    client_id:AuthenticationEndPoints.ClientId,
  };
  const formData = queryString.stringify(data);
  await axios
    .post(url, formData, {
      headers: header,
    })
    .then(async (response) => {
      if (response.status === 200) {
        saveToken(response.data);
        dispatch(
          push({
            pathname: '/client-portal/home',
          })
        );
      }
      else{
        handleError();
      }
    })
    .catch(async (error) => {
      handleError();
      throw error;
    });
};

export const refreshAccessToken=()=> async () => {
  const data = {
    grant_type: constants.REFRESH_TOKEN,
    client_id: AuthenticationEndPoints.ClientId,
    refresh_token: window.sessionStorage.getItem("refresh_token"),
  };

  const formData = queryString.stringify(data);
  await axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
    .then(async (response) => {
      if (response.status === 200) {
        saveToken(response.data);
      } else {
        handleError();
      }
    })
    .catch(async (error) => {
      handleError();
      throw error;
    });
};


const handleError=()=>
{
  sessionStorage.clear();
  redirectToAuthPage();
}

export const handleUserAuthorization = async (): Promise<string | undefined> => {
  if (isUserAuthenticated()) {
    return getToken(constants.ACCESS_TOKEN);
  }
  refreshAccessToken();
  await handleUserAuthorization();
  handleError();
  console.error('No token generated in handleUserAuthorization');
  throw new Error('No access token'); // throw an error if there is no access token
};


