// @ts-nocheck
/* eslint-disable */
import { InternalTheme } from '@spectrum-reach/mui-theme';

const buttonStyles = {
  headerButton: {
    root: {
      boxShadow: 'none',
      marginLeft: '.25rem',
      marginRight: '.15rem',
      height: '2.65rem',
    },
    label: {
      textTransform: 'none',
    },
  },
  sidebarButton: {
    root: {
      boxShadow: 'none',
      marginLeft: '.25rem',
      marginRight: '.15rem',
      height: '2.65rem',
      background: '#d2e6f9',
      color: '#106ca2',

      '&:hover': {
        color: InternalTheme.palette.common.white,
        backgroundColor: InternalTheme.palette.secondary.light,
      },
      '@media only screen and (min-width: 1563px)': {
        maxWidth: '160px !important',
        marginTop: '.0rem !important',
        margin: '.25rem !important',
      },

      '@media only screen and (min-width: 1062px)': {
        width: '90%',
        maxWidth: '250px',
        marginBottom: '.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  tableButtonOutlinePrimary: {
    root: {
      boxShadow: 'none',
      marginLeft: '.25rem',
      marginRight: '.15rem',
      height: '2.65rem',
      background: '#ffffff',
      color: '#106ca2',
      border: '1px solid #106ca2',

      '&:hover': {
        color: InternalTheme.palette.common.white,
        backgroundColor: InternalTheme.palette.secondary.light,
      },
      '@media only screen and (min-width: 1563px)': {
        maxWidth: '105px !important',
        marginTop: '.0rem !important',
        margin: '.25rem !important',
      },

      '@media only screen and (min-width: 1062px)': {
        width: '90%',
        maxWidth: '250px',
        marginBottom: '.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  tableButtonOutlineDelete: {
    root: {
      boxShadow: 'none',
      marginLeft: '.25rem',
      marginRight: '.15rem',
      height: '2.65rem',
      background: '#ffe0e0',
      color: '#f35a90',
      border: '1px solid #f35a90',

      '&:hover': {
        color: InternalTheme.palette.common.white,
        backgroundColor: '#ea3535',
      },
      '@media only screen and (min-width: 1563px)': {
        maxWidth: '105px !important',
        marginTop: '.0rem !important',
        margin: '.25rem !important',
      },

      '@media only screen and (min-width: 1062px)': {
        width: '90%',
        maxWidth: '250px',
        marginBottom: '.5rem',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  closeModalButton: {
    root: {
      boxShadow: 'none',
      marginLeft: '.25rem',
      marginRight: '.15rem',
      height: '2.65rem',

      '& .MuiSvgIcon-colorError': {
        color: '#48110c !important',
      },

      '&:hover': {
        background: '#ffe0e0',

        '& .MuiSvgIcon-colorError': {
          color: '#f35a90 !important',
        },
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  submitActionModalButton: {
    root: {
      boxShadow: 'none',
      width: '200px',
      background: InternalTheme.palette.secondary.main,

      '&:hover': {
        background: InternalTheme.palette.secondary.light,
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  buttonGroupFirst: {
    root: {
      boxShadow: 'none',
      height: '2.65rem',
      borderTopRightRadius: '0rem',
      borderBottomRightRadius: '0rem',
      color: '#00629b',
      backgroundColor: '#e6e6e6',
      borderRight: '1px solid #d4d4d4',

      '&:hover': {
        background: InternalTheme.palette.secondary.main,
        color: InternalTheme.palette.common.white,
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  buttonGroupMiddle: {
    root: {
      boxShadow: 'none',
      height: '2.65rem',
      borderTopRightRadius: '0rem',
      borderBottomRightRadius: '0rem',
      borderTopLeftRadius: '0rem',
      borderBottomLeftRadius: '0rem',
      borderRight: '1px solid #d4d4d4',
      color: '#00629b',
      backgroundColor: '#e6e6e6',

      '&:hover': {
        background: InternalTheme.palette.secondary.main,
        color: InternalTheme.palette.common.white,
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  buttonGroupSingle: {
    root: {
      boxShadow: 'none',
      height: '2.65rem',
      color: '#00629b',
      backgroundColor: '#e6e6e6',

      '&:hover': {
        background: InternalTheme.palette.secondary.main,
        color: InternalTheme.palette.common.white,
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  buttonGroupSingleExtraSmall: {
    root: {
      boxShadow: 'none',
      height: '2rem',
      color: '#00629b',
      backgroundColor: '#e6e6e6',

      '&:hover': {
        background: InternalTheme.palette.secondary.main,
        color: InternalTheme.palette.common.white,
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  buttonGroupEnd: {
    root: {
      boxShadow: 'none',
      height: '2.65rem',
      borderTopLeftRadius: '0rem',
      borderBottomLeftRadius: '0rem',
      color: '#00629b',
      backgroundColor: '#e6e6e6',

      '&:hover': {
        background: InternalTheme.palette.secondary.main,
        color: InternalTheme.palette.common.white,
      },
    },
    label: {
      textTransform: 'none',
    },
  },
  buttonGroupFirstAction: {
    root: {
      boxShadow: 'none',
      height: '2.65rem',
      borderTopRightRadius: '0rem',
      borderBottomRightRadius: '0rem',
      color: '#fff',
      backgroundColor: InternalTheme.palette.secondary.main,

      '&:hover': {
        background: InternalTheme.palette.secondary.light,
        color: InternalTheme.palette.common.white,
      },
    },
    label: {
      textTransform: 'none',
    },
  },
};

export default buttonStyles;
