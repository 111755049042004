import queryString from 'query-string';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import moment from 'moment';
import * as types from '../actionTypes/UsersActionTypes';
import {
  UsersEndPoints,
  PaymentAdjustmentsEndPoints,
  TransactionHistoryEndPoints,
} from '../../constants/apiConstants';
import { showToastMessage } from './common/ToastsActionCreators';
import { makeOrGetTableFiltersByKey } from '../selectors/common/UseTableSelectors';
import { formatDataObject } from '../../shared/helpers';
import { getErrorMessages } from '../../shared/utils/errorHandler';
import { setTableFilters } from './common/UseTableActionCreators';
import makeApiCall from '../../shared/utils/makeApiCall';
import { handleUserAuthorization } from './AuthActionCreators';

/** Throw Error Toast */
export const displayErrorToast = (dispatch: Dispatch<any>, message: string) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message,
    type: 'error',
  });
};

/** Throw Success Toast */
export const displaySuccessToast = (dispatch: Dispatch<any>, message: string) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message,
    type: 'success',
  });
};

const handleUsersLoadingRequest = (dispatch: Dispatch<any>) => {
  dispatch(usersRequestLoading());
};

const handleUsersRequestFailure = (dispatch: Dispatch<any>) => {
  dispatch(usersRequestFailure());
};

export const usersRequestLoading = (): types.IUsersRequestLoading => ({
  type: types.USERS_REQUEST_LOADING,
});

export const usersRequestFailure = (): types.IUsersRequestFailure => ({
  type: types.USERS_REQUEST_FAILURE,
});

export const loadUsersSuccess = (data: any): types.ILoadUsersSuccess => ({
  type: types.LOAD_USERS_SUCCESS,
  data,
});

const handleUsersTransactionsLoadingRequest = (dispatch: Dispatch<any>) => {
  dispatch(usersTransactionsRequestLoading());
};

const handleUsersTransactionsRequestFailure = (dispatch: Dispatch<any>) => {
  dispatch(usersTransactionsRequestFailure());
};

export const usersTransactionsRequestLoading =
  (): types.IUsersTransactionsRequestLoading => ({
    type: types.USERS_TRANSACTIONS_REQUEST_LOADING,
  });

export const usersTransactionsRequestFailure =
  (): types.IUsersTransactionsRequestFailure => ({
    type: types.USERS_TRANSACTIONS_REQUEST_FAILURE,
  });

const handleLoadUsersResponse = (dispatch: Dispatch<any>, response: any) => {
  dispatch(loadUsersSuccess(response));
};

export const loadUsers =
  (isUser?: boolean | undefined) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => { useTableReducer: { tableFilters: any } }
  ) => {
    const getPageFilters = makeOrGetTableFiltersByKey();
    const pageFilters = getPageFilters(getState(), 'users');
    const reportStartDate = pageFilters.endDate
      ? `${moment(pageFilters.startDate).format('L')} 12:00:00 AM`
      : '';
    const reportEndDate = pageFilters.endDate
      ? `${moment(pageFilters.endDate).format('L')} 11:59:00 PM`
      : '';
    const parsed = queryString.parse(window.location.search);
    const additionalParams = {
      startDate: reportStartDate,
      endDate: reportEndDate,
      userName: isUser ? parsed.userName : '',
    };
    const data = formatDataObject(pageFilters, additionalParams);
    const newData = {
      ...data,
      sortColumn: isUser ? 'firstName' : data.sortColumn,
    };
    handleUsersLoadingRequest(dispatch);

    makeApiCall.postData(`${UsersEndPoints.getUsersHistory}`, null, newData, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      // @ts-ignore
      .then(async (response) => {
        if (response.data.status === 200) {
          handleLoadUsersResponse(dispatch, response.data.data);
          if (!response.data.data.data.length) {
            displaySuccessToast(dispatch, 'No Users found');
          }
        } else {
          // Else case handled here
          handleUsersRequestFailure(dispatch);
          displayErrorToast(dispatch, 'Error while getting users');
        }
      })
      .catch(async (error) => {
        handleUsersRequestFailure(dispatch);
        displayErrorToast(dispatch, 'Error while getting users');
        throw error;
      });
  };

export const loadUsersTransactionHistorySuccess = (
  data: any
): types.ILoadUsersTransactionHistorySuccess => ({
  type: types.LOAD_USERS_TRANSACTION_HISTORY_SUCCESS,
  data,
});

const handleLoadUsersTransactionHistoryResponse = (
  dispatch: Dispatch<any>,
  response: any
) => {
  dispatch(loadUsersTransactionHistorySuccess(response));
};

/** Load users transaction history */
export const loadUsersTransactionHistory =
  (isUsersPage?: boolean | undefined, userName?: string | string[] | null | undefined) =>
  async (
    dispatch: Dispatch<any>,
    getState: () => { useTableReducer: { tableFilters: any } }
  ) => {
    const getPageFilters = makeOrGetTableFiltersByKey();
    const pageFilters = getPageFilters(getState(), 'usersTransactionHistory');
    const parsed = queryString.parse(window.location.search);
    const reportStartDate = pageFilters.endDate
      ? `${moment(pageFilters.startDate).format('L')} 12:00:00 AM`
      : '';
    const reportEndDate = pageFilters.endDate
      ? `${moment(pageFilters.endDate).format('L')} 11:59:00 PM`
      : '';
    const additionalParams = {
      userName: userName || pageFilters.userName || parsed.userName,
      paymentStatus: pageFilters.paymentStatus,
      paymentType: pageFilters.paymentType,
      columnFilterDetails: [],
      startDate: reportStartDate,
      endDate: reportEndDate,
    };
    const data = formatDataObject(pageFilters, additionalParams, isUsersPage);
    handleUsersTransactionsLoadingRequest(dispatch);

    makeApiCall.postData(`${TransactionHistoryEndPoints.getTransactionHistory}`, null, data, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      // @ts-ignore
      .then(async (response) => {
          if (response.data.status === 200) {
            handleLoadUsersTransactionHistoryResponse(dispatch, response.data.data);
            if (!response.data.data.data.length) {
              displaySuccessToast(dispatch, 'No transactions found');
            } else if (isUsersPage) {
              dispatch(
                push({
                  pathname: '/client-portal/users/transactions',
                  search: `?userName=${userName}`,
                })
              );
            }
          } else if (response.data.status === 400) {
            const errorMsg =
              response.data.error || 'Error while getting user transactions';
            handleUsersTransactionsRequestFailure(dispatch);
            displayErrorToast(dispatch, errorMsg);
          } else {
            // Else case handled here
            handleUsersTransactionsRequestFailure(dispatch);
            displayErrorToast(dispatch, 'Error while getting user transactions');
          }
        }
      )
      .catch(async (error) => {
        const errorMsg = getErrorMessages(error, 'Error while getting user transactions');
        displayErrorToast(dispatch, errorMsg);
        handleUsersTransactionsRequestFailure(dispatch);
        throw error;
      });
  };

const handleUsersPaymentsLoadingRequest = (dispatch: Dispatch<any>) => {
  dispatch(usersPaymentsRequestLoading());
};

export const usersPaymentsRequestLoading = (): types.IUsersPaymentsRequestLoading => ({
  type: types.USERS_PAYMENTS_REQUEST_LOADING,
});

const handleSendConfirmationRequestFailure = (dispatch: Dispatch<any>, errorMsg: any) => {
  dispatch(sendConfirmationRequestFailure(errorMsg));
};

export const sendConfirmationRequestFailure = (
  errorMsg: any
): types.ISendConfirmationRequestFailure => ({
  type: types.SEND_CONFIRMATION_REQUEST_FAILURE,
  data: errorMsg,
});

export const sendConfirmationRequestSuccess =
  (): types.ISendConfirmationRequestSuccess => ({
    type: types.SEND_CONFIRMATION_REQUEST_SUCCESS,
  });

const handleSendConfirmationRequestSuccess = (dispatch: Dispatch<any>) => {
  dispatch(sendConfirmationRequestSuccess());
};

/** Send Email Confirmation */
export const sendEmail =
  (transactionId: string, email: string) => async (dispatch: Dispatch<any>) => {
    handleUsersPaymentsLoadingRequest(dispatch);
    makeApiCall.postData(`${PaymentAdjustmentsEndPoints.sendEmailConfirmation}`, null, {
      transactionId,
        email,
    }, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })

      // @ts-ignore
      .then(async (response) => {
        if (response.data.status === 200 && response.data.isSuccess) {
          toggleSendPaymentReceiptModal(dispatch, false);
          handleSendConfirmationRequestSuccess(dispatch);
          displaySuccessToast(dispatch, 'Successfully sent email receipt');
        } else if (response.data.status === 400) {
          const errorMsg = response.data.error || 'Error in sending email receipt';
          handleSendConfirmationRequestFailure(dispatch, errorMsg);
          displayErrorToast(dispatch, errorMsg);
        } else {
          toggleSendPaymentReceiptModal(dispatch, false);
          // Else case handled here
          handleSendConfirmationRequestFailure(
            dispatch,
            'Error in sending email receipt'
          );
          displayErrorToast(dispatch, 'Error in sending email receipt');
        }
      })
      .catch(async (error) => {
        const errorMsg = getErrorMessages(error, 'Error in sending email receipt');
        displayErrorToast(dispatch, errorMsg);
        handleSendConfirmationRequestFailure(dispatch, errorMsg);
        throw error;
      });
  };

const handleRecurringPaymentRequestFailure = (dispatch: Dispatch<any>) => {
  dispatch(getRecurringPaymentRequestFailure());
};

export const getRecurringPaymentRequestFailure =
  (): types.IGetRecurringPaymentRequestFailure => ({
    type: types.GET_RECURRING_PAYMENT_REQUEST_FAILURE,
  });

export const getRecurringPaymentRequestSuccess = (
  data: any
): types.IGetRecurringPaymentRequestSuccess => ({
  type: types.GET_RECURRING_PAYMENT_REQUEST_SUCCESS,
  data,
});

const handleRecurringPaymentRequestSuccess = (dispatch: Dispatch<any>, data: any) => {
  dispatch(getRecurringPaymentRequestSuccess(data));
};

/** Get Recurring Payment Informarion */
export const fetchRecurringPaymentInfo =
  (userName?: string | string[] | null | undefined) =>
  async (dispatch: Dispatch<any>) => {
    const parsed = queryString.parse(window.location.search);
    const name = userName || parsed.userName;
    handleUsersPaymentsLoadingRequest(dispatch);
    makeApiCall.getData(`${PaymentAdjustmentsEndPoints.getRecurringPaymentInformation}?userName=${name}`,null, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      // @ts-ignore
      .then(async (response) => {
          if (response.data.status === 200) {
            const responseData = response.data.data;
            handleRecurringPaymentRequestSuccess(dispatch, responseData);
            if (response.data.data) {
              const filterValue = {
                pageKey: 'recurringPaymentModal',
                newFilters: {
                  startDate: response.data.data.startDate,
                  endDate: response.data.data.endDate,
                },
              };
              setTableFilters(dispatch, filterValue);
            }
          } else if (response.data.status === 400) {
            const errorMsg =
              response.data.error || 'Error in retrieving payment information';
            handleRecurringPaymentRequestFailure(dispatch);
            displayErrorToast(dispatch, errorMsg);
          } else {
            // Else case handled here
            handleRecurringPaymentRequestFailure(dispatch);
            displayErrorToast(dispatch, 'Error in retrieving payment information');
          }
        }
      )
      .catch(async (error) => {
        const errorMsg = getErrorMessages(
          error,
          'Error in retrieving payment information'
        );
        displayErrorToast(dispatch, errorMsg);
        handleRecurringPaymentRequestFailure(dispatch);
        throw error;
      });
  };

export const updateRecurringPaymentRequestFailure = (
  errorMsg: any
): types.IUpdateRecurringPaymentRequestFailure => ({
  type: types.UPDATE_RECURRING_PAYMENT_REQUEST_FAILURE,
  data: errorMsg,
});

const handleDisableRecurringPaymentRequestFailure = (
  dispatch: (arg0: { type: string }) => void
) => {
  dispatch(disableRecurringPaymentRequestFailure());
};

export const disableRecurringPaymentRequestFailure =
  (): types.IDisableRecurringPaymentRequestFailure => ({
    type: types.DISABLE_RECURRING_PAYMENT_REQUEST_FAILURE,
  });

export const disableRecurringPaymentRequestSuccess =
  (): types.IDisableRecurringPaymentRequestSuccess => ({
    type: types.DISABLE_RECURRING_PAYMENT_REQUEST_SUCCESS,
  });

const handleDisableRecurringPaymentRequestSuccess = (
  dispatch: Dispatch<any>
) => {
  dispatch(disableRecurringPaymentRequestSuccess());
};

/** Disable Recurring Payment */
export const disableRecurringPayment =
  (id: any, isLastEntry: any) => async (dispatch: Dispatch<any>) => {
    const parsed = queryString.parse(window.location.search);
    const userName = parsed.userName;
    const data = {
      id,
      userName,
    };
    handleUsersPaymentsLoadingRequest(dispatch);
    makeApiCall.postData(`${PaymentAdjustmentsEndPoints.disableAutoPay}`, null, data, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })

      // @ts-ignore
      .then(async (response) => {
        isLastEntry && toggleUpdateRecurringPaymentModal(dispatch, false);
        if (response.data.status === 200) {
          handleDisableRecurringPaymentRequestSuccess(dispatch);
          displaySuccessToast(dispatch, 'Successfully Disabled Recurring Payment');
          dispatch(loadUsersTransactionHistory());
          dispatch(fetchRecurringPaymentInfo());
        } else {
          // Else case handled here
          const cancelError =
            response.data.error || 'Error in disabling recurring payment';
          handleDisableRecurringPaymentRequestFailure(dispatch);
          displayErrorToast(dispatch, cancelError);
        }
      })
      .catch(async (error) => {
        isLastEntry && toggleUpdateRecurringPaymentModal(dispatch, false);
        const errorMsg = getErrorMessages(error, 'Error in disabling recurring payment');
        displayErrorToast(dispatch, errorMsg);
        dispatch(fetchRecurringPaymentInfo());
        handleDisableRecurringPaymentRequestFailure(dispatch);
        throw error;
      });
  };

const handlePaymentTypesRequestFailure = (dispatch: Dispatch<any>, errorMsg: any) => {
  dispatch(getPaymentTypesRequestFailure(errorMsg));
};

export const getPaymentTypesRequestFailure = (
  errorMsg: any
): types.IGetPaymentTypesFailure => ({
  type: types.GET_PAYMENT_TYPES_REQUEST_FAILURE,
  data: errorMsg,
});

export const getPaymentTypesRequestSuccess = (
  data: any
): types.IGetPaymentTypesSuccess => ({
  type: types.GET_PAYMENT_TYPES_REQUEST_SUCCESS,
  data,
});

const handlePaymentTypesRequestSuccess = (dispatch: Dispatch<any>, data: any) => {
  dispatch(getPaymentTypesRequestSuccess(data));
};

/** Get Payment Types */
export const fetchPaymentTypes = () => async (dispatch: Dispatch<any>) => {
  handleUsersPaymentsLoadingRequest(dispatch);
  makeApiCall.getData(`${PaymentAdjustmentsEndPoints.fetchPaymentTypes}`, null, {
    'Authorization': `Bearer ${await handleUserAuthorization()}`,
  })
    // @ts-ignore
    .then(async (response) => {
      if (response.data.status === 200) {
        handlePaymentTypesRequestSuccess(dispatch, response.data.data);
      } else if (response.data.status === 400) {
        const errorMsg = response.data.error || 'Error in fetching payment types data';
        handlePaymentTypesRequestFailure(dispatch, errorMsg);
        displayErrorToast(dispatch, errorMsg);
      } else {
        // Else case handled here
        handlePaymentTypesRequestFailure(
          dispatch,
          'Error in fetching payment types data'
        );
        displayErrorToast(dispatch, 'Error in fetching payment types data');
      }
    })
    .catch(async (error) => {
      const errorMsg = getErrorMessages(error, 'Error in fetching payment types data');
      displayErrorToast(dispatch, errorMsg);
      handlePaymentTypesRequestFailure(dispatch, errorMsg);
      throw error;
    });
};

const handlePaymentStatusRequestFailure = (dispatch: Dispatch<any>, errorMsg: any) => {
  dispatch(getPaymentStatusRequestFailure(errorMsg));
};

export const getPaymentStatusRequestFailure = (
  errorMsg: any
): types.IGetPaymentStatusesFailure => ({
  type: types.GET_PAYMENT_STATUS_REQUEST_FAILURE,
  data: errorMsg,
});

export const getPaymentStatusRequestSuccess = (
  data: any
): types.IGetPaymentStatusesSuccess => ({
  type: types.GET_PAYMENT_STATUS_REQUEST_SUCCESS,
  data,
});

const handlePaymentStatusRequestSuccess = (dispatch: Dispatch<any>, data: any) => {
  dispatch(getPaymentStatusRequestSuccess(data));
};

/** Get Payment Statuses */
export const fetchPaymentStatuses = () => async (dispatch: Dispatch<any>) => {
  handleUsersPaymentsLoadingRequest(dispatch);
  makeApiCall.getData(`${PaymentAdjustmentsEndPoints.fetchPaymentStatus}`, null, {
    'Authorization': `Bearer ${await handleUserAuthorization()}`,
  })

    // @ts-ignore
    .then(async (response) => {
      if (response.data.status === 200) {
        handlePaymentStatusRequestSuccess(dispatch, response.data.data);
      } else if (response.data.status === 400) {
        const errorMsg = response.data.error || 'Error in fetching payment statuses data';
        handlePaymentStatusRequestFailure(dispatch, errorMsg);
        displayErrorToast(dispatch, errorMsg);
      } else {
        // Else case handled here
        handlePaymentStatusRequestFailure(
          dispatch,
          'Error in fetching payment statuses data'
        );
        displayErrorToast(dispatch, 'Error in fetching payment statuses data');
      }
    })
    .catch(async (error) => {
      const errorMsg = getErrorMessages(error, 'Error in fetching payment statuses data');
      displayErrorToast(dispatch, errorMsg);
      handlePaymentStatusRequestFailure(dispatch, errorMsg);
      throw error;
    });
};

const handleUpdateScheduledPaymentRequestFailure = (
  dispatch: Dispatch<any>,
  errorMsg: any
) => {
  dispatch(updateScheduledPaymentRequestFailure(errorMsg));
};

export const updateScheduledPaymentRequestFailure = (errorMsg: any) => ({
  type: types.UPDATE_SCHEDULED_PAYMENT_REQUEST_FAILURE,
  data: errorMsg,
});

export const updateScheduledPaymentRequestSuccess =
  (): types.IUpdateScheduledPaymentRequestSuccess => ({
    type: types.UPDATE_SCHEDULED_PAYMENT_REQUEST_SUCCESS,
  });

const handleUpdateScheduledPaymentRequestSuccess = (dispatch: Dispatch<any>) => {
  dispatch(updateScheduledPaymentRequestSuccess());
};

/** Update Scheduled Payment Informarion */
export const updateScheduledPaymentInfo =
  (scheduledPaymentData: {
    id: any;
    amount: any;
    paymentDate: any;
    paymentDetails: any;
  }) =>
  async (dispatch: Dispatch<any>) => {
    handleUsersPaymentsLoadingRequest(dispatch);
    makeApiCall.postData(`${PaymentAdjustmentsEndPoints.updateScheduledPayment}`, null, scheduledPaymentData, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      // @ts-ignore
      .then(async (response) => {
        if (response.data.status === 200) {
          toggleUpdateScheduledPaymentModal(dispatch, false);
          handleUpdateScheduledPaymentRequestSuccess(dispatch);
          displaySuccessToast(
            dispatch,
            'Successfully updated Scheduled payment information'
          );
          dispatch(loadUsersTransactionHistory());
        } else if (response.data.status === 400) {
          const errorMsg = response.data.error || 'Error in updating payment';
          handleUpdateScheduledPaymentRequestFailure(dispatch, errorMsg);
          displayErrorToast(dispatch, errorMsg);
        } else {
          // Else case handled here
          toggleUpdateScheduledPaymentModal(dispatch, false);
          handleUpdateScheduledPaymentRequestFailure(
            dispatch,
            'Error in updating payment'
          );
          displayErrorToast(dispatch, 'Error in updating payment');
        }
      })
      .catch(async (error) => {
        const errorMsg = getErrorMessages(error, 'Error in updating payment');
        displayErrorToast(dispatch, errorMsg);
        handleUpdateScheduledPaymentRequestFailure(dispatch, errorMsg);
        throw error;
      });
  };

const handleCancelScheduledPaymentRequestFailure = (dispatch: Dispatch<any>) => {
  dispatch(cancelScheduledPaymentRequestFailure());
};

export const cancelScheduledPaymentRequestFailure =
  (): types.IDisableScheduledPaymentRequestFailure => ({
    type: types.DISABLE_SCHEDULED_PAYMENT_REQUEST_FAILURE,
  });

export const cancelScheduledPaymentRequestSuccess =
  (): types.IDisableScheduledPaymentRequestSuccess => ({
    type: types.DISABLE_SCHEDULED_PAYMENT_REQUEST_SUCCESS,
  });

const handleCancelScheduledPaymentRequestSuccess = (dispatch: Dispatch<any>) => {
  dispatch(cancelScheduledPaymentRequestSuccess());
};

/** Cancel Scheduled Payment */
export const cancelScheduledPayment = (id: any) => async (dispatch: Dispatch<any>) => {
  handleUsersPaymentsLoadingRequest(dispatch);
  makeApiCall.postData(`${PaymentAdjustmentsEndPoints.cancelScheduledPayment}`, null, {
    id,
  }, {
    'Authorization': `Bearer ${await handleUserAuthorization()}`,
  })
    // @ts-ignore
    .then(async (response) => {
      if (response.data.status === 200) {
        handleCancelScheduledPaymentRequestSuccess(dispatch);
        displaySuccessToast(dispatch, 'Successfully Cancelled Scheduled Payment');
        dispatch(loadUsersTransactionHistory());
      } else {
        // Else case handled here
        handleCancelScheduledPaymentRequestFailure(dispatch);
        const cancelError =
          response.data.error || 'Error in cancelling Scheduled payment';
        displayErrorToast(dispatch, cancelError);
      }
    })
    .catch(async (error) => {
      const errorMsg = getErrorMessages(error, 'Error in cancelling Scheduled payment');
      displayErrorToast(dispatch, errorMsg);
      handleCancelScheduledPaymentRequestFailure(dispatch);
      throw error;
    });
};

/** Action to toggle send payment receipt modal */
export const toggleSendPaymentReceiptModal = (dispatch: Dispatch<any>, data: boolean) => {
  const open = {
    type: types.TOGGLE_SEND_PAYMENT_RECEIPT_MODAL,
    data,
  };
  dispatch(open);
};

/** Action to toggle update recurring payment modal */
export const toggleUpdateRecurringPaymentModal = (
  dispatch: Dispatch<any>,
  data: boolean
) => {
  const open = {
    type: types.TOGGLE_UPDATE_RECURRING_PAYMENT_MODAL,
    data,
  };
  dispatch(open);
};

/** Action to toggle update scheduled payment modal */
export const toggleUpdateScheduledPaymentModal = (
  dispatch: Dispatch<any>,
  data: boolean
) => {
  const open = {
    type: types.TOGGLE_UPDATE_SCHEDULED_PAYMENT_MODAL,
    data,
  };
  dispatch(open);
};

/** Action to toggle edit modal */
export const toggleEditModal = (dispatch: Dispatch<any>, data: boolean) => {
  const open = {
    type: types.TOGGLE_EDIT_MODAL,
    data,
  };
  dispatch(open);
};

const handleUpdateUserRequestFailure = (dispatch: Dispatch<any>, errorMsg: any) => {
  dispatch(updateUserRequestFailure(errorMsg));
};

export const updateUserRequestFailure = (
  errorMsg: any
): types.IUpdateUserRequestFailure => ({
  type: types.UPDATE_USER_REQUEST_FAILURE,
  data: errorMsg,
});

export const updateUserRequestSuccess = (): types.IUpdateUserRequestSuccess => ({
  type: types.UPDATE_USER_REQUEST_SUCCESS,
});

const handleUpdateUserRequestSuccess = (dispatch: Dispatch<any>) => {
  dispatch(updateUserRequestSuccess());
};

/** Update User details */
export const updateUserDetails =
  (
    data: {
      firstName: any;
      lastName: any;
      email: any;
      companyName: any;
      accountExecutive: any;
      agencyId: any;
      phoneNumber: any;
    },
    userName: string
  ) =>
  async (dispatch: Dispatch<any>) => {
    handleUsersLoadingRequest(dispatch);
    makeApiCall.postData(`${UsersEndPoints.updateUser}?userName=${userName}`, null, data, {
      'Authorization': `Bearer ${await handleUserAuthorization()}`,
    })
      // @ts-ignore
      .then(async (response) => {
        if (response.data.status === 200) {
          toggleEditModal(dispatch, false);
          handleUpdateUserRequestSuccess(dispatch);
          displaySuccessToast(dispatch, 'Successfully updated user details');
          dispatch(loadUsers());
        } else if (response.data.status === 400) {
          const errorMsg = response.data.error || 'Error in updating user details';
          handleUpdateUserRequestFailure(dispatch, errorMsg);
          displayErrorToast(dispatch, errorMsg);
        } else {
          // Else case handled here
          toggleEditModal(dispatch, false);
          handleUpdateUserRequestFailure(dispatch, 'Error in updating user details');
          displayErrorToast(dispatch, 'Error in updating user details');
        }
      })
      .catch(async (error) => {
        const errorMsg = getErrorMessages(error, 'Error in updating user details');
        displayErrorToast(dispatch, errorMsg);
        handleUpdateUserRequestFailure(dispatch, errorMsg);
        throw error;
      });
  };

const handleRecurringPaymentTypesRequestFailure = (
  dispatch: Dispatch<any>,
  errorMsg: any
) => {
  dispatch(getRecurringPaymentTypesRequestFailure(errorMsg));
};

export const getRecurringPaymentTypesRequestFailure = (
  errorMsg: any
): types.IGetRecurringPaymentTypesRequestFailure => ({
  type: types.GET_RECURRING_PAYMENT_TYPES_REQUEST_FAILURE,
  data: errorMsg,
});

export const getRecurringPaymentTypesRequestSuccess = (
  data: any
): types.IGetRecurringPaymentTypesRequestSuccess => ({
  type: types.GET_RECURRING_PAYMENT_TYPES_REQUEST_SUCCESS,
  data,
});

const handleRecurringPaymentTypesRequestSuccess = (
  dispatch: Dispatch<any>,
  data: any
) => {
  dispatch(getRecurringPaymentTypesRequestSuccess(data));
};

/** Get Recurring Payment Types */
export const fetchRecurringPaymentTypesAction = () => async (dispatch: Dispatch<any>) => {
  handleUsersPaymentsLoadingRequest(dispatch);

  makeApiCall.getData(`${PaymentAdjustmentsEndPoints.recurringPaymentTypes}`, null, {
    'Authorization': `Bearer ${await handleUserAuthorization()}`,
  })
    // @ts-ignore
    .then(async (response) => {
      if (response.data.status === 200) {
        handleRecurringPaymentTypesRequestSuccess(dispatch, response.data.data);
      } else if (response.data.status === 400) {
        const errorMsg = response.data.error || 'Error in fetching recurring types data';
        handleRecurringPaymentTypesRequestFailure(dispatch, errorMsg);
        displayErrorToast(dispatch, errorMsg);
      } else {
        // Else case handled here
        handleRecurringPaymentTypesRequestFailure(
          dispatch,
          'Error in fetching recurring types data'
        );
        displayErrorToast(dispatch, 'Error in fetching recurring types data');
      }
    })
    .catch(async (error) => {
      const errorMsg = getErrorMessages(error, 'Error in fetching recurring types data');
      displayErrorToast(dispatch, errorMsg);
      handleRecurringPaymentTypesRequestFailure(dispatch, errorMsg);
      throw error;
    });
};
