import React from 'react';
import { TitledListItem } from '../../../../shared/components/TitledListItem';
import { Box } from '@material-ui/core';
import { SubHeader } from '../../../../shared/components/SubHeader';
import { formatCurrency } from '../../../../shared/helpers';
import { IPaymentTransactionDetails } from 'redux/actionTypes/TransactionsActionTypes';

interface ITransactionDetail {
  details: IPaymentTransactionDetails[];
}

const TransactionDetail: React.FC<ITransactionDetail> = (props) => {
  const data = props.details;
  return (
    <div>
      <Box pt={2} pb={2}>
        <SubHeader subTitle="Transaction Details" />
      </Box>
      {data?.map((row) => (
        <TitledListItem
          key={row.id}
          id={row.id}
          title={`${row.orderTypeId === 1 ? 'Order' : 'Invoice'} - ${row.orderId}`}
          description={formatCurrency(row.amount)}
        />
      ))}
    </div>
  );
};

export default TransactionDetail;
