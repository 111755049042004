// @ts-nocheck
import React from 'react';
import { Button as IapButton, makeStyles, ButtonProps } from '@material-ui/core';
import buttonStyles from '../styles/buttonStyles';

interface IButton extends ButtonProps {
  customRoot?: any;
  customButtonType?: string;
  text?: string;
  className?: string;
  onClick?: any;
}

const Button: React.FC<IButton> = (props) => {
  const { text, size, color, variant, onClick, customButtonType, customRoot, ...other } =
    props;
  let useStyles;

  if (!customRoot) {
    useStyles = makeStyles((theme) => ({
      root: {
        margin: theme.spacing(0.5),
        boxShadow: 'none',
      },
      label: {
        textTransform: 'none',
      },
    }));
  }
  if (customButtonType) {
    if (customButtonType === 'headerButton')
      useStyles = makeStyles(() => ({
        root: buttonStyles.headerButton.root,
        label: buttonStyles.headerButton.label,
      }));
    if (customButtonType === 'sidebarButton')
      useStyles = makeStyles(() => ({
        root: buttonStyles.sidebarButton.root,
        label: buttonStyles.sidebarButton.label,
      }));
    if (customButtonType === 'tableButtonOutlinePrimary')
      useStyles = makeStyles(() => ({
        root: buttonStyles.tableButtonOutlinePrimary.root,
        label: buttonStyles.tableButtonOutlinePrimary.label,
      }));
    if (customButtonType === 'tableButtonOutlineDelete')
      useStyles = makeStyles(() => ({
        root: buttonStyles.tableButtonOutlineDelete.root,
        label: buttonStyles.tableButtonOutlineDelete.label,
      }));
    if (customButtonType === 'submitActionModalButton')
      useStyles = makeStyles(() => ({
        root: buttonStyles.submitActionModalButton.root,
        label: buttonStyles.submitActionModalButton.label,
      }));
    if (customButtonType === 'buttonGroupFirst')
      useStyles = makeStyles(() => ({
        root: buttonStyles.buttonGroupFirst.root,
        label: buttonStyles.buttonGroupFirst.label,
      }));
    if (customButtonType === 'buttonGroupMiddle')
      useStyles = makeStyles(() => ({
        root: buttonStyles.buttonGroupMiddle.root,
        label: buttonStyles.buttonGroupMiddle.label,
      }));
    if (customButtonType === 'buttonGroupEnd')
      useStyles = makeStyles(() => ({
        root: buttonStyles.buttonGroupEnd.root,
        label: buttonStyles.buttonGroupEnd.label,
      }));
    if (customButtonType === 'buttonGroupSingle')
      useStyles = makeStyles(() => ({
        root: buttonStyles.buttonGroupSingle.root,
        label: buttonStyles.buttonGroupSingle.label,
      }));
    if (customButtonType === 'buttonGroupFirstAction')
      useStyles = makeStyles(() => ({
        root: buttonStyles.buttonGroupFirstAction.root,
        label: buttonStyles.buttonGroupFirstAction.label,
      }));

    if (customButtonType === 'buttonGroupSingleExtraSmall')
      useStyles = makeStyles(() => ({
        root: buttonStyles.buttonGroupSingleExtraSmall.root,
        label: buttonStyles.buttonGroupSingleExtraSmall.label,
      }));
  } else {
    useStyles = makeStyles(() => ({
      root: customRoot,
      label: {
        textTransform: 'none',
      },
    }));
  }
  // @ts-ignore
  const classes = useStyles();

  return (
    <IapButton
      variant={variant || 'contained'}
      size={size || 'large'}
      color={color || 'primary'}
      onClick={onClick}
      {...other}
      classes={{
        root: classes.root,
        label: classes.label,
      }}
    >
      {text}
    </IapButton>
  );
};

export default Button;
