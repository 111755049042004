/* eslint-disable */
import React, { useState, useEffect, ComponentType } from 'react';
import { constants } from 'constants/authHandlerConstants';
import { RouteProps } from 'react-router-dom';
import { exchangeCodeForToken, refreshAccessToken } from '../redux/actionCreators/AuthActionCreators';
import { cognitoGroup } from "../constants/authHandlerConstants";
import { useDispatch } from 'react-redux';
import NoAccess from '../shared/components/NoAccess';
import { decodeToken,isTokenExpired,redirectToAuthPage,getCodeFromUrl,getToken } from './AuthUtils';


interface IAuthorizeRoute extends RouteProps {
    component: ComponentType;
}

const AuthorizeRoute: React.FC<IAuthorizeRoute> = (props) => {
    const dispatch = useDispatch();
    const securityGroup = cognitoGroup.GROUP;

    const [isUserAuthorized, setIsUserAuthorized] = useState(false);
    const [noAccess, setNoAccess] = useState(false);

    useEffect(() => {
        const accessToken = getToken(constants.ACCESS_TOKEN);

        if (accessToken) {
            const decodedToken = decodeToken(accessToken);

            if (isTokenExpired(decodedToken)) {
                if (decodedToken['cognito:groups'].includes(securityGroup)) {
                    setIsUserAuthorized(true)
                } else {
                    setNoAccess(true);
                }
            } else {
                const refreshToken = getToken(constants.REFRESH_TOKEN);
                if (refreshToken) {
                    refreshAccessToken();
                } else {
                    // Token is expired and refresh token is not present - redirect to authentication page
                    redirectToAuthPage();
                }
            }
        } else {
            const code = getCodeFromUrl();
            if (code) {
                dispatch(exchangeCodeForToken(code));
                // Code is present in the URL - exchange it for an access token

            } else {
                // Code is not present in the URL - redirect to authentication page
                redirectToAuthPage();
            }
        }
    }, []);

    return (
      <>
          {isUserAuthorized && React.createElement(props.component)}
          {noAccess && <NoAccess />}
      </>
    );
};

export default AuthorizeRoute;
