import React from 'react';
import { Snackbar, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actionCreators/common/ToastsActionCreators';
import { initialState } from '../../rootReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    top: theme.spacing(9),
  },
}));

/** Toast Messages Component */
const Toasts: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { toasts } = useSelector((state: initialState) => ({
    toasts: state.toastsReducer,
  }));
  const handleClose = () => {
    Actions.showToastMessage(dispatch, {
      ...toasts,
      isOpen: false,
    });
  };

  return (
    <>
      {toasts.isOpen ? (
        <Snackbar
          className={classes.root}
          open={toasts.isOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={handleClose}
        >
          <Alert severity={toasts.type} onClose={handleClose}>
            {toasts.message}
          </Alert>
        </Snackbar>
      ) : null}
    </>
  );
};

export default Toasts;
