import * as types from '../../actionTypes/ReportingActionTypes';

const reportingInitialState: types.IReportingInitialState = {
  isDownloadReportLoading: false,
};

const reportingReducer = (
  state = reportingInitialState,
  action: types.IReportingActions
) => {
  switch (action.type) {
    case types.DOWNLOAD_REPORTING_LOADING:
      return {
        ...state,
        isDownloadReportLoading: true,
      };
    case types.DOWNLOAD_REPORTING_FAILURE:
      return {
        ...state,
        isDownloadReportLoading: false,
      };
    case types.DOWNLOAD_REPORTING_SUCCESS:
      return {
        ...state,
        isDownloadReportLoading: false,
      };

    default:
      return state;
  }
};

export { reportingInitialState, reportingReducer };
