import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, ListItem, List, ListSubheader, ListItemIcon } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import {
  ClientPortalLinks,
  IClientPortalLinks,
} from '../routeConfigs/clientPortalConfig';
import Icons from './Icons';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
  },
  panel: {
    height: 350,
    width: 300,
  },
  boxHeader: {
    color: theme.palette.primary.main,
    fontSize: '2.2rem',
    textAlign: 'left',
    fontFamily: 'Reach-Text-Regular, sans-serif',
    paddingBottom: '1rem',
    paddingTop: '1rem',
  },
  icon: {
    color: theme.palette.primary.main,
    fill: '#095088 !important',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',

    '& :hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#bad8f1 !important',
    },
  },
}));

interface ILink {
  text: string;
  route: string;
  icon: string;
}

interface IGridItem {
  header: string;
  links: IClientPortalLinks[];
}

const Home: React.FC = () => {
  const classes = useStyles();

  const ListLink = ({ text, route, icon }: ILink) => (
    <ListItem button key={route}>
      <ListItemIcon>
        <Icons icon={icon} customClass={classes.icon} />
      </ListItemIcon>
      <NavLink exact to={route} className={classes.link}>
        {text}
      </NavLink>
    </ListItem>
  );

  const GridItem = ({ header, links }: IGridItem) => (
    <Grid item>
      <div className={classes.panel}>
        <List
          subheader={
            <ListSubheader component="div" className={classes.boxHeader}>
              {header}
            </ListSubheader>
          }
        >
          {links &&
            links.map(({ linkName, subRoute, icon }: IClientPortalLinks) => (
              <ListLink text={linkName} route={subRoute} icon={icon} />
            ))}
        </List>
      </div>
    </Grid>
  );
  return (
    <Grid
      container
      className={classes.wrapper}
      spacing={3}
      alignItems="center"
      justify="center"
    >
      <GridItem header="Client Portal" links={ClientPortalLinks} />
    </Grid>
  );
};

export default Home;
