import React from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import StorageIcon from '@material-ui/icons/Storage';
import FilterListIcon from '@material-ui/icons/FilterList';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import EmailIcon from '@material-ui/icons/Email';
import ArchiveIcon from '@material-ui/icons/Archive';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import CreateIcon from '@material-ui/icons/Create';
import HistoryIcon from '@material-ui/icons/History';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DvrIcon from '@material-ui/icons/Dvr';
import LinkIcon from '@material-ui/icons/Link';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PeopleIcon from '@material-ui/icons/People';


interface IIcons {
  icon: string;
  customClass?: any;
}
const Icons: React.FC<IIcons> = (props) => {
  const { icon } = props;
  const getIcon = (iconText: string) => {
    let iconComponent;
    switch (iconText) {
      case 'SettingsIcon':
        iconComponent = <SettingsIcon className={props.customClass} />;
        break;
      case 'FilterListIcon':
        iconComponent = <FilterListIcon className={props.customClass} />;
        break;
      case 'PermIdentityIcon':
        iconComponent = <PermIdentityIcon className={props.customClass} />;
        break;
      case 'StorageIcon':
        iconComponent = <StorageIcon className={props.customClass} />;
        break;
      case 'EmailIcon':
        iconComponent = <EmailIcon className={props.customClass} />;
        break;
      case 'ArchiveIcon':
        iconComponent = <ArchiveIcon className={props.customClass} />;
        break;
      case 'MonetizationOnIcon':
        iconComponent = <MonetizationOnIcon className={props.customClass} />;
        break;
      case 'FileCopyIcon':
        iconComponent = <FileCopyIcon className={props.customClass} />;
        break;
      case 'LocalAtmIcon':
        iconComponent = <LocalAtmIcon className={props.customClass} />;
        break;
      case 'KeyIcon':
        iconComponent = <VpnKeyIcon className={props.customClass} />;
        break;
      case 'CreateIcon':
        iconComponent = <CreateIcon className={props.customClass} />;
        break;
      case 'AccountBalanceIcon':
        iconComponent = <AccountBalanceIcon className={props.customClass} />;
        break;
      case 'DvrIcon':
        iconComponent = <DvrIcon className={props.customClass} />;
        break;
      case 'HistoryIcon':
        iconComponent = <HistoryIcon className={props.customClass} />;
        break;
      case 'InsertDriveFileIcon':
        iconComponent = <InsertDriveFileIcon className={props.customClass} />;
        break;
      case 'LinkIcon':
        iconComponent = <LinkIcon className={props.customClass} />;
        break;
      case 'PeopleIcon':
        iconComponent = <PeopleIcon className={props.customClass} />;
        break;
      default:
        iconComponent = '';
        break;
    }
    return iconComponent;
  };
  return <>{getIcon(icon)}</>;
};

export default Icons;
