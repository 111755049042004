import * as types from '../../actionTypes/TransactionsActionTypes';

const transactionsInitialState: types.ITransactionsInitialState = {
  transactionsData: [],
  totalRecords: 0,
  isTransactionsLoading: false,
};

const transactionsReducer = (
  state = transactionsInitialState,
  action: types.ITransactionsActions
) => {
  switch (action.type) {
    case types.TRANSACTIONS_REQUEST_LOADING:
      return {
        ...state,
        isTransactionsLoading: true,
      };
    case types.TRANSACTIONS_REQUEST_FAILURE:
      return {
        ...state,
        isTransactionsLoading: false,
      };
    case types.LOAD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionsData: action.data.data,
        totalRecords: action.data.totalRecords,
        isTransactionsLoading: false,
      };
    case types.EXPORT_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isTransactionsLoading: false,
      };
    default:
      return state;
  }
};

export { transactionsInitialState, transactionsReducer };
