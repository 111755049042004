import queryString from 'query-string';
import { Dispatch } from 'redux';
import moment from 'moment';
import * as types from '../actionTypes/AdportalActionTypes';
import { showToastMessage } from './common/ToastsActionCreators';
import { makeOrGetTableFiltersByKey } from '../selectors/common/UseTableSelectors';
import { formatDataObject } from '../../shared/helpers';
import { TransactionHistoryEndPoints } from '../../constants/apiConstants';
import { getErrorMessages } from '../../shared/utils/errorHandler';
import makeApiCall from '../../shared/utils/makeApiCall';
import { handleUserAuthorization } from './AuthActionCreators';

/** Throw Error Toast */
export const displayErrorToast = (dispatch: Dispatch<any>, message: string) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message,
    type: 'error',
  });
};

/** Throw Success Toast */
export const displaySuccessToast = (dispatch: Dispatch<any>, message: string) => {
  showToastMessage(dispatch, {
    isOpen: true,
    message,
    type: 'success',
  });
};

const handleadportalLoadingRequest = (dispatch: Dispatch<any>) => {
  dispatch(adportalRequestLoading());
};

const handleLoadadportalPaymnetsResponse = (dispatch: Dispatch<any>, response: any) => {
  dispatch(loadAdportalSuccess(response));
};
const handleAdportalRequestFailure = (dispatch: Dispatch<any>) => {
  dispatch(adportalRequestFailure());
};
const handleAdportalDownloadRequestSuccess = (dispatch: Dispatch<any>) => {
  dispatch(adportalDownloadRequestSuccess());
};
export const adportalRequestLoading = (): types.IAdportalRequestLoading => ({
  type: types.ADPORTAL_REQUEST_LOADING,
});
export const loadAdportalSuccess = (data: any): types.ILoadAdportalSuccess => ({
  type: types.LOAD_ADPORTAL_SUCCESS,
  data,
});
export const adportalRequestFailure = (): types.IAdportalRequestFailure => ({
  type: types.ADPORTAL_REQUEST_FAILURE,
});
export const adportalDownloadRequestSuccess = (): types.IAdportalDownloadRequestSuccess => ({
  type: types.ADPORTAL_DOWNLOAD_REQUEST_SUCCESS,
});

export const loadAdportalTransactions =
  (isUser?: boolean | undefined) =>
    async (
      dispatch: Dispatch<any>,
      getState: () => { useTableReducer: { tableFilters: any } },
    ) => {
      const getPageFilters = makeOrGetTableFiltersByKey();
      const pageFilters = getPageFilters(getState(), 'adportal');
      const reportStartDate = pageFilters.endDate
        ? `${moment(pageFilters.startDate).format('L')}`
        : '';
      const reportEndDate = pageFilters.endDate
        ? `${moment(pageFilters.endDate).add(1, 'days').format('L')}`
        : '';
      const parsed = queryString.parse(window.location.search);
      const additionalParams = {
        startDate: reportStartDate,
        endDate: reportEndDate,
        userName: isUser ? parsed.userName : '',
      };
      const data = formatDataObject(pageFilters, additionalParams);
      const newData = {
        ...data,
        sortColumn: isUser ? 'firstName' : data.sortColumn,
      };
      handleadportalLoadingRequest(dispatch);

      const url = `${TransactionHistoryEndPoints.adPortalTransactions}`;
      makeApiCall.postData(url, null, newData, {
        'Authorization': `Bearer ${await handleUserAuthorization()}`,
      })
        .then(async (response) => {
          if (response.data.status === 200) {
            handleLoadadportalPaymnetsResponse(dispatch, response.data.data);
            if (!response.data.data.data.length) {
              displaySuccessToast(dispatch, 'No Transactions found');
            }
          } else {
            // Else case handled here
            handleAdportalRequestFailure(dispatch);
            displayErrorToast(dispatch, 'Error while getting Adportal Transactions');
          }
        })
        .catch(async (error) => {
          handleAdportalRequestFailure(dispatch);
          displayErrorToast(dispatch, 'Error while getting Adportal Transactions');
          throw error;
        });
    };

export const downloadAdportalTransactions =
  () =>
    async (
      dispatch: Dispatch<any>,
      getState: () => { useTableReducer: { tableFilters: any } },
    ) => {
      handleadportalLoadingRequest(dispatch);
      const getPageFilters = makeOrGetTableFiltersByKey();
      const pageFilters = getPageFilters(getState(), 'adportal');
      const reportStartDate = pageFilters.endDate
        ? `${moment(pageFilters.startDate).format('L')}`
        : '';
      const reportEndDate = pageFilters.endDate
        ? `${moment(pageFilters.endDate).add(1, 'days').format('L')}`
        : '';
      const data = {
        start: pageFilters.page * 10,
        length: 10,
        sortColumn: pageFilters.orderBy,
        sortDirection: pageFilters.order,
        search: {
          value: pageFilters.search === undefined ? '' : pageFilters.search,
          regex: '',
        },
        startDate: reportStartDate,
        endDate: reportEndDate,
      };

      makeApiCall.postData(`${TransactionHistoryEndPoints.downloadAdPortalTransactions}`, null, data, {
        'Authorization': `Bearer ${await handleUserAuthorization()}`,
      })
        // @ts-ignore
        .then(async (response) => {
          if (response.headers['content-disposition']) {
            const fileName = response.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0];
            // @ts-ignore
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              // IE variant
              // @ts-ignore
              window.navigator.msSaveOrOpenBlob(
                new Blob([response.data], { type: 'application/pdf' }),
                fileName,
              );
            } else {
              const url = window.URL.createObjectURL(
                new Blob([response.data], { type: 'application/pdf' }),
              );
              const link = document.createElement('a');
              link.href = url;

              link.setAttribute('download', fileName.replace(/["]/g, ''));
              document.body.appendChild(link);
              link.click();
            }
            handleAdportalDownloadRequestSuccess(dispatch);
            displaySuccessToast(dispatch, 'Downloaded Payments Successfully');
          } else {
            handleAdportalRequestFailure(dispatch);
            displayErrorToast(dispatch, 'Failed to download payments');
          }
        })
        .catch(async (error) => {
          const errorMsg = getErrorMessages(error, 'Error while downloading transactions');
          displayErrorToast(dispatch, errorMsg);
          handleAdportalRequestFailure(dispatch);
          throw error;
        });
    };
