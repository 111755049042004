import { createSelector } from 'reselect';
import queryString from 'query-string';

// selector
const getOrderByKey = (pageKey: string) => {
  let key = '';
  switch (pageKey) {
    case 'transactions':
      key = 'userName';
      break;
    case 'users':
      key = 'firstName';
      break;
    case 'usersTransactionHistory':
      key = '';
      break;
    default:
      key = 'id';
      break;
  }
  return key;
};

const getTableFilters = (state: { useTableReducer: { tableFilters: any } }) =>
  state.useTableReducer.tableFilters;

const getKey = (_state: any, pageKey: any) => pageKey;

const getPageKeyFiltersData = (filtersData: { [x: string]: any }, pageKey: string) => {
  const filtersObject = (filtersData && filtersData[pageKey]) || {};
  const userNameParam = queryString.parse(window.location.search);
  return Object.keys(filtersObject).length > 0
    ? filtersObject
    : {
        order: 'desc',
        orderBy: getOrderByKey(pageKey),
        search: '',
        page: 0,
        columnFilterDetails: {},
        userName: userNameParam.userName || '',
      };
};

// reselect function to get table filters data by page key
export const makeOrGetTableFiltersByKey = () =>
  createSelector(getTableFilters, getKey, getPageKeyFiltersData);
