export interface IAdportalPayments {
paymentId:string;
amount:string;
paymentDate:Date;
status:string;
invoiceNumber:string;
firstName:string;
lastName:string;
company:string;
email:string;
phone:string;
address:string;
}
    
export interface IAdportalInitialState {
        adportalData: IAdportalPayments[];
        totalRecords: number;
        isAdportalLoading: boolean;
      }

export const ADPORTAL_REQUEST_LOADING = 'ADPORTAL_REQUEST_LOADING';
export const LOAD_ADPORTAL_SUCCESS = 'LOAD_ADPORTAL_SUCCESS';
export const ADPORTAL_REQUEST_FAILURE = 'ADPORTAL_REQUEST_FAILURE';
export const ADPORTAL_DOWNLOAD_REQUEST_SUCCESS='ADPORTAL_DOWNLOAD_REQUEST_SUCCESS';
export interface IAdportalRequestLoading {
    type: 'ADPORTAL_REQUEST_LOADING';
}  

export interface ILoadAdportalSuccess {
    type: 'LOAD_ADPORTAL_SUCCESS';
    data: {
      data: IAdportalPayments[];
      filteredRecords: number;
      totalRecords: number;
    };
}
export interface IAdportalRequestFailure {
    type: 'ADPORTAL_REQUEST_FAILURE';
}
export interface IAdportalDownloadRequestSuccess {
    type: 'ADPORTAL_DOWNLOAD_REQUEST_SUCCESS';
}
export type IAdportalActions=  
|IAdportalRequestLoading
|ILoadAdportalSuccess
|IAdportalRequestFailure
|IAdportalDownloadRequestSuccess
