/* eslint-disable no-case-declarations */
/* eslint-disable arrow-body-style */
import * as types from '../../actionTypes/AdportalActionTypes';

const adportalInitialState: types.IAdportalInitialState = {
  adportalData: [],
  totalRecords: 0,
  isAdportalLoading: false,
};

const adportalReducer = (state = adportalInitialState, action:types.IAdportalActions) => {
  switch (action.type) {
    case types.ADPORTAL_REQUEST_LOADING:
      return {
        ...state,
        isAdportalLoading: true
      };
    case types.ADPORTAL_REQUEST_FAILURE:
      return {
        ...state,
        isAdportalLoading: false,
      };
    case types.LOAD_ADPORTAL_SUCCESS:
      return {
        ...state,
        adportalData: action.data.data,
        totalRecords: action.data.totalRecords,
        isAdportalLoading: false,
      };
      case types.ADPORTAL_DOWNLOAD_REQUEST_SUCCESS:
        return {
          ...state,
          isAdportalLoading: false,
        };
    default:
      return state;
  }
};

export { adportalInitialState, adportalReducer };
