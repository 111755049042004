/* eslint-disable linebreak-style */
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

interface INumberFormatCustom extends NumberFormatProps {
  inputRef: any;
}
const NumberFormatCustom: React.FC<INumberFormatCustom> = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      allowNegative={false}
      allowEmptyFormatting
      onValueChange={(values) => {
        // @ts-ignore
        onChange({
          target: {
            // @ts-ignore
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
};

export default NumberFormatCustom;
