/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Grid,
  Tooltip,
  IconButton,
  Collapse,
  Typography,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import queryString from 'query-string';
import { initialState } from '../../../../rootReducer';
import * as Actions from '../../../../redux/actionCreators/UsersActionCreators';
import ActionButton from '../../../../shared/controls/ActionButton';
import UseTable from '../../../../shared/controls/UseTable';
import PageHeader from '../../../../shared/components/PageHeader';
import * as UseTableActions from '../../../../redux/actionCreators/common/UseTableActionCreators';
import Loader from '../../../../shared/components/Loader';
import Controls from '../../../../shared/controls/Controls';
import Popup from '../../../../shared/controls/Popup';
import ConfirmDialog from '../../../../shared/controls/ConfirmDialog';
import SearchInput from '../../../../shared/controls/SearchInput';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import { makeOrGetTableFiltersByKey } from '../../../../redux/selectors/common/UseTableSelectors';
import {
  formatDateMMDDYYYY,
  formatCurrency,
  emailRegex,
} from '../../../../shared/helpers';
import { getStatusStyles } from '../transactions/helpers';
import { PAYMENT_RECEIVED, PAYMENT_SCHEDULED } from './constants';
import MultipleRecurringPayments from './MultipleRecurringPayments';
import ScheduledPaymentModal from './ScheduledPaymentModal';
import TransactionDetail from '../transactions/TransactionDetail';
import ValidationError from './ValidationError';
import { ITransactionData } from '../../../../redux/actionTypes/TransactionsActionTypes';

const UsersTransactionHistory: React.FC = () => {
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const classes = { ...clientPortalClasses, ...commonClasses };
  const headCells = [
    { id: 'paymentTransactionId', label: 'Payment Id' },
    { id: 'paymentTransactionAmount', label: 'Amount' },
    { id: 'transactionDateTime', label: 'Transaction Date' },
    { id: 'paymentDate', label: 'Payment Date' },
    { id: 'paymentStatus', label: 'Status' },
    { id: 'paymentType', label: 'Type' },
    { id: 'description', label: 'Description' },
    { id: 'recurringPaymentId', label: 'Recurring Payment Id' },
    { id: 'actions', label: 'Actions', disableSorting: true },
  ];
  const [email, setEmail] = useState('');
  const [open, setOpen] = useState({});
  const [selectedId, setSelectedTransactionId] = useState('');
  const [scheduledPaymentData, setScheduledPaymentData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: '',
  });
  const isIntialMount = useRef(true);
  const userNameParam = queryString.parse(window.location.search);
  const dispatch = useDispatch();

  const getAccountFiltersByPageKey = makeOrGetTableFiltersByKey();
  const {
    usersData,
    sendPaymentError,
    isSendPaymentModalOpen,
    isScheduledPaymentModalOpen,
    paymentTypes,
    paymentStatuses,
    usersTransactionsData,
    tableFilters,
    totalRecordsTransactions,
    isUsersTransactionsLoading,
    isPaymentsLoading,
  } = useSelector((state: initialState) => ({
    usersData: state && state.usersReducer && state.usersReducer.usersData,
    sendPaymentError: state && state.usersReducer && state.usersReducer.sendPaymentError,
    isSendPaymentModalOpen:
      state && state.usersReducer && state.usersReducer.isSendPaymentModalOpen,
    isScheduledPaymentModalOpen:
      state && state.usersReducer && state.usersReducer.isScheduledPaymentModalOpen,
    paymentTypes: state && state.usersReducer && state.usersReducer.paymentTypes,
    paymentStatuses: state && state.usersReducer && state.usersReducer.paymentStatuses,
    usersTransactionsData:
      state && state.usersReducer && state.usersReducer.usersTransactionsData,
    tableFilters: getAccountFiltersByPageKey(state, 'usersTransactionHistory'),
    totalRecordsTransactions:
      state && state.usersReducer && state.usersReducer.totalRecordsTransactions,
    isUsersTransactionsLoading: state.usersReducer.isUsersTransactionsLoading,
    isPaymentsLoading: state.usersReducer.isPaymentsLoading,
  }));
  useEffect(() => {
    let orderBy = 'paymentDate';
    let order = 'desc';
    let page = 0;
    let searchInput = '';
    let { userName } = userNameParam || '';
    let paymentStatus = '';
    let paymentType = '';
    if (isIntialMount.current) {
      isIntialMount.current = false;
      const parsed: any = queryString.parse(window.location.search);
      const isUrlParamsAvailable = Object.keys(parsed).length > 1;
      const initialFilterValues = {
        pageKey: 'usersTransactionHistory',
        newFilters: {
          orderBy: isUrlParamsAvailable ? parsed.orderBy : 'paymentDate',
          order: isUrlParamsAvailable ? parsed.order : 'desc',
          page: isUrlParamsAvailable ? parseInt(parsed.page, 10) : 0,
          search: isUrlParamsAvailable ? parsed.searchInput : '',
          userName: parsed.userName,
          paymentType: isUrlParamsAvailable ? parsed.paymentType : '',
          paymentStatus: isUrlParamsAvailable ? parsed.paymentStatus : '',
        },
      };
      UseTableActions.setTableFilters(dispatch, initialFilterValues);
      if (Object.keys(parsed).length > 1) {
        order = parsed.order;
        orderBy = parsed.orderBy;
        page = parseInt(parsed.page, 10);
        searchInput = parsed.searchInput;
        userName = parsed.userName;
        paymentType = parsed.paymentType;
        paymentStatus = parsed.paymentStatus;
      }
    } else {
      order = tableFilters.order;
      orderBy = tableFilters.orderBy;
      page = tableFilters.page;
      searchInput = tableFilters.search;
      userName = tableFilters.userName;
      paymentType = tableFilters.paymentType;
      paymentStatus = tableFilters.paymentStatus;
    }
    const newPage = page.toString();
    // @ts-ignore
    const newUserName = userName.toString();
    dispatch(
      push({
        search: `?${new URLSearchParams({
          order,
          orderBy,
          page: newPage,
          searchInput,
          userName: newUserName,
          paymentType,
          paymentStatus,
        }).toString()}`,
      })
    );
    dispatch(Actions.loadUsersTransactionHistory());
    dispatch(Actions.loadUsers(true));
    dispatch(Actions.fetchPaymentStatuses());
    dispatch(Actions.fetchPaymentTypes());
    dispatch(Actions.fetchRecurringPaymentInfo());
  }, [
    tableFilters.order,
    tableFilters.orderBy,
    tableFilters.page,
    tableFilters.userName,
    tableFilters.paymentStatus,
    tableFilters.paymentType,
  ]);

  /** Reset Page Filters When page Unmounts */
  useEffect(
    () => () => {
      UseTableActions.resetFilters(dispatch, 'usersTransactionHistory');
    },
    []
  );

  const handleSendConfirmation = () => {
    dispatch(Actions.sendEmail(selectedId, email));
    setEmail('');
  };

  const isValidEmailField = (value: string, allowEmpty: boolean) => {
    if (allowEmpty && (value === undefined || value === '')) {
      return true;
    }
    return emailRegex.test(value);
  };

  const handleChange = (e: { target: { value: any } }) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleOnChangeFilter = (e: { target: { value: any; name: any } }) => {
    const { value, name } = e.target;
    const filterValue = {
      pageKey: 'usersTransactionHistory',
      newFilters: {
        [name]: value,
      },
    };
    UseTableActions.setTableFilters(dispatch, filterValue);
  };

  const showScheduledPaymentPopup = () => {
    Actions.toggleUpdateScheduledPaymentModal(dispatch, true);
  };

  const handleOpenFileOuput = (item: ITransactionData) => {
    const id = `${item.paymentTransactionId}`;
    setOpen({
      // @ts-ignore
      [id]: !open[id],
    });
  };

  const handleScheduledPayment = (item: ITransactionData) => {
    setScheduledPaymentData({
      amount: item.paymentTransactionAmount,
      paymentDate: item.paymentDate,
      transactionId: item.paymentTransactionId,
      id: item.paymentTransactionId,
      paymentDetails: item.paymentTransactionDetails,
    });
    showScheduledPaymentPopup();
  };

  const handleCancelScheduledPayment = (id: number) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    dispatch(Actions.cancelScheduledPayment(id));
  };

  const renderUserTransactionsChanges = () => (
    <div className={`${classes.global}`}>
      <Paper className={classes.pageContent} elevation={0}>
        <div>
          {usersData && usersData[0] && (
            <div>
              <div className={classes.userInfoContainer}>
                <div>
                  <Typography variant="h4" gutterBottom>
                    <b>User</b> : {userNameParam.userName}
                  </Typography>
                </div>
                <div>
                  <Controls.Button
                    type="button"
                    text="Back to Users"
                    className={`${classes.marginRight}`}
                    title="Returns back to Users table."
                    color="primary"
                    onClick={() => dispatch(push({ pathname: '/client-portal/users' }))}
                  />
                </div>
              </div>
              <div className={classes.userInfoContainer}>
                <div>
                  <Typography variant="body2" gutterBottom>
                    <b>First Name</b> : {usersData[0].firstName}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <b>Last Name</b> : {usersData[0].lastName}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <b>Customer Id</b> : {usersData[0].agencyId}
                  </Typography>
                </div>
                <div>
                  <Typography variant="body2" gutterBottom>
                    <b>Email Id</b> : {usersData[0].emailAddress}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <b>Company Name</b> : {usersData[0].companyName}
                  </Typography>
                </div>
              </div>
              <div className={classes.divider} />
            </div>
          )}
        </div>
        <div>
          <MultipleRecurringPayments />
          <div className={classes.divider} />
        </div>
        <PageHeader
          title="Transaction History"
          customHeaderToolbarClass={classes.pageHeaderToolbar}
          pageHeaderOptions={
            <div className={classes.registrationKeyPageHeaderOptions}>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <Controls.Select
                  name="paymentStatus"
                  label="Payment Status"
                  customRoot={{
                    paddingTop: '.95rem !important',
                    height: '.70rem !important',
                    '& .MuiInputLabel-root': {
                      lineHeight: '.5 !important',
                    },
                  }}
                  customFormControl={{
                    minWidth: 160,
                    marginRight: '5px',
                  }}
                  value={tableFilters && tableFilters.paymentStatus}
                  // @ts-ignore
                  onChange={handleOnChangeFilter}
                  options={paymentStatuses || []}
                  removeNone
                />
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol1}>
                <Controls.Select
                  name="paymentType"
                  label="Payment Type"
                  customRoot={{
                    paddingTop: '.95rem !important',
                    height: '.70rem !important',
                    '& .MuiInputLabel-root': {
                      lineHeight: '.5 !important',
                    },
                  }}
                  customFormControl={{
                    minWidth: 160,
                    marginRight: '5px',
                  }}
                  value={tableFilters && tableFilters.paymentType}
                  // @ts-ignore
                  onChange={handleOnChangeFilter}
                  options={paymentTypes || []}
                  removeNone
                />
              </div>
              <div className={classes.registrationKeyPageHeaderOptionsCol2}>
                <div>
                  <SearchInput
                    pageKey="usersTransactionHistory"
                    onSearchAction={() => {
                      dispatch(Actions.loadUsersTransactionHistory());
                    }}
                  />
                </div>
              </div>
            </div>
          }
        />
        <UseTable
          headCells={headCells}
          totalRecords={totalRecordsTransactions}
          pageKey="usersTransactionHistory"
        >
          <TableBody>
            {usersTransactionsData.map((item, index) => (
              <>
                <TableRow key={`${item.paymentTransactionId}_${index}`}>
                  <TableCell>{item.paymentTransactionId}</TableCell>
                  <TableCell>
                    {item.paymentTransactionAmount &&
                      formatCurrency(item.paymentTransactionAmount)}
                  </TableCell>
                  <TableCell>{formatDateMMDDYYYY(item.transactionDateTime)}</TableCell>
                  <TableCell>
                    {item.paymentDate && formatDateMMDDYYYY(item.paymentDate)}
                  </TableCell>
                  <TableCell>
                    <Box
                      p={0.25}
                      color="white !important"
                      borderRadius="8px"
                      bgcolor={getStatusStyles(item.paymentStatus)}
                    >
                      <Box textAlign="center">{item.paymentStatus}</Box>
                    </Box>
                  </TableCell>
                  <TableCell>{item.paymentType}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.recurringPaymentId}</TableCell>
                  <TableCell>
                    <ActionButton
                      color="secondary"
                      onClick={() => handleOpenFileOuput(item)}
                      disabled={item?.paymentTransactionDetails?.length < 1}
                    >
                      <Tooltip title="Transaction Details" arrow>
                        <ExitToAppOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </ActionButton>
                    <Tooltip title="Send Email Receipts">
                      <IconButton
                        onClick={() => {
                          Actions.toggleSendPaymentReceiptModal(dispatch, true);
                          // @ts-ignore
                          setSelectedTransactionId(item.paymentTransactionId);
                        }}
                      >
                        <EmailIcon style={{ fill: '#ff8c00' }} />
                      </IconButton>
                    </Tooltip>
                    {item.paymentStatus === PAYMENT_RECEIVED &&
                      item.paymentType === PAYMENT_SCHEDULED && (
                        <>
                          <Tooltip title="Edit Scheduled Payment">
                            <IconButton
                              color="primary"
                              onClick={() => handleScheduledPayment(item)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel Scheduled Payment">
                            <IconButton
                              color="primary"
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: `Are you sure, you want to cancel scheduled payment for transaction id : ${item.paymentTransactionId}?`,
                                  // @ts-ignore
                                  onConfirm: () => {
                                    handleCancelScheduledPayment(
                                      item.paymentTransactionId
                                    );
                                  },
                                });
                              }}
                            >
                              <CancelIcon style={{ fill: '#FF0000' }} />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse
                      // @ts-ignore
                      in={open[`${item.paymentTransactionId}`]}
                      timeout="auto"
                      unmountOnExit
                      className={classes.collapseOutput}
                    >
                      <Box margin={1}>
                        <TransactionDetail
                          details={
                            item.paymentTransactionDetails &&
                            item.paymentTransactionDetails
                          }
                        />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </UseTable>
      </Paper>
    </div>
  );

  return (
    <>
      <>
        <Loader isLoading={isUsersTransactionsLoading} />
        {usersTransactionsData ? renderUserTransactionsChanges() : <></>}
        <Popup
          title="Send Email Receipt"
          openPopup={isSendPaymentModalOpen}
          open={isSendPaymentModalOpen}
          closeFn={() => Actions.toggleSendPaymentReceiptModal(dispatch, false)}
        >
          {isPaymentsLoading ? (
            <Loader isLoading={isPaymentsLoading} />
          ) : (
            <div style={{ width: 500 }}>
              <Grid container>
                <ValidationError validationErrorData={sendPaymentError} />
                <Box m={3} bgcolor="background.paper" />
                <Grid item xs={12}>
                  <div
                    className="DivAddEmail MuiFormControl-root MuiTextField-root"
                    style={{ width: '100%' }}
                  >
                    <Controls.Input
                      name="paymentId"
                      label="Payment ID"
                      value={selectedId}
                      disabled
                      fullWidth
                    />
                    <Controls.Input
                      name="email"
                      label="Email ID"
                      value={email}
                      onChange={handleChange}
                      fullWidth
                      // @ts-ignore
                      error={
                        email &&
                        !isValidEmailField(email, false) &&
                        'Please Enter Valid Email'
                      }
                    />
                    <Box m={3} bgcolor="background.paper" />
                    <Controls.Button
                      type="submit"
                      text="SEND"
                      disabled={!email}
                      onClick={handleSendConfirmation}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
        </Popup>
        {isScheduledPaymentModalOpen && (
          <ScheduledPaymentModal
            openScheduledPaymentPopup={isScheduledPaymentModalOpen}
            paymentData={scheduledPaymentData}
          />
        )}
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </>
    </>
  );
};

export default UsersTransactionHistory;
