/** Interface for use table reducer */
export interface IUseTableInitialState {
  tableFilters: { [key: string | number]: any };
}

/** Action type for Reset Filters Data */
export const RESET_FILTERS = 'RESET_FILTERS';

/** Action type to store table filters data */
export const SET_TABLE_FILTERS_DATA = 'SET_TABLE_FILTERS_DATA';

export interface IResetFilters {
  type: 'RESET_FILTERS';
  pageKey: string;
}

export interface ISetTableFiltersData {
  type: 'SET_TABLE_FILTERS_DATA';
  data: {
    pageKey: string;
    newFilters: any;
  };
}

/** Interface for Action Types */
export type IUseTableActions = IResetFilters | ISetTableFiltersData;
