import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {
  DateRangePicker,
  DateRangeDelimiter,
  LocalizationProvider,
} from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
/* eslint-disable import/no-extraneous-dependencies */
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns';
import { setTableFilters } from '../../redux/actionCreators/common/UseTableActionCreators';
import { makeOrGetTableFiltersByKey } from '../../redux/selectors/common/UseTableSelectors';
import { initialState } from '../../rootReducer';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 0,
  },
}));

interface IBasicDateRangePicker {
  pageKey: string;
}

const BasicDateRangePicker: React.FC<IBasicDateRangePicker> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const getDateFiltersByPageKey = makeOrGetTableFiltersByKey();

  const { tableFilters } = useSelector((state: initialState) => ({
    tableFilters: getDateFiltersByPageKey(state, props.pageKey),
  }));
  const intialStartDate =
    tableFilters && tableFilters.startDate ? tableFilters.startDate : null;
  const intialEndDate =
    tableFilters && tableFilters.endDate ? tableFilters.endDate : null;
  useEffect(() => handleDateChange([intialStartDate, intialEndDate]), [tableFilters]);
  const [selectedDate, handleDateChange] = React.useState([
    intialStartDate,
    intialEndDate,
  ]);
  const [error, setError] = React.useState([null, null]);
  const setDateChange = (dateRange: any) => {
    const validatedDate =
      // @ts-ignore
      (new Date(dateRange[0]).getYear() > 0 && new Date(dateRange[1]).getYear() > 0) ||
      dateRange[0] === 'Invalid Date' ||
      dateRange[1] === 'Invalid Date';
    handleDateChange(dateRange);
    const filterValue = {
      pageKey: props.pageKey,
      newFilters: {
        startDate: dateRange[0] === null ? '' : dateRange[0],
        endDate: dateRange[1] === null ? '' : dateRange[1],
        page: 0,
      },
    };
    validatedDate && setTableFilters(dispatch, filterValue);
    if (dateRange[0] !== null && dateRange[1] !== null) {
      setIsOpen(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={DateFnsUtils}>
      <DateRangePicker
        startText="From Date"
        endText="To Date"
        // @ts-ignore
        value={selectedDate}
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        onError={([startReason, endReason]) => {
          if (startReason === 'invalidRange' || endReason === 'invalidRange') {
            setError([null, null]);
            return;
          }
          // @ts-ignore
          setError([startReason, endReason]);
        }}
        onChange={(dateRange) => setDateChange(dateRange)}
        renderInput={(startProps, endProps) => (
          <>
            <TextField
              className={classes.root}
              {...startProps}
              error={Boolean(error[0])}
              size="small"
            />
            <DateRangeDelimiter>-</DateRangeDelimiter>
            <TextField
              className={classes.root}
              {...endProps}
              error={Boolean(error[1])}
              size="small"
            />
          </>
        )}
      />
    </LocalizationProvider>
  );
};

export default BasicDateRangePicker;
