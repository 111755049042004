/** Interface for global nav reducer */
export interface IGlobalNavInitialState {
  isOpen: boolean;
}

/** Action Type to toggle global nav */
export const TOGGLE_GLOBAL_NAV_DRAWER = 'TOGGLE_GLOBAL_NAV_DRAWER';

export interface IToggleGlobalNavDrawer {
  type: 'TOGGLE_GLOBAL_NAV_DRAWER';
  isOpen: boolean;
}

/** Interface for action types */
export type IGlobalNavActions = IToggleGlobalNavDrawer;
