// @ts-nocheck
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
// @ts-ignore
import { SpectrumReachLogoAlt } from '@spectrum-reach/mui-theme';
import { PrimaryLinks } from '../routeConfigs/primaryLinks';
import {getUserInfoState } from '../../auth/UserSelectors';
import * as Actions from '../../redux/actionCreators/GlobalNavActionCreators';
import { initialState } from '../../rootReducer';
import { getUserInformation, isUserAuthenticated } from '../../auth/AuthUtils';
import { getPath } from '../../Routes';
import {  useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    marginBottom: '5px',
    height: '64px',
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  menuLink: {
    textDecoration: 'none',
    color: theme.palette.common.white,
    fontSize: '1.05rem',
    height: '64px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    minWidth: '200px',

    [`@media only screen and (min-width: 1563px)`]: {
      marginRight: theme.spacing(6),
    },

    '&:hover': {
      fontWeight: '600',
    },
  },
  menuLinkActive: {
    height: '64px',
    fontWeight: 600,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    textAlign: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  linkText: {
    textAlign: 'center',
    display: 'block',
    paddingTop: '1.2rem',
  },
  navMenu: {
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
    minWidth: '30px',
    '@media only screen and (min-width: 1563px)': {
      minWidth: '100px',
    },
  },
  toolbar: {
    minHeight: '64px',
  },
  titleWrapper: {
    flexGrow: 1,
    borderLeft: `1px solid ${theme.palette.common.white}`,
    paddingLeft: '10px',
    marginLeft: '17px',
    [`@media only screen and (min-width: 1563px)`]: {
      top: '0px',
    },
  },
  userName: {
    fontSize: '1rem',
  },
}));

const Header: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {isOpen } = useSelector(
    (state: initialState) => ({
      isOpen: state.globalNavReducer.isOpen,
      user: getUserInfoState(state),
    })
  );

  const userInformation = getUserInformation();
  const userAuthenticated=isUserAuthenticated();
  const location = useLocation()

  const isHomePage= (location.pathname === getPath().CLIENTPORTAL_HOME || location.pathname === '/');

  const filteredLink = PrimaryLinks.filter(
    (pL) => pL.subLinks && location.pathname.includes(pL.primaryRoute)
  );

  const Menu = () => {
    if (filteredLink && filteredLink.length > 0) {
      return (
        <MenuIcon
          // fontSize="large"
          onClick={() => Actions.toggleGlobalNavDrawerAction(dispatch, true)}
        />
      );
    }
    return <>&nbsp;</>;
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          {userAuthenticated &&!isHomePage && (
            <div className={classes.navMenu}>
              {isOpen ? (
                <CloseIcon
                  fontSize="large"
                  onClick={() => Actions.toggleGlobalNavDrawerAction(dispatch, false)}
                />
              ) : (
                <Menu />
              )}
            </div>
          )}

          <Link href="/">
            <SpectrumReachLogoAlt width="125" height="85" v1="1200" v2="250" />
          </Link>
          <Typography variant="h5" className={classes.titleWrapper}>
            Client Admin Portal
          </Typography>
          <>
            {PrimaryLinks &&
              PrimaryLinks.map(({ primaryLinkName, primaryRoute, id }) =>
                id > 1 ? (
                  <Link
                    href={primaryRoute}
                    className={classes.menuLink}
                    target="_blank"
                    key={id}
                    style={{ textDecoration: 'none' }}
                  >
                    <span className={`${classes.linkText}`}>{primaryLinkName}</span>
                  </Link>
                ) : (
                  <NavLink
                    to={primaryRoute}
                    activeClassName={classes.menuLinkActive}
                    className={classes.menuLink}
                    key={primaryRoute}
                  >
                    <span className={classes.linkText}>{primaryLinkName}</span>
                  </NavLink>
                )
              )}
            <>
              {' '}
              {userInformation && (
                <Typography variant="h5" className={classes.userName}>
                  Hello, {`${userInformation?.firstName} ${userInformation?.lastName}`}
                </Typography>
              )}
            </>
          </>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
