import React from 'react';
import { useSelector } from 'react-redux';
import { Paper, Grid } from '@material-ui/core';
import PageHeader from '../../../../shared/components/PageHeader';
import Loader from '../../../../shared/components/Loader';
import useClientPortalClasses from '../../../classes/ClientPortalClasses';
import useCommonClasses from '../../../../shared/classes/CommonClasses';
import TransactionHistory from './TransactionHistory';
import RegisteredUsers from './RegisteredUsers';
import RecurringPayments from './RecurringPayments';
import { initialState } from 'rootReducer';

/** Reporting Container Component */
const Reporting: React.FC = () => {
  const clientPortalClasses = useClientPortalClasses();
  const commonClasses = useCommonClasses();
  const classes = { ...clientPortalClasses, ...commonClasses };
  const { isDownloadReportLoading } = useSelector((state: initialState) => ({
    isDownloadReportLoading: state.reportingReducer.isDownloadReportLoading,
  }));

  return (
    <>
      <Loader isLoading={isDownloadReportLoading} />
      {!isDownloadReportLoading && (
        <div className={`${classes.global}`}>
          <Paper className={classes.pageContent} elevation={0}>
            <PageHeader title="Reporting" />
            <Grid container xs={12}>
              <TransactionHistory />
              <Grid item xs={1} />
              <RegisteredUsers />
              <Grid item xs={1} />
              <RecurringPayments />
            </Grid>
          </Paper>
        </div>
      )}
    </>
  );
};

export default Reporting;
