import { Dispatch } from 'redux';
import * as types from '../actionTypes/GlobalNavActionTypes';

/** Toggle Nav Bar */
export const toggleGlobalNavDrawer = (isOpen: boolean): types.IToggleGlobalNavDrawer => ({
  type: types.TOGGLE_GLOBAL_NAV_DRAWER,
  isOpen,
});

/** Action to TOGGLE Nav Drawer */
export const toggleGlobalNavDrawerAction = (dispatch: Dispatch<any>, isOpen: boolean) => {
  dispatch(toggleGlobalNavDrawer(isOpen));
};
