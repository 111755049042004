import React from 'react';
import { makeStyles, Paper, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fdfdff',
  },
  maxContent: {
    width: 'max-content',
  },
  headerPageHeader: {
    display: 'flex',
  },
  headerPageIcon: {
    display: 'inline-block',
    padding: theme.spacing(2),
    color: '#3c44b1',
  },
  headerPageTitle: {
    [`@media only screen and (min-width: 1563px)`]: {
      width: '10%',
      paddingRight: '0rem',
      paddingBottom: '0rem',
    },

    '& .MuiTypography-h4': {
      fontFamily: 'Reach-Text-Regular, Helvetica, Arial, sans-serif',
      paddingLeft: '.5rem',
      minWidth: '380px',
    },
    '& .MuiTypography-subtitle2': {
      opacity: '0.3',
    },
  },
  headerToolbar: {
    display: 'flex',
    justifyContent: 'space-between',

    padding: '0px 0px 5px 0px',

    [`@media only screen and (min-width: 1563px)`]: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      padding: '0px 0px 5px 0px',
      margin: theme.spacing(-1),
    },
  },
}));

interface IPageHeader {
  customHeaderToolbarClass?: any;
  title: string;
  customHeaderClass?: any;
  pageHeaderOptions?: React.ReactNode;
}

/** Page Header Component, Accepts Page Title and Options to be rendered
 * using the props 'pageHeaderOptions'
 */
const PageHeader: React.FC<IPageHeader> = (props) => {
  const classes = useStyles();
  const { title } = props;
  return (
    <Paper elevation={0} square className={classes.root}>
      <Toolbar className={`${classes.headerToolbar} ${props.customHeaderToolbarClass}`}>
        <div>
          <Typography variant="h4" component="div" className={`${classes.maxContent}`}>
            {title}
          </Typography>
        </div>
        <div className={props.customHeaderClass}>{props.pageHeaderOptions}</div>
      </Toolbar>
    </Paper>
  );
};

export default PageHeader;
