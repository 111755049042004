import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TablePagination,
  TableSortLabel,
  TableContainer,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import * as Actions from '../../redux/actionCreators/common/UseTableActionCreators';
import { makeOrGetTableFiltersByKey } from '../../redux/selectors/common/UseTableSelectors';
import TableFilterRow from './TableFilterRow';
import { initialState } from '../../rootReducer';

interface IUseTable {
  pageKey: string;
  headCells?: any;
  totalRecords?: any;
  hasColumnFilter?: boolean;
  children?: any;
  onColumnFilterAction?: any;
  hidePagination?: boolean;
  tableWidth?: number;
}

const useStyles = makeStyles((theme) => ({
  table: {
    width: (props: IUseTable) => (props.tableWidth ? `${props.tableWidth}px` : '100%'),
    '& thead th': {
      fontWeight: '600',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
      padding: '5px',
      '&:hover': {
        color: theme.palette.secondary.light,
      },
    },
    '& tbody td': {
      fontWeight: '400',
      fontSize: '14px',
      padding: '5px',
    },
    '& tbody tr:hover': {
      backgroundColor: '#f5f5f5',
      cursor: 'pointer',
      color: theme.palette.common.white,
    },
  },
}));

const UseTable: React.FC<IUseTable> = (props) => {
  const dispatch = useDispatch();
  const getTableFiltersByPageKey = makeOrGetTableFiltersByKey();
  const { tableFilters } = useSelector((state: initialState) => ({
    tableFilters: getTableFiltersByPageKey(state, props.pageKey),
  }));
  const classes = useStyles(props);

  const TblHead = () => {
    const { orderBy, order, startDate, endDate } = tableFilters;

    const handleSortRequest = (cellId: string) => {
      const isAsc = orderBy === cellId && order === 'asc';
      const cellOrder = isAsc ? 'desc' : 'asc';
      const filterValue = {
        pageKey: props.pageKey,
        newFilters: {
          order: cellOrder,
          orderBy: cellId,
          page: 0,
          startDate,
          endDate,
        },
      };
      Actions.setTableFilters(dispatch, filterValue);
    };

    return (
      <TableHead>
        <TableRow>
          {props.headCells.map((headCell: any) => (
            <TableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRequest(headCell.id);
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const handleChangePage = (_event: any, newPage: any) => {
    const filterValue = {
      pageKey: props.pageKey,
      newFilters: {
        page: newPage,
      },
    };
    Actions.setTableFilters(dispatch, filterValue);
  };

  const TblPagination = () => {
    const { page } = tableFilters;
    return (
      <TablePagination
        component="div"
        page={page}
        rowsPerPageOptions={[]}
        rowsPerPage={10}
        count={props.totalRecords}
        onChangePage={handleChangePage}
      />
    );
  };
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table}>
        {TblHead()}
        {props.hasColumnFilter && (
          <TableFilterRow
            headCells={props.headCells}
            onColumnFilterAction={props.onColumnFilterAction}
            pageKey={props.pageKey}
          />
        )}
        {props.children}
      </Table>
      {!props.hidePagination && TblPagination()}
    </TableContainer>
  );
};

export default UseTable;
