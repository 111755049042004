import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Box, Grid } from '@material-ui/core';

interface IValidationErrorData {
  validationErrorData: any;
}

/** ValidationError Component */
const ValidationError: React.FC<IValidationErrorData> = (props) => {
  const { validationErrorData } = props;
  return (
    <Grid item xs={12}>
      {validationErrorData &&
      Array.isArray(validationErrorData) &&
      validationErrorData.length > 0 ? (
        <>
          <Box m={1} bgcolor="background.paper" />
          {validationErrorData.map((data) => (
            <>
              <Alert severity="error">{data}</Alert>{' '}
              <Box m={1} bgcolor="background.paper" />
            </>
          ))}
        </>
      ) : (
        validationErrorData !== '' && (
          <>
            {' '}
            <Box m={1} bgcolor="background.paper" />
            <Alert severity="error">{validationErrorData}</Alert>{' '}
            <Box m={1} bgcolor="background.paper" />
          </>
        )
      )}
    </Grid>
  );
};

export default ValidationError;
