/** eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Route, Switch} from 'react-router-dom';
// @ts-ignore
import PageNotFound from './shared/error/components/PageNotFound';
import Home from './shared/Home/Home';
import Reporting from './modules/client-portal/components/reporting/Reporting';
import Transactions from './modules/client-portal/components/transactions/Transactions';
import Users from './modules/client-portal/components/users/Users';
import UsersTransactionHistory from './modules/client-portal/components/users/UsersTransactionHistory';
import AdportalTransactions from "./modules/client-portal/components/adportalTransactions/AdportalTransactions";
import AuthorizeRoute from "./auth/AuthorizeRoute";
import NoAccess from "./shared/components/NoAccess";

export const getPath = () => ({
  CLIENTPORTAL_HOME: '/client-portal/home',
  CLIENTPORTAL_REPORTING: '/client-portal/reporting',
  CLIENTPORTAL_TRANSACTIONS: '/client-portal/transactions',
  CLIENTPORTAL_USERS: '/client-portal/users',
  CLIENTPORTAL_USERS_TRANSACTIONS: '/client-portal/users/transactions',
  CLIENTPORTAL_ADPORTAL_TRANSACTIONS: '/client-portal/users/adportal/transactions',
  CLIENTPORTAL_No_Access:'/client-portal/no-access'
});

const routes = [
  { path: '/', exact: true, component: Home },
  { path: getPath().CLIENTPORTAL_HOME, exact: true, component: Home },
  { path: getPath().CLIENTPORTAL_REPORTING, component: Reporting },
  { path: getPath().CLIENTPORTAL_TRANSACTIONS, exact: true, component: Transactions },
  { path: getPath().CLIENTPORTAL_USERS, exact: true, component: Users },
  { path: getPath().CLIENTPORTAL_USERS_TRANSACTIONS, exact: true, component: UsersTransactionHistory },
  { path: getPath().CLIENTPORTAL_ADPORTAL_TRANSACTIONS, exact: true, component: AdportalTransactions },
  { path: getPath().CLIENTPORTAL_No_Access, exact: true, component: NoAccess },
];

const Routes: React.FC = () => (
  <Switch>
    {routes.map(({ path, exact, component }) => (
      <AuthorizeRoute key={path} path={path} exact={exact} component={component} />
    ))}
    <Route path="*" component={PageNotFound} />
  </Switch>
);

export default Routes;
