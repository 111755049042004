import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    minHeight: 'calc(100vh - 216px)',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

interface IWrapper {
  children: React.ReactNode;
}

const Wrapper: React.FC<IWrapper> = (props) => {
  const classes = useStyles();
  const { children } = props;
  return <div className={classes.root}>{children}</div>;
};

export default Wrapper;
