export const ReportingEndPoints = {
  downloadPymentTransaction: `${process.env.REACT_APP_INTERNAL_API_URL}Reporting/DownloadPaymentTransaction`,
  downloadRegisteredUsers: `${process.env.REACT_APP_INTERNAL_API_URL}Reporting/DownloadRegisteredUsers`,
  downloadRecurringPayments: `${process.env.REACT_APP_INTERNAL_API_URL}Reporting/DownloadRecurringPayments`,
};

export const TransactionHistoryEndPoints = {
  getTransactionHistory: `${process.env.REACT_APP_INTERNAL_API_URL}TransactionHistory/Post`,
  downloadTransaction: `${process.env.REACT_APP_INTERNAL_API_URL}TransactionHistory/Download`,
  adPortalTransactions: `${process.env.REACT_APP_INTERNAL_API_URL}TransactionHistory/AdPortalTransactions`,
  downloadAdPortalTransactions: `${process.env.REACT_APP_INTERNAL_API_URL}TransactionHistory/DownloadAdportalTransactions`,
};

export const UsersEndPoints = {
  getUsersHistory: `${process.env.REACT_APP_INTERNAL_API_URL}User/GetUsers`,
  getUsersTransactionHistory: `${process.env.REACT_APP_INTERNAL_API_URL}User/GetTransactions`,
  updateUser: `${process.env.REACT_APP_INTERNAL_API_URL}User/EditUser`,
};

export const PaymentAdjustmentsEndPoints = {
  sendEmailConfirmation: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/SendPaymentConfirmation`,
  getRecurringPaymentInformation: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/GetRecurringPaymentInformation`,
  disableAutoPay: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/DisableRecurringPayment`,
  fetchPaymentTypes: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/GetPaymentTransactionTypes`,
  fetchPaymentStatus: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/GetPaymentStatusList`,
  cancelScheduledPayment: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/CancelSchedulePayment`,
  updateScheduledPayment: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/UpdateSchedulePayment`,
  recurringPaymentTypes: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/GetRecurringPaymentTypes`,
  getWeekDays: `${process.env.REACT_APP_INTERNAL_API_URL}PaymentAdjustments/GetWeekDays`,
};

export const AuthenticationEndPoints={
  CognitoDomainName:`${process.env.REACT_APP_COGNITO_DOMAIN}`,
  ClientId:`${process.env.REACT_APP_CLIENT_ID}`,
  RedirectURI:`${process.env.REACT_APP_REDIRECT_URI}`,
}
