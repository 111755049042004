 /* @ts-ignore */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { CreateStore } from './Store';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
// @ts-ignore
import { InternalTheme } from '@spectrum-reach/mui-theme';
import { CssBaseline } from '@material-ui/core';

ReactDOM.render(
  // @ts-ignore
  <ReduxProvider store={CreateStore()}>
    <BrowserRouter>
      <ThemeProvider theme={createMuiTheme(InternalTheme)}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);
